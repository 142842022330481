#guest-tracking{
  #columns{
    max-width: 1440px;
    margin: auto;
    color: $black;
    padding-bottom: 30px;
  }
  h3{
    color: $black;
  }
  button{
    min-width: 150px;
    background-color: $black;
  }
}