#stores{
  #columns{
    color: $black;
  }
  #center_column{
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;
    max-width: 1440px;
  }
  .page_heading{
    margin-bottom: 40px;
    color: $black;
    border-bottom: 4px solid $black;
    display: inline-block;
  }
  .store-description{
    @media(min-width: $mobile){
      max-width: 30.66667%;
    }
    @media(max-width: $mobile){
      margin-bottom: 15px;
    }
    .page_heading{
      margin-bottom: 10px;
    }
    a{
      background-color: $black;
      color: #fff;
      padding: 10px;
      transition: 0.3s ease;
      font-size: 14px;
      &:hover{
        background-color: $gray;
        color: $black;
      }
    }
  }
  .store-item-footer{
    background-color: #f5f7f6;
    padding: 10px;
    box-shadow: 0 0 10px 3px #00000036;
  }
  .heading_color{
    color: $black;
    font-weight: 700;
    margin-right: 15px;
    min-width: 93px;
  }
  .flex_container{
    &:last-child{
      .flex_child{
        color: red;
        font-weight: 700;
      }
    }
  }
}