#two-banners{
  max-width: 1575px;
  .st_banner_5{
    overflow: visible;
  }
  .st_banner_block{
    &:hover{
      img{
        filter: grayscale(1);
      }
    }
  }
  .st_banner_block{
    padding-bottom: 40px;
  }
  .st_image_layered_description_inner{
    position: absolute;
    bottom: 0;
    padding: 0;
  }
  h5{
    font-size: 65px;
  }
  .men{
    color: #fff;
  }
}
#banner_box_6{
  @media(min-width: $presta-tablet){
    flex: 0 0 51%;
    max-width: 51%;
    padding-right: 7px;
  }
  img{
    box-shadow: -40px 55px 47px -24px #0000006e;
  }
  .st_banner_block{
    padding-left: 53px;
    overflow: visible;
  }
  .st_image_layered_description_inner{
    @media(min-width: $presta-tablet){
      left: 30px;
    }
  }
}
#banner_box_7{
  @media(min-width: $presta-tablet){
    flex: 0 0 49%;
    max-width: 49%;
    padding-left: 7px;
  }
  .st_banner_block{
    padding-right: 27px;
  }
  .st_image_layered_description_inner{
    @media(min-width: $presta-tablet){
      right: 0;
    }
  }
}
@media(max-width: $tablet){
  #two-banners{

    .st_banner_5{
      margin-bottom: 24px;
    }
    .st_banner_block{
      &:hover{
        img{

        }
      }
    }
    .st_banner_block{

    }
    .st_image_layered_description_inner{
      bottom: 7px;
    }
    h5{
      font-size: 55px;
    }
    .men{

    }
  }
  #banner_box_6{

    img{

    }
    .st_banner_block{

    }
    .st_image_layered_description_inner{

    }
  }
  #banner_box_7{

    .st_banner_block{

    }
    .st_image_layered_description_inner{

    }
  }
}
@media(max-width: $presta-tablet){
  #two-banners{

    .st_banner_5{

    }
    .st_banner_block{
      &:hover{
        img{

        }
      }
    }
    .st_banner_block{

    }
    .st_image_layered_description_inner{
      bottom: 13px;
    }
    h5{
      font-size: 42px;
    }
    .men{

    }
  }
  #banner_box_6{

    img{

    }
    .st_banner_block{
      padding-left: 0;
    }
    .st_image_layered_description_inner{

    }
  }
  #banner_box_7{

    .st_banner_block{
      padding-right: 0;
    }
    .st_image_layered_description_inner{

    }
  }
}
@media(max-width: $mobile){
  #two-banners{
    max-width: 480px;
    .banner_col{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .st_banner_5{

    }
    .st_banner_block{
      &:hover{
        img{

        }
      }
    }
    .st_banner_block{

    }
    .st_image_layered_description_inner{

    }
    h5{
      font-size: 36px;
    }
    .men{

    }
  }
  #banner_box_6{

    img{
      box-shadow: -4px 25px 35px -11px #0000006e;
    }
    .st_banner_block{
      margin-bottom: 0;
    }
    .st_image_layered_description_inner{

    }
  }
  #banner_box_7{

    .st_banner_block{
      margin-bottom: 0;
    }
    .st_image_layered_description_inner{

    }
  }
}