#lrp-product-widget,
#cart-points-summary{
  border: none;
  padding: 0;
  max-width: 500px;
  font-size: 14px;
  color: $black;
  background-color: #f2f2f2;
  strong{

    &:first-of-type{
      color: #fff;
      background-color: #d83232;
      border-radius: 50%;
      padding: 4px;
      font-weight: 400;
      font-size: 13px;
    }
  }
  br{
    display: none;
  }
  .material-icons{
    display: none;
  }
}
#cart-points-summary{
  background-color: unset;
  .card{
    background-color: #f2f2f2;
    max-width: 300px;
  }
}
#product{
  .kk_close{
    background-color: #fff;
    border-radius: 50%;
    color: #000;
  }
  .swiper-slide{
    cursor: zoom-in;
    &.swiper-slide-zoomed{
      cursor: zoom-out;
    }
  }
  .sizep{
    h3{
      text-align: center;
      font-size: 24px;
    }
    p{
      text-align: center;
      font-size: 16px;
      color: $black;
      span{
        margin-bottom: 20px;
        display: inline-block;
      }
    }
    h4{
      font-size: 20px;
      text-align: center;
      margin: 30px 0;
    }
    table{
      width: 100%;
      color: #000;
      th{
        font-size: 14px;
        border: 1px solid #ccc;
        padding: 5px;
        text-align: center;
      }
      td{
        border: 1px solid #ccc;
        font-size: 13px;
        padding: 5px;
        text-align: center;
      }
    }
  }
  .inline_popup_content{
    width: 100%;
    overflow-y: auto;
    max-height: 800px;
    .mfp-close{
      position: absolute;
      right: 0;
      top: 0;
      background-color: #000;
      color: #fff;
      z-index: 999;
    }
  }
  .product_first_section{
    .swiper-button{
      display: block!important;
      i{
        line-height: 44px;
        width: 47px;
      }
    }
  }
  #page_banner_container_1{
    padding: 23px 0px 31px 0;
    .container-fluid{
      max-width: 1465px;
      span{
        font-weight: 300;
      }
    }
  }
  .breadcrumb_spacing{
    display: none;
  }
  .steasy_divider{
    display: none;
  }
  .product_first_section{
    background-color: #f2f2f2;
    padding-bottom: 75px;
    .product_page_container{
      max-width: 1465px;
      margin: auto;
    }
  }
  .pro_gallery_thumbs{
    position: static;
  }
  .pro_gallery_thumbs_container{
    float: left;
    width: 100%;
    max-width: 100%;
    padding-right: 328px;
    position: relative;
    .general_border{
      border: none;
    }
    .swiper-button{
      width: 46px;
      height: 46px;
      top: 50%;
      i{
        width: 42px;
      }
    }
    .swiper-button-next{
      right: 194px;
    }
    .swiper-button-prev{
      left: auto;
      right: 248px;
    }
    .swiper-button-disabled{
      border-color: #b9b9b9;
      i{
        &:before{
          color: #b9b9b9;
        }
      }
      &:hover{
        background-color: unset;
      }
    }
    .swiper-slide{
      width: 89px;
      margin-bottom: 8px;
      box-shadow: 0px 4px 15px -8px #0000002b;
    }
  }
  .pro_gallery_top_container{
    box-shadow: 14px 16px 76px rgba(0, 0, 0, 0.17);
    z-index: 999;
  }
  .images-container{
    max-width: 720px;
  }
  .pro_popup_trigger_box{
    right: 36px;
    bottom: 36px;
  }
  .st_popup_image{
    width: 50px;
    height: 50px;
    box-shadow: 0 0 13px rgba(18, 16, 11, 0.15);
    background-color: #ffffff;
    border-radius: 50%;
    i{
      color: $black;
      font-size: 23px;
      line-height: 49px;
    }
  }
  .product_left_column{
    flex: 0 0 51.5%;
    max-width: 51.5%;
    z-index: 99;
    padding-right: 0;
  }
  .product_middle_column_inner{
    padding: 24px 15px 0px 50px;
  }
  .product_middle_column{
    flex: 0 0 100%;
    max-width: calc(48.5% + 156px);
    background-color: #fff;
    margin-left: -156px;
    padding-left: 148px;
    padding-right: 0;
    padding-bottom: 25px;
  }
  .product-manufacturer{
    padding-left: 28px;
    background-color: #f2f2f2;
  }
  .social_share_block{
    float: right;
    margin: 5px 25px 0 0;
    span{
      font-size: 14px;
      font-weight: 300;
      color: $black;
      margin-right: 20px;
    }
    a{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      line-height: 24px;
      margin-bottom: 0;
      margin-right: 15px;
    }
    .social_share_twitter{
      i{
        &:before{
          font-size: 20px;
        }
      }
    }
  }
}
#page_wrapper{
  .product_name{
    font-size: 26px;
    font-weight: 300;
    color: $black;
    margin-bottom: 13px;
  }
  .price{
    font-size: 30px;
    font-weight: 700;
    color: $black;
  }
  .regular-price{
    margin-left: 10px;
    font-size: 20px;
    color: #a3a2a2;
    font-weight: 300;
  }
  .product-variants-item{
    margin-bottom: 32px;
  }
  .product-variants{

    .control-label{

    }
    .li_fl{
      float: left;
    }
    .radio-label{
      border: 2px solid #fff;
      padding: 3px;
      line-height: 13px;
      align-items: center;
      justify-content: center;
      display: flex;
      &:hover{

      }
    }
    .input-radio{

    }
    .input-radio:checked+span{
      border-color: $black;
    }
    input:hover+span{
      border-color: $black;
      line-height: 13px;
    }
    .color{

      border: 2px solid $black;

    }
    .input-color:checked+span::before{
      border-color: $black;
    }
  }
}
.product-reference{
  font-size: 16px;
  font-weight: 300;
  color: $black;
  margin-bottom: 19px;
  .pro_extra_info_label{
    color: #b8b7b7;
  }
}
.product-description-short{
  color: $gray;
  font-size: 16px;
  font-weight: 200;
  margin-top: 15px;
  margin-bottom: 9px!important;
}
.tax-shipping-delivery-label{
  br{
    display: none;
  }
}
.product-unavailable{
  color: $red;
}
.availability{
  color: #939393;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  padding: 0 0 23px 27px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 23px;
  &:before{
    content: "\E80F";
    font-family: fontello;
    position: absolute;
    left: 0;
    top: 0;
    color: #1cd91c;
    font-size: 16px;
  }
}
.pro_right_item{
  a{
    font-size: 16px;
    color: $black;
    font-weight: 700;
    border-bottom: 2px solid $black;
    padding-bottom: 11px;
  }
}
.product-variants-item{
  margin-bottom: 32px;
}
.product-variants{
  margin: 41px 0 45px 0;
  .control-label{
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: $black;
    margin-right: 35px;
    min-width: 81px;
  }
  .li_fl{
    float: left;
  }
  .radio-label{
    border: 2px solid #fff;
    color: $black;
    padding: 3px;
    width: 48px;
    line-height: 13px;
    height: 36px;
    text-align: center;
    font-size: 14px;
    &:hover{
      border-color: $black;
    }
  }
  .input-radio{
    width: 48px;
    height: 36px;
    line-height: 13px;
  }
  .input-radio:checked+span{
    border-color: $black;
  }
  input:hover+span{
    border-color: $black;
    line-height: 13px;
  }
  .color{
    border: none;
    width: 32px;
    height: 32px;
  }
  .input-color:checked+span::before{
    content: "\E80F";
    position: absolute;
    font-family: fontello;
    font-size: 18px;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -58%);
  }
  .input-color[title="Λευκό"]:checked+span::before{
    color: #000;
  }
}
.product-add-to-cart{
  .product-quantity{
    width: 77.5%;
    display: inline-block;
  }
  .pro_cart_right{
    width: calc(22.5% - 23px);
    display: inline-block;
    margin-left: 23px;
  }
  .input-group{
    border: 2px solid $black;
  }
  .qty{
    width: 130px;
    margin-right: 19px;
    display: inline-block!important;
    button,
    input{
      height: 65px!important;
      border: none;
      color: $black;
    }
    input{
      font-size: 23px;
      text-align: center;
      font-weight: 700;
    }
    button{
      position: relative;
      font-size: 0;
      width: 42px;
      &:before{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-size: 16px;
        color: $black;
        font-family: fontello;
      }
      &:hover{
        background-color: unset;
      }
    }
    .bootstrap-touchspin-down{
      &:before{
        content: "\E84A";
      }
    }
    .bootstrap-touchspin-up{
      &:before{
        content: "\E848";
      }
    }
  }
  .add{
    width: calc(100% - 155px);
    display: inline-block!important;
    button{
      height: 68px;
      background-color: $black;
      border-color: $black;
      &.active{
        cursor: progress;
      }
      @extend .shine-hover;
      span{
        letter-spacing: 1.3px;
        font-size: 15px;
        font-weight: 400;
        color: #fff;
      }
      i{
        display: none;
      }
    }
  }
  .add_to_wishlit{
    margin: 0;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 50px;
    transition: 0.3s ease;
    position: relative;
    height: 50px;
    .hover_fly_btn_inner{
      font-size:0;
    }
    &:hover{
      border-color: $black;
    }
    i{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);
      margin: 0;
      &:before{
        content: "";
        @include sprite($heart);
      }
    }
  }
}
@media(max-width: $desktop){
  #product{
    .swiper-button{

      i{

      }
    }
    #page_banner_container_1{

      .container-fluid{

        span{

        }
      }
    }
    .breadcrumb_spacing{

    }
    .steasy_divider{

    }
    .product_first_section{

      .product_page_container{

      }
    }
    .pro_gallery_thumbs{
      max-width: 260px;
      float: left;
    }
    .pro_gallery_thumbs_container{

      .general_border{

      }
      .swiper-button{

        i{

        }
      }
      .swiper-button-next{

      }
      .swiper-button-prev{

      }
      .swiper-button-disabled{

        i{
          &:before{

          }
        }
        &:hover{

        }
      }
      .swiper-slide{
        width: 78px;
      }
    }
    .pro_gallery_top_container{

    }
    .images-container{

    }
    .pro_popup_trigger_box{

    }
    .st_popup_image{

      i{

      }
    }
    .product_left_column{

    }
    .product_middle_column_inner{

    }
    .product_middle_column{
      padding-bottom: 35px;
    }
    .product-manufacturer{

    }
    .social_share_block{

      span{

      }
      a{

      }
      .social_share_twitter{
        i{
          &:before{

          }
        }
      }
    }
  }
}
@media(max-width: $tablet){
  #product{
    .swiper-button{

      i{

      }
    }
    #page_banner_container_1{

      .container-fluid{

        span{

        }
      }
    }
    .breadcrumb_spacing{

    }
    .steasy_divider{

    }
    .product_first_section{

      .product_page_container{

      }
    }
    .pro_gallery_thumbs{
      max-width: 176px;
    }
    .pro_gallery_thumbs_container{

      .general_border{

      }
      .swiper-button{

        i{

        }
      }
      .swiper-button-next{

      }
      .swiper-button-prev{

      }
      .swiper-button-disabled{

        i{
          &:before{

          }
        }
        &:hover{

        }
      }
      .swiper-slide{

      }
    }
    .pro_gallery_top_container{

    }
    .images-container{

    }
    .pro_popup_trigger_box{

    }
    .st_popup_image{

      i{

      }
    }
    .product_left_column{
      padding-left: 0;
    }
    .product_middle_column_inner{

    }
    .product_middle_column{

    }
    .product-manufacturer{

    }
    .social_share_block{

      span{

      }
      a{

      }
      .social_share_twitter{
        i{
          &:before{

          }
        }
      }
    }
  }
  #page_wrapper{
    .product-add-to-cart{
      .product-quantity{

      }
      .pro_cart_right{
        width: calc(14.5% - 10px);
        margin-left: 10px;
      }
      .input-group{

      }
      .qty{
        width: 110px;
        button,
        input{

        }
        input{

        }
        button{

          &:before{

          }
          &:hover{

          }
        }
        .bootstrap-touchspin-down{
          &:before{

          }
        }
        .bootstrap-touchspin-up{
          &:before{

          }
        }
      }
      .add{
        width: calc(100% - 134px);
        button{
          padding: 10px;
          span{
            font-size: 14px;
          }
          i{
            display: none;
          }
        }
      }
      .add_to_wishlit{

        &:hover{

        }
        i{

          &:before{

          }
        }
      }
    }
  }
}
@media(max-width: $presta-tablet){
  #product{
    .swiper-button{

      i{

      }
    }
    #page_banner_container_1{

      .container-fluid{

        span{

        }
      }
    }
    .breadcrumb_spacing{

    }
    .steasy_divider{

    }
    .product_first_section{

      .product_page_container{

      }
    }
    .pro_gallery_thumbs{
      max-width: 263px;
      @media(max-width: 875px){
        max-width: 174px;
      }
      margin: auto;
      float: unset;
    }
    .pro_gallery_thumbs_container{
      max-width: calc(100% - 104px);
      padding-right: 0;
      .general_border{

      }
      .swiper-button{
        top: auto;
        bottom: -51px;
        i{

        }
      }
      .swiper-button-next{
        right: 31px;
      }
      .swiper-button-prev{
        right: 90px;
      }
      .swiper-button-disabled{

        i{
          &:before{

          }
        }
        &:hover{

        }
      }
      .swiper-slide{

      }
    }
    .pro_gallery_top_container{

    }
    .images-container{

    }
    .pro_popup_trigger_box{

    }
    .st_popup_image{

      i{

      }
    }
    .product_left_column{
      flex: 0 0 44.5%;
      max-width: 44.5%;
    }
    .product_middle_column_inner{

    }
    .product_middle_column{
      max-width: calc(55.5% + 100px);
      margin-left: -100px;
      padding-left: 69px;
    }
    .product-manufacturer{

    }
    .social_share_block{

      span{

      }
      a{

      }
      .social_share_twitter{
        i{
          &:before{

          }
        }
      }
    }
  }
  #page_wrapper{
    .product-add-to-cart{
      .product-quantity{

      }
      .pro_cart_right{

      }
      .input-group{

      }
      .qty{

        button,
        input{

        }
        input{

        }
        button{

          &:before{

          }
          &:hover{

          }
        }
        .bootstrap-touchspin-down{
          &:before{

          }
        }
        .bootstrap-touchspin-up{
          &:before{

          }
        }
      }
      .add{

        button{

          span{

          }
          i{

          }
        }
      }
      .add_to_wishlit{

        &:hover{

        }
        i{

          &:before{

          }
        }
      }
    }
    .product_name{
      font-size: 18px;
      margin-bottom: 3px;
    }
    .product-reference{
      margin-bottom: 7px;
    }
    .price{
      font-size: 26px;
    }
    .regular-price{
      font-size: 17px;
    }
    .product-description-short{
      font-size: 14px;
    }
    .availability{
      font-size: 14px;
    }
    .pro_right_item{
      a{
        font-size: 14px;
        padding-bottom: 5px;
      }
    }
    .product-variants{
      margin: 18px 0 0px 0;
    }
    .product-variants-item{
      margin-bottom: 15px;
      .control-label{
        font-size: 16px;
        margin-right: 22px;
      }
    }
    .product-add-to-cart{
      .product-quantity{

      }
      .pro_cart_right{
        width: calc(14.5% - 10px);
        margin-left: 10px;
      }
      .input-group{

      }
      .qty{
        width: 107px;
        margin-right: 10px;
        button,
        input{
          height: 50px !important;

        }
        input{
          font-size: 16px;
        }
        button{
          &:before{
            font-size: 11px;
          }
          &:hover{

          }
        }
        .bootstrap-touchspin-down{
          &:before{

          }
        }
        .bootstrap-touchspin-up{
          &:before{

          }
        }
      }
      .add{
        width: calc(100% - 134px);
        button{
          height: 50px;
          span{
            font-size: 12px;
          }
          i{
            display: none;
          }
        }
      }
      .add_to_wishlit{

        &:hover{

        }
        i{

          &:before{

          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #product{
     .mfp-image-holder{
      padding-left: 5px;
       padding-right: 5px;
       .mfp-close{
         right: 0;
         top: 0;
         background-color: #000;
         color: #fff;
         z-index: 999;
       }
       .mfp-img{
         padding: 0;
         min-height: 460px;
         object-fit: cover;
       }
    }

    .sizep{
      h3{
        font-size: 16px;
      }
      p{
        font-size: 13px;
        span{

        }
      }
      h4{
        font-size: 16px;
      }
      table{

        th{
          font-size: 10px;

        }
        td{
          font-size: 11px;
        }
      }
    }
    .mfp-inline-holder{
      padding: 5px;
    }
    .inline_popup_content{

      .mfp-close{

      }
    }
    .product_first_section{
      padding-bottom: 10px;
      .swiper-button{
        display: none!important;
        i{

        }
      }
    }
    .product-manufacturer{
      padding-left: 0px;
    }
    .swiper-button{

      i{

      }
    }
    #page_banner_container_1{

      .container-fluid{

        span{

        }
      }
    }
    .breadcrumb_spacing{

    }
    .steasy_divider{

    }
    .product_first_section{

      .product_page_container{

      }
    }
    .pro_gallery_thumbs{
      max-width: inherit;
    }
    .pro_gallery_thumbs_container{
      max-width: inherit;
      .general_border{

      }
      .swiper-button{

        i{

        }
      }
      .swiper-button-next{

      }
      .swiper-button-prev{

      }
      .swiper-button-disabled{

        i{
          &:before{

          }
        }
        &:hover{

        }
      }
      .swiper-slide{
        width: 72px;
      }
    }
    .pro_gallery_top_container{

    }
    .images-container{

    }
    .pro_popup_trigger_box{

    }
    .st_popup_image{

      i{

      }
    }
    .product_left_column{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .product_middle_column_inner{
      padding: 10px;
    }
    .product_middle_column{
      max-width: 100%;
      margin-left: 0;
      padding-left: 0px;
      padding-bottom: 25px;
    }
    .product-manufacturer{

    }
    .social_share_block{

      span{

      }
      a{

      }
      .social_share_twitter{
        i{
          &:before{

          }
        }
      }
    }
  }
  #page_wrapper{
    .product_name{

    }
    .product-reference{
      margin-bottom: 15px;
    }
    .price{

    }
    .regular-price{

    }
    .pro_right_item{
      a{

      }
    }
    .product-variants{

    }
    .product-variants-item{

      .control-label{
        font-size: 14px;
        margin-right: 5px;
      }
    }
    .product-add-to-cart{
      margin-top: 30px;
      .pro_cart_block{
        flex-flow: row;
      }
      .product-quantity{

      }
      .pro_cart_right{
        width: calc(12.5% - 6px);
        margin-left: 6px;
      }
      .input-group{

      }
      .qty{
        display: inline-block;
        width: 87px;
        margin-right: 2px;
        button,
        input{


        }
        input{
          font-size: 14px;
        }
        button{
          width: 30px;
          &:before{

          }
          &:hover{

          }
        }
        .bootstrap-touchspin-down{
          &:before{

          }
        }
        .bootstrap-touchspin-up{
          &:before{

          }
        }
      }
      .add{
        width: calc(100% - 94px);
        display: inline-block;
        button{
          height: 54px;
          padding: 10px 6px;
          span{
            font-size: 11px;
          }
          i{

          }
        }
      }
      .add_to_wishlit{
        left: 3px;
        &:hover{

        }
        i{

          &:before{

          }
        }
      }
    }
  }
  .product-description-short{
    font-size: 12px;
    margin-bottom: 2px !important;
  }
  .availability{
    font-size: 12px;
    padding: 0 0 13px 19px;
    margin-bottom: 12px;
  }
}