#footer{
  #footer-top{
    margin-left: auto;
    margin-right: auto;
    padding-top: 42px;
    padding-bottom: 32px;
    .title_block_inner{
      font-size: 16px;
      font-weight: 700;
      color: $black;
      @media(min-width:$mobile){
        margin-bottom: 20px;
        margin-top: 46px;
      }
    }
    .footer_block_content{
      li{
        margin-bottom: 6px;
      }
      a{
        color: #7b7b7b;
        font-size: 14px;
        font-weight: 300;
        &:hover{
          color: $black;
        }
      }
    }
  }
  .ctitle{
    font-size: 36px;
    font-weight: 700;
  }
  .st_news_letter{
    p{
      font-size: 14px;
      color: #808080;
      font-weight: 300;
      max-width: 500px;
    }
  }
  form{
    flex-flow: column-reverse;
    display: flex;
    margin-top: 50px;
    .st_news_letter_form_inner{
      margin-bottom: 15px;
    }
    .input-group-btn{
      width: 49px;
      height: 49px;
      margin-top: 9px;
    }
    button{
      position: relative;
      font-size: 0;
      background-color: $black;
      border-radius: 50%;
      width: 49px;
      height: 49px;
      &:before{
        content:"";
        position: absolute;
        @include sprite($arrow);
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
      i{
        display: none;
      }
    }
    input{
      background-color: unset;
      border-bottom: 2px solid $black;
      margin-right: 7px;
      padding-bottom: 12px;
    }
    input::placeholder{
      color: $black;
      font-weight: 400;
      font-size: 14px;
    }
    .input-group{
      background: unset;
      border: none;
    }
    .psgdpr_consent_message{
      font-size: 13px;
      font-weight: 400;
      margin-top: 5px;
      margin-left: 7px;
      text-align: left;
      color: $black;
      a{
        color: $black;
        border-bottom: 1px solid #808080;
        &:hover{
          color: #7b7b7b;
        }
      }
    }
    .custom-input-item{
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border-color: $black;
      i{
        line-height: 22px;
        &:before{
          color: #000;
        }
      }
    }
  }
  #multilink_8{
    a{
      padding-left: 37px;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-39%);
        transition: 0.3s ease;
      }
      &:hover{
        &:before{
          left: 5px;
        }
      }
      &.fb{
        &:before{
          @include sprite($fb);
        }
      }
      &.insta{
        &:before{
          @include sprite($insta);
        }
      }
      &.twtr{
        &:before{
          @include sprite($twt);
        }
      }
    }
  }
  #st_news_letter_2{
    max-width: 537px;
  }
  @media(min-width: $mobile){
    .col1{
      width: 46%;
    }
    .col2{
      width: 13.5%;
    }
    .col3{
      width: 15.2%;
    }
    .col4{
      width: 16.3%;
    }
    .col5{
      width: 9%;
      padding-right: 0;
    }
  }
  #copywrite{
    margin-left: auto;
    margin-right: auto;
    .right{
      padding-right: 0;
    }
    .left{
      padding-left: 0;
      p{
        font-size: 14px;
        font-weight: 300;
        color: $black;
        a{
          color: $black;
          font-weight: 700;
          &:hover{
            color: #ef5611;
          }
        }
        strong{
          font-weight: 700;
        }
      }
    }
  }
}
@media(max-width: $desktop){
  #footer{
    #footer-top{

      .title_block_inner{

      }
      .footer_block_content{
        li{

        }
        a{

          &:hover{

          }
        }
      }
    }
    .ctitle{

    }
    .st_news_letter{
      p{

      }
    }
    form{

      .st_news_letter_form_inner{

      }
      .input-group-btn{

      }
      button{

        &:before{

        }
      }
      input{

      }
      input::placeholder{

      }
      .input-group{

      }
      .psgdpr_consent_message{

        a{

          &:hover{

          }
        }
      }
      .custom-input-item{

        i{

          &:before{

          }
        }
      }
    }
    #multilink_8{
      a{

        &:before{

        }
        &:hover{
          &:before{

          }
        }
        &.fb{
          &:before{

          }
        }
        &.insta{
          &:before{

          }
        }
        &.twtr{
          &:before{

          }
        }
      }
    }
    #st_news_letter_2{

    }
    @media(min-width: $mobile){
      .col1{
        width: 44%;
        padding-right: 55px;
      }
      .col2{

      }
      .col3{

      }
      .col4{
        width: 15.3%;
      }
      .col5{
        width: 12%;
        padding-right: 15px;
      }
    }
    #copywrite{

      .right{

      }
      .left{

        p{

          a{

            &:hover{

            }
          }
          strong{

          }
        }
      }
    }
  }
}
@media(max-width: $tablet){
  #footer{
    #footer-top{

      .title_block_inner{

      }
      .footer_block_content{
        li{

        }
        a{

          &:hover{

          }
        }
      }
    }
    .ctitle{

    }
    .st_news_letter{
      p{

      }
    }
    form{

      .st_news_letter_form_inner{

      }
      .input-group-btn{

      }
      button{

        &:before{

        }
      }
      input{

      }
      input::placeholder{

      }
      .input-group{

      }
      .psgdpr_consent_message{

        a{

          &:hover{

          }
        }
      }
      .custom-input-item{

        i{

          &:before{

          }
        }
      }
    }
    #multilink_8{
      a{

        &:before{

        }
        &:hover{
          &:before{

          }
        }
        &.fb{
          &:before{

          }
        }
        &.insta{
          &:before{

          }
        }
        &.twtr{
          &:before{

          }
        }
      }
    }
    #st_news_letter_2{

    }
    @media(min-width: $mobile){
      .col1{
        width: 36%;
        padding-right: 26px;
      }
      .col2{
        width: 13.5%;
      }
      .col3{
        width: 18.2%;
      }
      .col4{
        width: 18.3%;
      }
      .col5{
        width: 14%;
        padding-right: 15px;
      }
    }
    #copywrite{

      .right{

      }
      .left{

        p{

          a{

            &:hover{

            }
          }
          strong{

          }
        }
      }
    }
  }
}
@media(max-width: $presta-tablet){
  #footer{
    #footer-top{
      padding: 10px 0;
      .title_block_inner{

      }
      .footer_block_content{
        li{

        }
        a{

          &:hover{

          }
        }
      }
    }
    .ctitle{

    }
    .st_news_letter{
      p{

      }
    }
    form{

      .st_news_letter_form_inner{

      }
      .input-group-btn{

      }
      button{

        &:before{

        }
      }
      input{

      }
      input::placeholder{

      }
      .input-group{

      }
      .psgdpr_consent_message{

        a{

          &:hover{

          }
        }
      }
      .custom-input-item{

        i{

          &:before{

          }
        }
      }
    }
    #multilink_8{
      a{

        &:before{

        }
        &:hover{
          &:before{

          }
        }
        &.fb{
          &:before{

          }
        }
        &.insta{
          &:before{

          }
        }
        &.twtr{
          &:before{

          }
        }
      }
    }
    #st_news_letter_2{

    }
    @media(min-width: $mobile){
      .col{
        min-height: 312px;
      }
      .col1{
        width: 50%;
      }
      .col2{
        width: 25%;
      }
      .col3{
        width: 25%;
      }
      .col4{
        width: 25%;
        min-height: unset;
      }
      .col5{
        width: 75%;
        min-height: unset;
      }
    }
    #copywrite{

      .right{
        width: 45%;
      }
      .left{
        width: 55%;
        p{
         font-size: 12px;
          margin-top: 22px;
          a{

            &:hover{

            }
          }
          strong{

          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #footer{
    #footer-top{
      .opener{
        z-index: 999;
        display: flex;
        i{
          &:before{
            color: $black;
          }
        }
      }
      .footer_block{
        margin: 10px 0;
      }
      .title_block{
        padding: 0 15px;
        margin-bottom: 0;
      }
      .title_block_inner{
        font-size: 14px;
      }
      .st_open{
        .footer_block_content{
          display: block;
        }
      }
      .footer_block_content{
        display: none;
        padding: 0 15px;
        margin-top: 15px;
        li{

        }
        a{

          &:hover{

          }
        }
      }
      .col{
        padding: 0;
        border-top: 1px solid #ccc;
      }
      .col1{
        margin-bottom: 30px;
        padding: 0 15px;
        border: none;
        .footer_block_content{
          display: block;
          padding: 0;
        }
      }
      .col5{
        border-bottom: 1px solid #ccc;
      }
    }
    .ctitle{
      font-size: 25px;
    }
    .st_news_letter_content{
      p{
        font-size: 13px;
      }
    }
    form{
      margin-top: 23px;
      .st_news_letter_form_inner{
        margin-bottom: 0;
      }
      .input-group-btn{

      }
      button{

        &:before{

        }
      }
      input{

      }
      input::placeholder{
        font-size: 12px;
      }
      .input-group{

      }
      .psgdpr_consent_message{

        a{

          &:hover{

          }
        }
      }
      .custom-input-item{

        i{

          &:before{

          }
        }
      }
    }
    #multilink_8{
      a{

        &:before{

        }
        &:hover{
          &:before{

          }
        }
        &.fb{
          &:before{

          }
        }
        &.insta{
          &:before{

          }
        }
        &.twtr{
          &:before{

          }
        }
      }
    }
    #st_news_letter_2{

    }

    #copywrite{

      .right{
        width: 100%;
        padding-left: 0;
      }
      .left{
        width: 100%;
        padding-right: 0;
        .wpb_content_element{
          margin-top: 0;
        }
        p{
          text-align: center;
          margin-top: 5px;
          a{

            &:hover{

            }
          }
          strong{

          }
        }
      }
    }
  }
}