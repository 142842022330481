#product{
  .product_second_section{
    margin-top: 22px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 55px;
    .tab-pane-body{
      max-width: 755px;
      margin: auto;
      font-size: 16px;
      font-weight: 300;
      color: $black;
      padding-top: 60px;
    }
    .nav-tabs{
      border: none;
    }
    .nav-item{
      margin-right: 20px;
      .nav-link{
        border-bottom: 3px solid #fff;
        font-size: 18px;
        font-weight: 300;
        color: $black;
        text-transform: capitalize;
        &.active{
          font-weight: 700;
        }
        &.active,
        &:hover{
          border-bottom: 3px solid $black;
        }
      }
    }
    .product-features{
      border: 1px solid $black;
      .page_heading{
        display: none;
      }
      .data-sheet{
        padding: 6px 20px;
        background-color: #fff;
        color: $black;
        border-bottom: none;
        &:nth-child(2n){
          background-color: $black;
          color: #fff;
        }
      }
    }
  }
}
@media(max-width: $presta-tablet){
  #product{
    .product_second_section{
      margin-top: 0;
      .mobile_tab_title{
        border: 1px solid $black;
        background-color: $black;
        color: #fff;
        text-transform: uppercase;
        font-weight: 400;
        padding: 10px 20px;
        .mobile_tab_name{
          font-size: 12px;
        }
        i{
          &:before{
            color: #fff;
          }
        }
      }
      .tab-pane-body{
        padding-top: 35px;
        padding-bottom: 35px;
      }
    }
  }
}
@media(max-width: $mobile){
  #product{
    .product_second_section{
      padding-bottom: 25px;
      .tab-pane-body{
        font-size: 13px;
      }
      .nav-tabs{

      }
      .nav-item{

        .nav-link{

          &.active{

          }
          &.active,
          &:hover{

          }
        }
      }
      #product-details{
        .tab-pane-body{
          padding: 20px 0 25px 0;
        }
      }
      .product-features{

        .page_heading{

        }
        .data-sheet{

          &:nth-child(2n){

          }
        }
      }
    }
  }
}