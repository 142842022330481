#checkout{
  #header_center,
  #header_right,
  #mobile_bar_left,
  #mobile_bar_right{
    display: none;
  }
  #cms_modal{
    color: #000;
    .close{
      background-color: #000;
      color: #fff;
      padding: 7px;
      width: 32px;
      height: 32px;
      font-size: 21px;
      i{
        margin-right: 0;
        position: relative;
        bottom: 4px;
        right: -1px;
      }
    }
    h1{
      color: #000;
    }
    .style_content.cms_content{
      font-size: 13px!important;
    }
    @media(max-width: 480px){
      .modal-dialog{
        margin: 0;
        .modal-content{
          margin-right: 10px;
        }
      }
    }
  }
  #header_primary_container{
    padding-top: 25px;
    padding-bottom: 25px;
  }
  div#onepagecheckoutps .addresses_customer_container{
    //overflow-y: unset;
  }
  div#onepagecheckoutps div.clear{
    padding-left: 21px;
    margin-top: 6px;
  }
  #easycontent_17{
    display: none;
  }
  div#onepagecheckoutps .onepagecheckoutps_p_step i.fa-pts{
    display: none;
  }
  #opc_modal{
    .modal-content{
      color: #000;
      margin: 0;
      .modal-dialog{
        max-width: 340px;
      }
      .forget_password{
        text-align: left!important;
        line-height: 15px;
        a{
          font-size: 12px;
          line-height: 17px;
        }
      }
      .panel-title{
        font-size: 15px;
        .fa-pts-times-circle{
          display: none;
        }
      }
      #btn_login{
        background-color: #000;
        border-color: #000;
      }
    }
    &.show{
      background-color: #0000003b;
    }
  }
  #wrapper{
    max-width: 1400px;
    margin: auto;
    div#onepagecheckoutps{
      div#onepagecheckoutps_header{
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 19px;
        margin-top: 30px;
        margin-bottom: 33px;
        .row{
          flex-flow: row;
        }
        div#div_onepagecheckoutps_info{
          float: left;
          flex: unset;
          max-width: unset;
          ////max-width: calc(100% - 500px);
          //max-width: calc(100% - 180px);
          h1{
            font-size: 20px;
            font-weight: 700;
            color: #000;
          }
          p{
            font-size: 14px;
            font-weight: 400;
            color: #000;
            max-width: 480px;
          }
        }
        #div_onepagecheckoutps_login{
          flex: 100%;
          max-width: 500px;
        }
        #opc_show_login,
        #btn-logout{
          background-color: unset;
          border: 1px solid #000000;
          color: #000;
          font-weight: 700;
          font-size: 16px;
          height: auto;
          min-width: 180px;
          line-height: 42px;
          transition: 0.3s ease;
          &:hover{
            background-color: #000;
            color: #fff;
            i{
              &:before{
                color: #fff;
              }
            }
          }
        }
        #div_onepagecheckoutps_login{
          br{
            display: none;
          }
          p{
            color: #000;
            font-weight: 400;
            display: inline-block;
            width: 100%;
            float: left;
            text-align: left;
            margin-top: 27px;
            a{
              color: #000;
              display: block;
            }
          }
        }
        #btn-logout{
          float: right;
          padding: 0;
          position: relative;
          padding-right: 0px;
          margin-top: -48px;
          i{
            display: none;
          }
        }
      }
    }
    #onepagecheckoutps_contenedor{
      .row{
        .left_content{
          flex: 0 0 43%;
          max-width: 43%;
          .form-group{
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        .right_content{
          flex: 0 0 57%;
          max-width: 57%;
          padding-left: 52px;
        }
        input[type=radio],
        input[type=checkbox]{
          appearance: none;
          background: none;
          margin-left: 5px;
          margin-right: 5px;
          background: #fff;
          width: 16px;
          height: 16px;
          padding: 6px 6px;
          border-radius: 50%!important;
          border: 2px solid #fff;
          box-shadow: 0px 0px 0px 1px #00000066;
          float: left;
          margin-top: 0!important;
          &:focus{
            outline: none;
            border: 4px solid #fff;
            box-shadow: 0px 0px 0px 1px #000;
            padding: 4px;
          }
          &:checked{
            background: #000;
            box-shadow: 0px 0px 0px 1px  #000;
            border: 4px solid #ffffff;
            padding: 4px;
          }
        }
        #onepagecheckoutps_step_one{
          .small-text{
            color: #000;
          }
          label{
            font-size: 14px;
            color: #000;
          }
          label[for="checkbox_create_invoice_address"]{
            font-size: 16px;
            font-weight: 400;
            color: #000;
            line-height: 21px;
          }
          div#field_customer_checkbox_create_account_guest{
            margin-top: 20px;
            label{
              color: #000;
              font-size: 14px;
              font-weight: 700;
              input{
                margin-right: 17px;
              }
            }
          }
          #field_customer_newsletter{
            input{
              margin-right: 17px;
            }
          }
          div#field_customer_invoice{
            margin-top: 34px;
            margin-bottom: 15px;
            > label{
              color: #000;
              font-size: 16px;
              margin-bottom: 29px;
              font-weight: 900;
            }
            .row{
              label{
                font-size: 14px;
                color: #000;
              }
              .col-6{
                max-width: 150px;
                input{
                  margin-right: 19px;
                }
              }
            }
          }
        }
        #onepagecheckoutps_step_two_container{
          .delivery-option{
            border: none;
            background-color: unset;
            .carrier-item-content{
              padding-bottom: 4px;
              .delivery_option_logo{
                width: 100%;
                .delivery_option_title{
                  color: #000000;
                  font-size: 15px;
                  font-weight: 400;
                }
              }
              .delivery_option_price{
                display: none;
              }
            }
          }
          label{
            color: #000;
            font-size: 15px;
            margin-top: 13px;
            margin-left: 30px;
          }
          label[for="gift_message"]{
            margin-left: 70px;
            font-size: 13px;
            @media(max-width: 1200px){
              margin-left: 55px;
            }
          }
          textarea{
            max-width: calc(100% - 97px);
            min-height: 80px;
            float: right;
            margin-right: 30px;
            @media(max-width: 1200px){
              max-width: calc(100% - 83px);
            }
          }
          #gift{
            margin-right: 19px;
          }
        }
        #onepagecheckoutps_step_three{
          .selected{
            background-color: unset;
            border: none;
          }
          #modirum_confirmation_form{
            margin-top: -20px;
            color: #000;
            font-size: 15px;
            margin-left: 21px;
            select{
              padding: 8px 20px 8px 12px;
              max-width: 140px;

            }
          }
          .payment-option{
            padding-top: 7px;
            padding-bottom: 0px;
            span{
              font-size: 15px;
              font-weight: 400;
              color: #000;
            }
          }
        }
        #onepagecheckoutps_step_review_container{
          margin-top: 41px;
          #remaining_amount_free_shipping{
            border: 1px solid #CCC;
            padding: 10px;
            color: #000;
          }
          #onepagecheckoutps_step_review{
            margin-top: 28px;
            #header-order-detail-content{
              border-bottom: 1px solid #0000001a;
              margin: 0;
              padding-bottom: 10px;
              div{
                text-decoration: none;
                font-size: 14px;
                padding-left: 0;
                font-weight: 700;
                color: #000;
              }
              .offset-1{
                margin-left: 0;
                flex: 0 0 66%;
                max-width: 66%;
              }
            }
            #order-detail-content{
              > .row{
                margin: 0;
                padding-top: 6px;
                padding-bottom: 6px;
                border-bottom: 1px solid #0000001a;
                .image_product{
                  flex: 0 0 11%;
                  padding-left: 0;
                  max-width: 11%;
                  img{
                    border: none;
                  }
                }
                .cart_description{
                  .s_title_block{
                    overflow: visible;
                    max-width: 300px;
                    height: auto;
                  }
                  span{
                    color: #000;
                    font-size: 14px;
                  }
                }
                .quantity-content{
                  flex: 0 0 21.66667%;
                  max-width: 21.66667%;
                  input{
                    width: 53px;
                    height: 46px!important;
                    padding: 5px;
                  }
                  .input-group-btn-vertical{
                    button{
                      height: 23px;
                      border-radius: 0;
                    }
                    i{
                      &:before{
                        font-family: fontello;
                        font-style: normal;
                        font-weight: 400;
                        speak: none;
                        display: inline-block;
                        text-decoration: inherit;
                        width: 1em;
                        text-align: center;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1em;
                        -webkit-font-smoothing: antialiased
                      }
                    }
                    .fa-pts-chevron-down{
                      &:before{
                        content: "\F107";
                      }
                    }
                    .fa-pts-chevron-up{
                      &:before{
                        content: "\F106";
                      }
                    }
                  }
                }
                .text-sm-right{
                  padding-right: 0;
                  .product-price{
                    color: #000;
                    font-weight: 400;
                    font-size: 14px;
                  }
                }
              }
              .order_total_items{
                margin-top: 23px;
                .row{
                  height: auto;
                  span{
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 10px;
                    display: inline-block;
                  }
                }
                .total_price{
                  margin-top: 16px;
                  span{
                    font-weight: 900;
                  }
                }
                #list-voucher-allowed{
                  border: none;
                  div{
                    padding: 0;
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin: 0;
                    a{
                      text-decoration: none;
                      border: 1px solid #000;
                      font-size: 14px;
                      font-weight: 700;
                      color: #000;
                      padding: 13px 15px;
                      display: inline-block;
                      float: right;
                      transition: 0.3s ease;
                      &:hover{
                        background-color: #000;
                        color: #fff;
                      }
                    }
                    .promo-code{
                      width: 100%;
                      display: none;

                      margin-top: 20px;
                      &.show,
                      &.collapsing{
                        display: inline-block;
                      }
                      input{
                        width: calc(100% - 115px);
                      }
                      button{
                        max-width: 110px;
                        text-align: center;
                        height: 50px;
                        border: 1px solid #000;
                        line-height: 32px;
                        background-color: unset;
                        span{
                          font-weight: 700;
                        }
                        &:hover{
                          background-color: #000;
                          span{
                            color: #fff;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            #div_leave_message{
              border: none;
              margin-top: 6px;
              padding-top: 0;
              #message{
                min-height: 90px;
                border-color: #00000033;
                border-radius: 0!important;
                color: #000;
                font-size: 14px;
                &:focus{
                  box-shadow: unset;
                }
              }
              textarea::placeholder{
                color: #000;
                font-size: 14px;
                padding-top: 10px;
                padding-left: 12px;
              }

            }
            #conditions-to-approve{
              margin-top: 12px;
              label{
                color: #000;
                font-size: 14px;
                max-width: 500px;
                line-height: 20px;
                position: relative;
                padding-left: 35px;
                a{
                  text-decoration: none;
                  font-weight: 900;
                }
              }
              input{
                border-radius: 0!important;
                position: absolute;
                left: 0;
                top: 4px;
              }
            }
            #buttons_footer_review{
              margin-top: 39px;
              #btn_continue_shopping{
                display: none;
              }
              #btn_place_order{
                font-size: 16px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                height: auto;
                line-height: 42px;
                width: 100%;
                max-width: 350px;
                border-color: #5cb355;
                background: #5cb355;
                i{
                  display: none;
                }
              }
            }
            #cart-points-summary{
              margin-top: 0;
            }
          }
        }
        #panel_addresses_customer{
          margin-top: 38px;
          button{
            min-height: 55px;
            i{
              display: none;
            }
          }
          .card{
            border: none;
            background-color: #f2f2f2;
            margin-bottom: 29px;
            padding: 15px 12px;
            opacity: 1;
          }
          .card-header{
            background-color: unset;
            border: none;
            a{
              font-weight: 900;
              font-size: 16px;
              color: #000;
            }
          }
        }
        #address_card_new{
          .container_card{
            max-width: 260px;
          }
        }
        .edit_address,
        .selected_address,
        .delete_address,
        .choose_address{
          background-color: unset;
          border: 1px solid #000000ed;
          font-size: 16px;
          font-weight: 700;
          color: #000;
          height: auto;
          line-height: 35px;
          margin-top: 6px;
          opacity: 1;
          display: inline-block;
          width: calc(50% - 5px);
          transition: 0.3s ease;
        }
        .edit_address{
          float: left;
          margin-right: 5px;
          &:hover{
            background-color: #000;
            color: #fff;
          }
        }
        .selected_address,
        .choose_address,
        .delete_address{
          background-color: #000;
          color: #fff;
          float: right;
          line-height: 44px;
          margin-left: 5px;
          &:hover{
            background-color: unset;
            color: #000;
          }
        }
        .choose_address{
          width: 100%;
        }
        .address_card{
          flex: 0 0 100%;
          max-width: 100%;
          .container_card{
            background-color: #dadada;
            border: none;
            border-radius: 0;
            font-size: 16px;
            padding: 13px;
            color: #000;
            width: 100%;
            display: inline-block;
            span{
              font-weight: 400;
              i{
                &:before{
                  font-size: 16px;
                  position: relative;
                  top: -2px;
                  margin-right: 7px;
                }
              }
            }
          }
        }

        .selected{
          background-color: unset;
          border: none;
          .header_card{
            border-top: 1px solid #000;
            border-bottom: none;
            font-weight: 700;
            padding-top: 27px;
            padding-bottom: 20px;
            color: #000;
            span{
              font-size: 16px;
            }
          }
          .content_card{
            height: auto!important;
            ul{
              margin-bottom: 34px;
              li{
                color: #000;
                font-weight: 400!important;
                font-size: 16px;
                i{
                  display: none;
                }
              }
            }
          }

        }
        #btn_save_customer{
          background-color: unset;
          border: 1px solid #000000ed;
          font-size: 16px;
          font-weight: 700;
          color: #000;
          height: auto;
          line-height: 35px;
          margin-top: 27px;
          transition: 0.3s ease;
          &:hover{
            background-color: #000;
            color: #fff;
          }
          i{
            display: none;
          }
        }
        #action_address_delivery{
          #btn_cancel_address_delivery,
          #btn_update_address_delivery{
            background-color: unset;
            border: 1px solid #000000ed;
            font-size: 16px;
            font-weight: 700;
            color: #000;
            height: auto;
            line-height: 35px;
            width: calc(100% - 10px);
            text-decoration: none;
            transition: 0.3s ease;
          }
          #btn_cancel_address_delivery{
            margin-right: 5px;
            height: 100%;
            &:hover{
              background-color: #000;
              color: #fff;
              i{
                &:before{
                  color: #fff;
                }
              }
            }
          }
          #btn_update_address_delivery{
            background-color: #000;
            color: #fff;
            margin-left: 5px;
            float: right;
            &:hover{
              background-color: unset;
              color: #000;
            }
          }
          button{
            min-height: 55px;
          }
        }
      }
      h5.onepagecheckoutps_p_step{
        color: #000;
        font-size: 16px;
        font-weight: 900;
        border-bottom: none;
        margin-bottom: 13px!important;
      }
      input::placeholder{
        color: #000;
        font-size: 14px;
      }
      input,select{
        border-radius: 0!important;
        padding: 17px 19px;
        color: #000;
      }
    }
  }
}
@media(max-width: 1200px){
  #checkout{
    div#onepagecheckoutps .onepagecheckoutps_p_step i.fa-pts{

    }
    #opc_modal{
      .modal-content{

        #btn_login{

        }
      }
      &.show{

      }
    }
    #wrapper{

      div#onepagecheckoutps{
        div#onepagecheckoutps_header{

          .row{

          }
          div#div_onepagecheckoutps_info{

            h1{

            }
            p{

            }
          }
          #div_onepagecheckoutps_login{

          }
          #opc_show_login,
          #btn-logout{

            &:hover{

              i{
                &:before{

                }
              }
            }
          }
          #div_onepagecheckoutps_login{
            br{

            }
            p{

              a{

              }
            }
          }
          #btn-logout{

            i{

            }
          }
        }
      }
      #onepagecheckoutps_contenedor{
        .row{
          .left_content{
            flex: 0 0 37%;
            max-width: 37%;
            .form-group{

            }
          }
          .right_content{
            flex: 0 0 63%;
            max-width: 63%;
            padding-left: 30px;
          }
          input[type=radio],
          input[type=checkbox]{

            &:focus{

            }
            &:checked{

            }
          }
          #onepagecheckoutps_step_one{
            .small-text{

            }
            label{

            }
            label[for="checkbox_create_invoice_address"]{

            }
            div#field_customer_checkbox_create_account_guest{

              label{

                input{

                }
              }
            }
            #field_customer_newsletter{
              input{

              }
            }
            div#field_customer_invoice{

              > label{

              }
              .row{
                label{

                }
                .col-6{

                  input{

                  }
                }
              }
            }
          }
          #onepagecheckoutps_step_two_container{
            .delivery-option{
              .delivery_radio_content{
                margin-right: 10px;
              }
              .carrier-item-content{
                padding: 8px 0px;
                .delivery_option_logo{

                  .delivery_option_title{
                    font-size: 14px;
                  }
                }
                .delivery_option_price{

                }
              }
            }
            label{
              margin-left: 16px;
            }
            #gift{
              margin-right: 15px;
            }
          }
          #onepagecheckoutps_step_three_container{
            padding-left: 5px;
            padding-right: 5px;
          }
          #onepagecheckoutps_step_three{
            .selected{

            }
            .payment-option{
              padding: 8px 0px;
              .payment_input{
                margin-right: 10px;
              }
              span{
                font-size: 14px;
              }
            }
          }
          #onepagecheckoutps_step_review_container{

            #onepagecheckoutps_step_review{

              #header-order-detail-content{


                div{

                }
                .offset-1{

                }
              }
              #order-detail-content{
                > .row{

                  .image_product{

                    img{

                    }
                  }
                  .cart_description{
                    .s_title_block{

                    }
                    span{
                      font-size: 13px;
                    }
                  }
                  .quantity-content{

                    input{

                    }
                    .input-group-btn-vertical{

                    }
                  }
                  .text-sm-right{

                    .product-price{

                    }
                  }
                }
                .order_total_items{

                  .row{

                    span{
                      font-size: 15px;
                      margin-bottom: 6px;
                    }
                  }
                  .total_price{

                    span{

                    }
                  }
                  #list-voucher-allowed{

                    div{

                      a{

                        &:hover{

                        }
                      }
                      .promo-code{

                        &.show,
                        &.collapsing{

                        }
                        input{

                        }
                        button{

                          span{

                          }
                        }
                      }
                    }
                  }
                }
              }
              #div_leave_message{

                #message{

                  &:focus{

                  }
                }
                textarea::placeholder{
                  font-size: 13px;
                }
              }
              #conditions-to-approve{

                label{

                  a{

                  }
                }
                input{

                }
              }
              #buttons_footer_review{

                #btn_continue_shopping{

                }
                #btn_place_order{

                  i{

                  }
                }
              }
              #cart-points-summary{

              }
            }
          }
          #panel_addresses_customer{

            .card{

            }
            .card-header{

              a{

              }
            }
          }
          #address_card_new{
            .container_card{

              span{

                i{
                  &:before{

                  }
                }
              }
            }
          }
          .selected{

            .header_card{

              span{

              }
            }
            .content_card{

              ul{

                li{

                  i{

                  }
                }
              }
            }
            .edit_address,
            .selected_address{

            }
            .edit_address{

              &:hover{

              }
            }
            .selected_address{

              &:hover{

              }
            }
          }
          #btn_save_customer{

            &:hover{

            }
            i{

            }
          }
          #action_address_delivery{
            #btn_cancel_address_delivery,
            #btn_update_address_delivery{
              width: calc(100% - 2px);
              i{
                display: none;
              }
            }
            #btn_cancel_address_delivery{
              margin-right: 2px;
              &:hover{


                i{
                  &:before{

                  }
                }
              }
            }
            #btn_update_address_delivery{
              margin-left: 2px;
              &:hover{

              }
            }
          }
        }
        h5.onepagecheckoutps_p_step{

        }
        input::placeholder{
          font-size: 13px;
        }
        input,select{

        }
      }
    }
  }
}
@media(max-width: 992px){
  #checkout{
    div#onepagecheckoutps .onepagecheckoutps_p_step i.fa-pts{

    }
    #opc_modal{
      .modal-content{

        #btn_login{

        }
      }
      &.show{

      }
    }
    #wrapper{

      div#onepagecheckoutps{
        div#onepagecheckoutps_header{

          .row{

          }
          div#div_onepagecheckoutps_info{

            h1{

            }
            p{

            }
          }
          #div_onepagecheckoutps_login{

          }
          #opc_show_login,
          #btn-logout{

            &:hover{

              i{
                &:before{

                }
              }
            }
          }
          #div_onepagecheckoutps_login{
            br{
              display: block;
            }
            p{
              float: right;
              text-align: right;
              margin-top: 0;
              margin-bottom: 8px;
              a{

              }
            }
          }
          #btn-logout{
            margin-top: 0;
            i{

            }
          }
        }
      }
      #onepagecheckoutps_contenedor{
        .row{
          .left_content{
            flex: 0 0 100%;
            max-width: 100%;

            .form-group{

            }
          }
          .right_content{
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 15px;
          }
          input[type=radio],
          input[type=checkbox]{

            &:focus{

            }
            &:checked{

            }
          }
          #onepagecheckoutps_step_one{
            .small-text{

            }
            label{

            }
            label[for="checkbox_create_invoice_address"]{

            }
            div#field_customer_checkbox_create_account_guest{

              label{

                input{

                }
              }
            }
            #field_customer_newsletter{
              input{

              }
            }
            div#field_customer_invoice{

              > label{

              }
              .row{
                label{

                }
                .col-6{

                  input{

                  }
                }
              }
            }
          }
          #onepagecheckoutps_step_two_container{
            .delivery-option{

              .carrier-item-content{

                .delivery_option_logo{

                  .delivery_option_title{

                  }
                }
                .delivery_option_price{

                }
              }
            }
            label{

            }
            #gift{

            }
          }
          #onepagecheckoutps_step_three{
            .selected{

            }
            .payment-option{

              span{

              }
            }
          }
          #onepagecheckoutps_step_review_container{

            #onepagecheckoutps_step_review{

              #header-order-detail-content{
                @media(min-width: 481px){
                  display: flex!important;
                }

                div{

                }
                .offset-1{
                  max-width: 58%;
                }
                .text-md-center{
                  position: relative;
                  left: 5%;
                }
              }
              #order-detail-content{
                > .row{

                  .image_product{

                    img{

                    }
                  }
                  .cart_description{
                    .s_title_block{

                    }
                    span{

                    }
                  }
                  .quantity-content{

                    input{

                    }
                    .input-group-btn-vertical{

                    }
                  }
                  .text-sm-right{

                    .product-price{

                    }
                  }
                }
                .order_total_items{

                  .row{

                    span{

                    }
                  }
                  .total_price{

                    span{

                    }
                  }
                  #list-voucher-allowed{

                    div{

                      a{

                        &:hover{

                        }
                      }
                      .promo-code{

                        &.show,
                        &.collapsing{

                        }
                        input{

                        }
                        button{

                          span{

                          }
                        }
                      }
                    }
                  }
                }
              }
              #div_leave_message{

                #message{

                  &:focus{

                  }
                }
                textarea::placeholder{

                }
              }
              #conditions-to-approve{

                label{

                  a{

                  }
                }
                input{

                }
              }
              #buttons_footer_review{
                margin-top: 17px;
                #btn_continue_shopping{

                }
                #btn_place_order{

                  i{

                  }
                }
              }
              #cart-points-summary{

              }
            }
          }
          #panel_addresses_customer{

            .card{

            }
            .card-header{

              a{

              }
            }
          }
          #address_card_new{
            .container_card{

              span{

                i{
                  &:before{

                  }
                }
              }
            }
          }
          .selected{

            .header_card{

              span{

              }
            }
            .content_card{

              ul{

                li{

                  i{

                  }
                }
              }
            }
            .edit_address,
            .selected_address{

            }
            .edit_address{

              &:hover{

              }
            }
            .selected_address{

              &:hover{

              }
            }
          }
          #btn_save_customer{
            max-width: 300px;
            margin: 27px auto;
            &:hover{

            }
            i{

            }
          }
          #action_address_delivery{
            flex-flow: row;
            div{
              flex: 0 0 50%;
              max-width: 50%;
            }
            #btn_cancel_address_delivery,
            #btn_update_address_delivery{

            }
            #btn_cancel_address_delivery{

              &:hover{


                i{
                  &:before{

                  }
                }
              }
            }
            #btn_update_address_delivery{

              &:hover{

              }
            }
          }
        }
        h5.onepagecheckoutps_p_step{

        }
        input::placeholder{

        }
        input,select{

        }
      }
    }
  }
}
@media(max-width: 768px){
  #checkout{
    #columns{
      padding-left: 5px;
      padding-right: 5px;
    }
    div#onepagecheckoutps .onepagecheckoutps_p_step i.fa-pts{

    }
    #opc_modal{
      .modal-content{

        #btn_login{

        }
      }
      &.show{

      }
    }
    #wrapper{

      div#onepagecheckoutps{
        div#onepagecheckoutps_header{
          margin-top: 0;
          margin-bottom: 12px;
          .row{
            flex-flow: column-reverse;
          }
          div#div_onepagecheckoutps_info{
            flex: 0 0 100%;
            max-width: 100%;
            h1{
              margin-top: 15px;
              font-size: 18px;
            }
            p{
              font-size: 12px;
            }
          }
          #opc_show_login{
            float: left;
            max-width: 180px;
            margin-bottom: 8px;
            margin-left: 14px;
          }
          #opc_show_login,
          #btn-logout{
            font-size: 14px;
            height: auto;
            line-height: 27px;
            &:hover{

              i{
                &:before{

                }
              }
            }
          }
          #div_onepagecheckoutps_login{
            max-width: 100%;
            br{

            }
            p{
              float: left;
              text-align: left;
              margin-bottom: 15px;
              font-size: 12px;
              a{
                font-size: 12px;

              }
            }
          }
          #btn-logout{
            float: left;
            margin-top: 0px;
            i{

            }
          }
        }
      }
      #onepagecheckoutps_contenedor{
        .row{
          .left_content{

            .form-group{

            }
          }
          .right_content{

          }
          input[type=radio],
          input[type=checkbox]{

            &:focus{

            }
            &:checked{

            }
          }
          #onepagecheckoutps_step_one{
            #form_customer{
              width: 95%;
              display: block;
              margin: auto;
            }
            .small-text{

            }
            label{

            }
            label[for="checkbox_create_invoice_address"]{

            }
            div#field_customer_checkbox_create_account_guest{

              label{

                input{

                }
              }
            }
            #field_customer_newsletter{
              input{

              }
            }
            div#field_customer_invoice{
              margin-bottom: 10px;
              > label{
                font-size: 14px;
                margin-bottom: 20px;
              }
              .row{
                label{

                }
                .col-6{

                  input{

                  }
                }
              }
            }
          }
          #onepagecheckoutps_step_two_container{
            .delivery-option{

              .carrier-item-content{

                .delivery_option_logo{
                  margin-right: 0;
                  .delivery_option_title{

                  }
                }
                .delivery_option_price{

                }
              }
            }
            label{

            }
            #gift{

            }
          }
          #onepagecheckoutps_step_two_container,
          #onepagecheckoutps_step_three_container{
            flex: 0 0 50%;
            max-width: 50%;
          }
          #onepagecheckoutps_step_three{
            .selected{

            }
            .payment-option{

              span{

              }
            }
          }
          #onepagecheckoutps_step_review_container{

            #onepagecheckoutps_step_review{

              #header-order-detail-content{


                div{

                }
                .offset-1{
                  max-width: 60%;
                }
                .text-md-center{
                  left: 4%;
                  max-width: 23%;
                }
                .text-md-right{
                  max-width: 17%;
                  padding-right: 0;
                  text-align: right;
                }
              }
              #order-detail-content{
                > .row{

                  .image_product{

                    img{

                    }
                  }
                  .cart_description{
                    flex: 0 0 50%;
                    max-width: 50%;
                    .s_title_block{

                    }
                    span{

                    }
                  }
                  .quantity-content{
                    flex: 0 0 21.66667%;
                    max-width: 21.66667%;
                    input{

                    }
                    .input-group-btn-vertical{

                    }
                  }
                  .text-sm-right{
                    flex: 0 0 16.66667%;
                    max-width: 16.66667%;
                    .total-price-text{
                      display: none!important;
                    }
                    .product-price{

                    }
                  }
                }
                .order_total_items{

                  .row{

                    span{

                    }
                  }
                  .total_price{

                    span{

                    }
                  }
                  #list-voucher-allowed{

                    div{

                      a{

                        &:hover{

                        }
                      }
                      .promo-code{

                        &.show,
                        &.collapsing{

                        }
                        input{

                        }
                        button{

                          span{

                          }
                        }
                      }
                    }
                  }
                }
              }
              #div_leave_message{

                #message{

                  &:focus{

                  }
                }
                textarea::placeholder{
                  font-size: 11px;
                }
              }
              #conditions-to-approve{

                label{

                  a{

                  }
                }
                input{

                }
              }
              #buttons_footer_review{

                #btn_continue_shopping{

                }
                #btn_place_order{

                  i{

                  }
                }
              }
              #cart-points-summary{

              }
            }
          }
          #panel_addresses_customer{
            margin-top: 23px;
            .card{

            }
            .card-header{

              a{

              }
            }
          }
          #address_card_new{
            .container_card{

              span{

                i{
                  &:before{

                  }
                }
              }
            }
          }
          .selected{

            .header_card{

              span{

              }
            }
            .content_card{

              ul{

                li{

                  i{

                  }
                }
              }
            }
            .edit_address,
            .selected_address{

            }
            .edit_address{

              &:hover{

              }
            }
            .selected_address{

              &:hover{

              }
            }
          }
          #btn_save_customer{

            &:hover{

            }
            i{

            }
          }
          #action_address_delivery{
            #btn_cancel_address_delivery,
            #btn_update_address_delivery{

            }
            #btn_cancel_address_delivery{

              &:hover{


                i{
                  &:before{

                  }
                }
              }
            }
            #btn_update_address_delivery{

              &:hover{

              }
            }
          }
        }
        h5.onepagecheckoutps_p_step{

        }
        input::placeholder{
          font-size: 11px;
        }
        input,select{

        }
      }
    }
  }
}
@media(max-width: 555px){
  #checkout{
    #columns{

    }
    div#onepagecheckoutps .onepagecheckoutps_p_step i.fa-pts{

    }
    #opc_modal{
      .modal-content{

        #btn_login{

        }
      }
      &.show{

      }
    }
    #wrapper{

      div#onepagecheckoutps{
        div#onepagecheckoutps_header{

          .row{

          }
          div#div_onepagecheckoutps_info{

            h1{

            }
            p{

            }
          }
          #opc_show_login{

          }
          #opc_show_login,
          #btn-logout{

            &:hover{

              i{
                &:before{

                }
              }
            }
          }
          #div_onepagecheckoutps_login{

            br{

            }
            p{

              a{


              }
            }
          }
          #btn-logout{

            i{

            }
          }
        }
      }
      #onepagecheckoutps_contenedor{
        .row{
          .left_content{

            .form-group{

            }
          }
          .right_content{

          }
          input[type=radio],
          input[type=checkbox]{

            &:focus{

            }
            &:checked{

            }
          }
          #onepagecheckoutps_step_one{
            #form_customer{

            }
            .small-text{

            }
            label{

            }
            label[for="checkbox_create_invoice_address"]{

            }
            div#field_customer_checkbox_create_account_guest{

              label{

                input{

                }
              }
            }
            #field_customer_newsletter{
              input{

              }
            }
            div#field_customer_invoice{

              > label{

              }
              .row{
                label{

                }
                .col-6{

                  input{

                  }
                }
              }
            }
          }
          #onepagecheckoutps_step_two_container{
            margin-bottom: 20px;
            .delivery-option{

              .carrier-item-content{

                .delivery_option_logo{

                  .delivery_option_title{

                  }
                }
                .delivery_option_price{

                }
              }
            }
            label{

            }
            #gift{

            }
          }
          #onepagecheckoutps_step_two_container,
          #onepagecheckoutps_step_three_container{
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 15px;
            padding-right: 15px;

          }
          #onepagecheckoutps_step_three{
            .selected{

            }
            .payment-option{

              span{

              }
            }
          }
          #onepagecheckoutps_step_review_container{

            #onepagecheckoutps_step_review{

              #header-order-detail-content{


                div{

                }
                .offset-1{

                }
                .text-md-center{

                }
                .text-md-right{

                }
              }
              #order-detail-content{
                > .row{

                  .image_product{

                    img{

                    }
                  }
                  .cart_description{

                    .s_title_block{

                    }
                    span{

                    }
                  }
                  .quantity-content{

                    input{

                    }
                    .input-group-btn-vertical{

                    }
                  }
                  .text-sm-right{

                    .total-price-text{

                    }
                    .product-price{

                    }
                  }
                }
                .order_total_items{

                  .row{

                    span{

                    }
                  }
                  .total_price{

                    span{

                    }
                  }
                  #list-voucher-allowed{

                    div{

                      a{

                        &:hover{

                        }
                      }
                      .promo-code{

                        &.show,
                        &.collapsing{

                        }
                        input{

                        }
                        button{

                          span{

                          }
                        }
                      }
                    }
                  }
                }
              }
              #div_leave_message{

                #message{

                  &:focus{

                  }
                }
                textarea::placeholder{

                }
              }
              #conditions-to-approve{

                label{

                  a{

                  }
                }
                input{

                }
              }
              #buttons_footer_review{

                #btn_continue_shopping{

                }
                #btn_place_order{

                  i{

                  }
                }
              }
              #cart-points-summary{

              }
            }
          }
          #panel_addresses_customer{

            .card{

            }
            .card-header{

              a{

              }
            }
          }
          #address_card_new{
            .container_card{

              span{

                i{
                  &:before{

                  }
                }
              }
            }
          }
          .selected{

            .header_card{

              span{

              }
            }
            .content_card{

              ul{

                li{

                  i{

                  }
                }
              }
            }
            .edit_address,
            .selected_address{

            }
            .edit_address{

              &:hover{

              }
            }
            .selected_address{

              &:hover{

              }
            }
          }
          #btn_save_customer{

            &:hover{

            }
            i{

            }
          }
          #action_address_delivery{
            #btn_cancel_address_delivery,
            #btn_update_address_delivery{

            }
            #btn_cancel_address_delivery{

              &:hover{


                i{
                  &:before{

                  }
                }
              }
            }
            #btn_update_address_delivery{

              &:hover{

              }
            }
          }
        }
        h5.onepagecheckoutps_p_step{

        }
        input::placeholder{

        }
        input,select{

        }
      }
    }
  }
}
@media(max-width: 480px){
  #checkout{
    #columns{
      padding-left: 0;
      padding-right: 0;
    }
    div#onepagecheckoutps .onepagecheckoutps_p_step i.fa-pts{

    }

    #opc_modal{
      .modal-content{

        #btn_login{

        }
      }
      &.show{

      }
    }
    #wrapper{

      div#onepagecheckoutps{
        div#onepagecheckoutps_header{

          .row{

          }
          div#div_onepagecheckoutps_info{

            h1{

            }
            p{

            }
          }
          #opc_show_login{

          }
          #opc_show_login,
          #btn-logout{

            &:hover{

              i{
                &:before{

                }
              }
            }
          }
          #div_onepagecheckoutps_login{

            br{

            }
            p{

              a{


              }
            }
          }
          #btn-logout{

            i{

            }
          }
        }
      }
      #onepagecheckoutps_contenedor{
        .row{
          .left_content{

            .form-group{

            }
          }
          .right_content{
            padding-top: 0;
          }
          input[type=radio],
          input[type=checkbox]{

            &:focus{

            }
            &:checked{

            }
          }
          #onepagecheckoutps_step_one{
            #form_customer{

            }
            .small-text{

            }
            label{

            }
            label[for="checkbox_create_invoice_address"]{
              font-size: 13px;
              input{
                margin-top: 3px !important;
              }
            }
            div#field_customer_checkbox_create_account_guest{

              label{

                input{

                }
              }
            }
            #field_customer_newsletter{
              input{

              }
            }
            div#field_customer_invoice{
              margin-top: 18px;
              > label{

              }
              .row{
                label{

                }
                .col-6{

                  input{

                  }
                }
              }
            }
          }
          #onepagecheckoutps_step_two_container{

            .delivery-option{

              .carrier-item-content{
                padding: 5px 0px;
                .delivery_option_logo{

                  .delivery_option_title{
                    font-size: 13px;
                  }
                }
                .delivery_option_price{

                }
              }
            }
            label{

            }
            #gift{

            }
          }
          #onepagecheckoutps_step_two_container,
          #onepagecheckoutps_step_three_container{


          }
          #onepagecheckoutps_step_three{
            .selected{

            }
            .module_payment_container{
              padding: 0;
            }
            .payment-option{
              padding: 5px 0px;
              span{
                font-size: 13px;
              }
            }
          }
          #onepagecheckoutps_step_review_container{
            #remaining_amount_free_shipping{
              margin-left: 0px;
              margin-right: 2px;
              padding: 4px;
              font-size: 12px;
              .col-sm-2{
                flex: 0 0 22%;
                max-width: 22%;
              }
              .col-sm-8{
                flex: 0 0 56%;
                max-width: 56%;
              }
              #remaining_amount_free_shipping-text{
                font-size: 11px;
                span{
                  font-size: 11px;
                }
              }
            }
            #onepagecheckoutps_step_review{
              border-top: 1px solid #0000001a;
              #header-order-detail-content{


                div{

                }
                .offset-1{

                }
                .text-md-center{

                }
                .text-md-right{

                }
              }
              #order-detail-content{
                > .row{

                  .image_product{
                    max-width: 20%;
                    flex: 0 0 20%;
                    img{

                    }
                  }
                  .cart_description{
                    max-width: 80%;
                    flex: 0 0 80%;
                    .s_title_block{

                    }
                    span{
                      font-size: 11px;
                    }
                  }
                  .row.clear{
                    display: none;
                  }
                  .quantity-content{
                    padding-left: 0;
                    max-width: 93px;
                    .remove-from-cart{
                      position: relative;
                      bottom: 12px;
                      margin-left: 10px;
                    }
                    input{

                    }
                    .input-group-btn-vertical{

                    }
                  }
                  .text-sm-right{
                    flex: 0 0 100%;
                    max-width: calc(100% - 100px);
                    .total-price-text{

                    }
                    .product-price{
                      text-align: left!important;
                      font-weight: 700;
                    }
                  }
                }
                .order_total_items{
                  margin-top: 12px;
                  .row{

                    span{
                      font-size: 14px;
                      margin-bottom: 3px
                    }
                  }
                  .total_price{
                    margin-top: 6px;

                    span{

                    }
                  }
                  #list-voucher-allowed{

                    div{

                      a{
                        font-size: 13px;
                        padding: 8px 9px;
                        &:hover{

                        }
                      }
                      .promo-code{

                        &.show,
                        &.collapsing{

                        }
                        input{
                          padding: 10px;
                        }
                        button{
                          height: 39px;
                          line-height: 21px;
                          span{
                            font-size: 12px;
                          }
                        }
                      }
                    }
                  }
                }
              }
              #div_leave_message{

                #message{

                  &:focus{

                  }
                }
                textarea::placeholder{

                }
              }
              #conditions-to-approve{

                label{
                  font-size: 11px;
                  a{

                  }
                }
                input{

                }
              }
              #buttons_footer_review{

                #btn_continue_shopping{

                }
                #btn_place_order{

                  i{

                  }
                }
              }
              #HOOK_SHOPPING_CART{
                display: none;
              }
              #cart-points-summary{
                .card{
                  display: inline-block;
                  font-size: 12px;
                }
                br{
                  display: none;
                }
              }
            }
          }
          #panel_addresses_customer{


            .card{
              padding: 10px 5px;
              margin-bottom: 14px;
            }
            .card-header{

              a{
                font-size: 14px;
              }
            }
          }
          .edit_address,
          .selected_address,
          .delete_address,
          .choose_address{
            font-size: 14px;
            line-height: 30px;
          }
          .selected_address,
          .choose_address{
            line-height: 37px;
            &:hover{

            }
          }
          .address_card{
            .container_card{

              span{

                i{
                  &:before{

                  }
                }
              }
            }
          }
          .selected{

            .header_card{

              span{

              }
            }
            .content_card{

              ul{

                li{

                  i{

                  }
                }
              }
            }

            .edit_address{

              &:hover{

              }
            }
          }
          #btn_save_customer{
            font-size: 14px;
            line-height: 30px;
            margin: 13px auto;
            &:hover{

            }
            i{

            }
          }
          #action_address_delivery{
            #btn_cancel_address_delivery,
            #btn_update_address_delivery{
              font-size: 14px;
            }
            #btn_cancel_address_delivery{

              &:hover{


                i{
                  &:before{

                  }
                }
              }
            }
            #btn_update_address_delivery{

              &:hover{

              }
            }
          }
        }
        h5.onepagecheckoutps_p_step{
          font-size: 15px;
          margin-bottom: 5px !important;
        }
        input::placeholder{

        }
        input,select{

        }
      }
    }
  }
}