.quickview{
  #product-description-short{
    display: none;
  }
  .pro_cart_right{
    display: none;
  }
  .product_name{
    font-size: 26px;
    font-weight: 300;
    color: $black;
  }
  .price{
    font-size: 30px;
    font-weight: 700;
    color: $black;
  }
  .regular-price{
    margin-left: 10px;
    font-size: 20px;
    color: #a3a2a2;
    font-weight: 300;
  }
  .product-variants{

    .control-label{

    }
    .li_fl{
      float: left;
    }
    .radio-label{
      border: 2px solid #fff;
      padding: 3px;
      line-height: 29px;
      &:hover{

      }
    }
    .input-radio{

    }
    .input-radio:checked+span{
      border-color: $black;
    }
    input:hover+span{
      border-color: $black;
      line-height: 29px;
    }
    .color{
      border: none;

    }
    .input-color:checked+span::before{

    }
  }
}
@media(max-width: $mobile){
  .quickview{
    .st_modal_close{
      right: 0;
      z-index: 999;
      background-color: $black;
      span{
        color: #fff;
      }
    }
    .modal-dialog{
      margin-left: 10px;
      margin-right: 10px;
    }
    .modal-content{
      width: 100%;
      margin-left: 0;
      padding-top: 30px
    }
    .product-add-to-cart{
      margin-top: 30px;
      .pro_cart_block{
        flex-flow: row;
      }
      .product-quantity{

      }
      .pro_cart_right{
        width: calc(12.5% - 6px);
        margin-left: 6px;
      }
      .input-group{

      }
      .qty{
        display: inline-block;
        width: 87px;
        margin-right: 2px;
        button,
        input{
          height: 50px !important;

        }
        input{
          font-size: 14px;
        }
        button{
          width: 30px;
          font-size: 0;
          &:before{

          }
          &:hover{

          }
        }
        .bootstrap-touchspin-down{
          &:before{

          }
        }
        .bootstrap-touchspin-up{
          &:before{

          }
        }
      }
      .add{
        width: calc(100% - 94px);
        display: inline-block;
        button{
          height: 54px;
          padding: 10px 6px;
          span{
            font-size: 11px;
          }
          i{

          }
        }
      }
      .add_to_wishlit{
        left: 3px;
        &:hover{

        }
        i{

          &:before{

          }
        }
      }
    }
  }
}