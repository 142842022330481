.show-left-column{
  #page_banner_container_1{
    .breadcrumb_nav{
      max-width: 1820px;
      margin: auto;
    }
  }
  #columns{
    max-width: 1853px;
    margin-top: 16px;
  }
  #center_column{
    flex: 0 0 80.6%;
    max-width: 80.6%;
    padding-left: 57px;
  }
  #left_column{
    flex: 0 0 19.4%;
    max-width: 19.4%;
  }
}
@media(max-width: $tablet){
  .show-left-column{
    #page_banner_container_1{
      .breadcrumb_nav{

      }
    }
    #columns{

    }
    #center_column{
      flex: 0 0 75%;
      max-width: 75%;
      padding-left: 30px;

    }
    #left_column{
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
@media(max-width: $presta-tablet){
  .show-left-column{
    #page_banner_container_1{
      .breadcrumb_nav{

      }
    }
    #columns{

    }
    #center_column{
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 15px;

    }
    #left_column{
      flex: 0 0 100%;
      max-width: 300px;
      height: 100%;
      .main_column_box{
        padding-top: 15px;
      }
    }
  }
}
@media(max-width: $mobile){
  .show-left-column{
    #page_banner_container_1{
      .breadcrumb_nav{

      }
    }
    #columns{

      margin-top: 5px;
    }
    #center_column{

    }
    #left_column{
    
    }
  }
}