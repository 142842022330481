#insta{
  margin-top: 68px;
  margin-bottom: 86px;
  .ins_image{
    height: 355px;
    object-fit: cover;
    width: 355px;
    object-position: top;
  }
  h5{
    text-align: center;
    font-size: 36px;
    margin-bottom: 11px;
  }
  h3{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
  }
}
@media(max-width: $presta-tablet){
  #insta{
    margin-top: 0px;
    h5{

    }
    h3{

    }
  }
}
@media(max-width: $mobile){
  #insta{
    margin-top: 0px;
    margin-bottom: 0;
    h5{
      font-size: 26px;
      margin-bottom: 4px;
    }
    h3{
      font-size: 15px;
    }
  }
}