#header_right_bottom{
  border-top: 1px solid #ccc;
  padding-top: 8px;
  margin-bottom: 15px;
  p{
    text-align: center;
    margin-bottom: 0;
  }
  .pipe{
    margin: 0 15px;
  }
  span{
    color: $black;
    font-weight: 400;
    font-size: 13px;
    a{
      color: $black;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        display: inline-block;
        height: 2px;
        left: 0px;
        bottom: -5px;
        background-color: $black;
        transition: 0.3s ease;
        right: 100%;

      }
      &:hover{
        &:before{
          right: 0px;
        }
      }
    }
    strong{
      font-weight: 700;
    }
  }
}