#swiper_container_out_1{
  margin-bottom: 21px;
}
.swiper_images_slider{

  .st_image_layered_description_inner{
    @media(min-width: $mobile){
      position: absolute;
      left: 12%;
      top: 26%;
      transform: translateY(-50%);
    }
  }
  h3{
    text-align: left;
    font-size: 29px;
    font-weight: 300;
    margin-bottom: 21px;
  }
  h4{
    font-size: 62px;
    font-weight: 700;
  }
  .shop{
    margin-top: 78px;
  }
}
.num-count{
  color: $black;
  font-weight: 700;
  position: absolute;
  bottom: 2%;
  left: 50px;

  .inline{
    display: inline-block;
  }
  .start{
    font-size: 88px;
    span{
      overflow: hidden;
      max-width: 33px;
      width: 100%;
      display: inline-flex;
      transform: rotate(180deg);
    }
  }
  .pipe{
    font-size: 26px;
  }
  .end{
    font-size: 26px;
  }
}
#body_wrapper{
  .swiper_images_slider{
    .swiper-button{
      top: auto;
      bottom: 9%;
    }
    .swiper-button-next{
      right: 77px;
    }
    .swiper-button-prev{
      left: auto;
      right: 142px;
    }
  }
}
@media(max-width: 1360px){
  .swiper_images_slider{

    .st_image_layered_description_inner{
      @media(min-width: $mobile){
        left: 5%;
        top: 18%;
      }
    }
    h3{
      font-size: 26px;
      margin-bottom: 15px;
    }
    h4{
      font-size: 50px;
    }
    .shop{
      margin-top: 40px;
    }
  }
  .num-count{
    bottom: 10px;
    .inline{
      display: inline-block;
    }
    .start{
      font-size: 40px;
      span{
        max-width: 15px;
      }
    }
    .pipe{
      font-size: 23px;
    }
    .end{
      font-size: 23px;
    }
  }
  #body_wrapper{
    .swiper_images_slider{
      .swiper-button{
        bottom: 23px;
      }
      .swiper-button-next{
        right: 77px;
      }
      .swiper-button-prev{
        right: 142px;
      }
    }
  }
}
@media(max-width: $presta-tablet){
  .swiper_images_slider{

    .st_image_layered_description_inner{
      @media(min-width: $mobile){
        left: 3%;
      }
    }
    h3{
      font-size: 23px;
      margin-bottom: 4px;
    }
    h4{
      font-size: 38px;
    }
    .shop{
      margin-top: 18px;
    }
  }
  .num-count{
    bottom: 0px;
    .inline{

    }
    .start{

      span{

      }
    }
    .pipe{

    }
    .end{

    }
  }
  #body_wrapper{
    .swiper_images_slider{
      .swiper-button{
        display: none!important;
      }
      .swiper-button-next{

      }
      .swiper-button-prev{

      }
    }
  }
}
@media(max-width: $mobile){
  .swiper_images_slider{
    img{
      min-height: 250px;
      object-fit: cover;
    }
    .st_image_layered_description_inner{
      background-color: #ffffff9c;
      padding: 19px 24px;
    }
    h3{
      font-size: 19px;
    }
    h4{
      font-size: 30px;
    }
    .shop{

    }
  }
  .num-count{
    left: 15px;
    .inline{

    }
    .start{
      font-size: 26px;
      span{
        max-width: 10px;
      }
    }
    .pipe{

    }
    .end{

    }
  }
  #body_wrapper{
    .swiper_images_slider{
      .swiper-button{
        display: none;
      }
      .swiper-button-next{

      }
      .swiper-button-prev{

      }
    }
  }
}