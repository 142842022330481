.category_page_heading{
  color: $black;
  font-size: 24px;
  font-weight: 700;
}
#category-description{
  color: $black;
  letter-spacing: 0.8px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}
@media(max-width: $presta-tablet){
  .category_page_heading{
    font-size: 21px;
  }
  #category-description{
    font-size: 14px;
  }
}
@media(max-width: $mobile){
  .category_page_heading{
    font-size: 18px;
  }
  #category-description{
    font-size: 12px;
    line-height: 23px;
  }
}