#usp{
  .common{
    max-width: 1705px;
    padding: 0 15px;
  }
  background-color: $black;
  padding: 45px 0 38px 0;
  .wpb_single_image{
    margin-bottom: 21px;
  }
  .col3{
    .wpb_single_image{
      margin-bottom: 17px;
    }
  }
  .col4{
    .wpb_single_image{
      margin-bottom: 12px;
    }
  }
  .bottom{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    strong,
    a{
      color: #fff;
      font-size: 16px;
    }
    strong{
      display: block;
      margin-bottom: 3px;
    }
    p{
      line-height: 17px;
      max-width: 253px;
      color: #bdbdbd;
      font-weight: 200;
      margin: auto;
    }
  }
}
@media(max-width: $tablet){
  #usp{
    .common{

    }

    .wpb_single_image{

    }
    .col3{
      .wpb_single_image{

      }
    }
    .col4{
      .wpb_single_image{

      }
    }
    .bottom{

      strong,
      a{

      }
      strong{

      }
      p{
        font-size: 12px;
      }
    }
  }
}
@media(max-width: $presta-tablet){
  #usp{
    .common{
      padding: 0;
    }
    .col{
      padding: 0 10px;
    }
    .wpb_single_image{

    }
    .col3{
      .wpb_single_image{

      }
    }
    .col4{
      .wpb_single_image{

      }
    }
    .bottom{

      strong,
      a{
        font-size: 14px;
      }
      strong{

      }
      p{
        font-size: 11px;
      }
    }
  }
}
$animationSpeed: 12s;
@keyframes scroll {
  0% { transform: translateX(300px); }
  100% { transform: translateX(calc(-240px * 4))}
}
@media(max-width: $mobile){
  #usp{
    padding: 25px 0;
    .common{
      max-width: 420px;
      display: flex;
      margin: auto;
      overflow:hidden;
      position: relative;
      flex-flow: row;
    }
    .col{
      max-width: 220px;
      flex: 0 0 100%;
      animation: scroll $animationSpeed linear infinite;
      display: flex;
    }
    .wpb_single_image{

    }
    .col3{
      .wpb_single_image{

      }
    }
    .col4{
      .wpb_single_image{

      }
    }
    .bottom{

      strong,
      a{

      }
      strong{

      }
      p{

      }
    }
  }
}