#left_column{
  .block-categories{
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px;
    margin-bottom: 0;
    .block_content{
      padding-top: 0;
    }
    .category-sub-menu{
      li[data-depth="0"]{
        a{
          color: $black;
          font-weight: 400;
          font-size: 16px;
          padding: 3px 0;
          padding-right: 8px;
          &:hover{
            color: #999;
          }
        }
        > .acc_header{
          a{
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
      .category-sub-menu{
        margin-top: 9px;
        .category-sub-menu{
          li{
            a{
              font-size: 14px;
            }
          }
          .category-sub-menu{
            li{
              a{
                font-size: 13px;
              }
            }
          }
        }
      }

      li{
        border: none;
        i{
          &:before{
            color: $black;
            font-size: 14px;
          }
        }
        .current_cate{
          a{
            padding-left: 13px;
            position: relative;
            &:before{
              content: "";
              position: absolute;
              display: inline-block;
              left: 0;
              background-color: $black;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
#search_filters_wrapper{
  .facet-title-mobile{
    @media(min-width: $presta-tablet){
      pointer-events: none;
    }
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 18px;
    color: $black;
    @media(min-width: $presta-tablet){
      i{
        display: none;
      }
    }
  }
  .facet_filter_box{
    display: block;
    max-height: 271px;
  }
  .facet_filter_box::-webkit-scrollbar{
    width: 5px;
  }
  .facet_filter_box::-webkit-scrollbar-track{
    background-color: #ccc;
    border: 2px solid #fff;
  }
  .facet_filter_box::-webkit-scrollbar-thumb{
    background-color: $black;
  }
  .faceted-slider{
    margin-top: 18px;
    padding-bottom: 0px;
    margin-bottom: 24px;
    margin-left: 14px;
    .ui-slider-handle{
      border-radius: 50%;
      width: 22px;
      height: 22px;
      border: none;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      top: -8px;
      background: #ffffff;
    }
    .ui-slider{
      height: 8px;
      border-radius: 4px;
      background: #dddddd;
      border-color: #ddd;
    }
    .ui-slider-range{
      background: $black;
      height: 8px;
    }
    p{
      font-size: 14px;
      color: #595b5b;
      font-weight: 400;
      margin-bottom: 25px;
    }
  }
  .facet{
    padding: 25px 0 28px 0;
    border-bottom: 1px solid #dddddd;
    &:last-child{
      border: none;
    }
    .facet-label{
      margin-bottom: 0;
    }
  }
  .facet-label{
    .search-link{
      color: $black;
      width: calc(100% - 40px);
      font-size: 16px;
      &:hover{
        color: #999;
      }
    }
  }
  .custom-input-box{
    margin-right: 15px;
  }
  .custom-input-item{
    width: 18px;
    height: 18px;
    border: 1px solid #a4a4a4;
    background: unset;
  }
  label.active{
    .custom-input-item{
      background-color: $black;
      border-color: $black;
      i{
        &:before{
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }
  }
  .magnitude{
    display: none;
  }
}
@media(max-width: $presta-tablet){
  #left_column{
    .block-categories{
      padding-bottom: 8px;

      .block_content{

      }
      .category-sub-menu{
        .category-sub-menu{

        }
        li[data-depth="0"]{
          a{
            font-size: 14px;
            &:hover{

            }
          }
          > .acc_header{
            a{
              font-size: 16px;
            }
          }
        }
        li{

          i{
            &:before{

            }
          }
          .current_cate{
            a{

              &:before{

              }
            }
          }
        }
      }
    }
  }
  #search_filters_wrapper{
    .facet-title-mobile-inner{
      font-size: 16px;
    }
    .collapsed + .facet_filter_box{
      display: none;
    }
    .facet-title-mobile{
      margin-bottom: 0;
      i{
        opacity: 1;
        &:before{
          color: $black;
        }
      }
      .arrow_up{
        &:before{
          content: "\F107";
        }
      }
      .arrow_down{
        &:before{
          content: "\F106";
        }
      }
    }
    .facet_filter_box{

    }
    .facet_filter_box::-webkit-scrollbar{

    }
    .facet_filter_box::-webkit-scrollbar-track{

    }
    .facet_filter_box::-webkit-scrollbar-thumb{

    }
    .faceted-slider{

      .ui-slider-handle{

      }
      .ui-slider{

      }
      .ui-slider-range{

      }
      p{

      }
    }
    .block_content{
      padding-top: 0;
    }
    .facet{
      padding: 9px 0 9px 0;
      ul{
        margin-top: 10px;
      }
      &:last-child{

      }
      .facet-label{

      }
    }
    .facet-label{
      .search-link{
        font-size: 14px;
        &:hover{

        }
      }
    }
    .custom-input-box{

    }
    .custom-input-item{

    }
    label.active{
      .custom-input-item{

        i{
          &:before{

          }
        }
      }
    }
    .magnitude{

    }
  }
}