#header_left{
  .shop_logo{
    width: 100%;
    max-width: 216px;
    img{
      width: 100%;
    }
  }
}
#header_primary{
  position: relative;
}
#product #st_header{
  z-index: 999;
}
#st_header{
  #header_primary_container{
    height: auto;
    padding: 0;
    position: unset;
    #header_primary_row{
      max-width: 1470px;
      margin: auto;
    }
    .search_widget_block{
      width: 100%;
      max-width: 396px;
      float: right;
    }
  }
  .search_widget_form_inner{
    border: none;
    .icon_text{
      color: #000;
    }
    &.input-group.input-group-with-border{
      border-bottom: 2px solid $black;
    }
    input{
      background: unset;
    }
    button{
      background: #fff;
      &:hover{
        background: #fff;
      }
      border: none;
      i{
        &:before{
          position: relative;
          top: 3px;
          color: $black;
        }
      }
    }
  }
  .blockcart{
    order: 3;
    margin-bottom: 5px;
    .ajax_cart_quantity{
      background: unset;
      font-size: 12px;
      color: $black;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-30%);
    }
    .ajax_cart_bag{
      background: unset;
      border: none;
      i{
        &:before{
          content: "";
          @include sprite($cart);
        }
      }
    }
  }
  .login,
  .userinfo_mod_top{
    margin-right: 15px;
    .fto-user{
      &:before{
        content: "";
        @include sprite($user);
      }
    }
  }
  .userinfo_mod_top{
    margin-right: 0px;
    .dropdown_list_ul{
      li{
        span,a{
          color: #000;
        }
      }
    }
  }
  .wishlist_link{
    .wishlist_customer_count{
      display: none;
    }
    i{
      &:before{
        content:"";
        @include sprite($heart);
      }
    }
  }
  &.stuck{
    #header_primary_row{
      display: none;
    }
    #header_right_bottom{
      @media(min-width: $presta-tablet){
        margin-top: 15px;
      }
    }
  }
}
#header_center{
  margin-left: 65px;
  .ml_level_0{
    padding: 25px 7px;
    transition: 0.3s ease;
    .ma_level_0{
      &:before{
        content: "";
        position: absolute;
        display: inline-block;
        width: 42px;
        height: 2px;
        background-color: #ffffff;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        opacity: 0;
      }
    }
    &.current,
    &:hover{
      background-color: $black;
      .ma_level_0{
        color: #fff;
        &:before{
          opacity: 1;
        }
      }
    }
  }
  .ma_level_0{
    font-size: 18px;
    font-weight: 700;
    color: $black;
    &:after{
      display: none;
    }
  }
}
#header_right{
  margin-top: 17px;
  @media(min-width: 1460px){
    position: relative;
    left: 30px;
  }
}
.st_mega_menu_container{
  margin-right: auto;
}
.search_widget_simple{
  margin-right: auto;
  margin-left: 25px;
  width: 45.5%;
  margin-bottom: 18px;
  @media(min-width: $presta-tablet){
    .dropdown_tri{
      display: none;
    }
    .dropdown_list{
      display: block;
      position: relative;
      &:before{
        display: none;
      }
    }
  }
}
@media(max-width: 1300px){
  #header_left{
    .shop_logo{

      img{

      }
    }
  }
  #header_primary{

  }
  #st_header{
    #header_primary_container{

      .search_widget_block{

      }
    }
    .search_widget_form_inner{

      &.input-group.input-group-with-border{

      }
      input{

      }
      button{

        &:hover{

        }

        i{
          &:before{

          }
        }
      }
    }
    .blockcart{

      .ajax_cart_quantity{

      }
      .ajax_cart_bag{

        i{
          &:before{

          }
        }
      }
    }
    .login,
    .userinfo_mod_top{

      i{
        &:before{

        }
      }
    }
    .wishlist_link{
      i{
        &:before{

        }
      }
    }
    &.stuck{
      #header_primary_row{

      }
      #header_right_bottom{

      }
    }
  }
  #header_center{
    margin-left: 15px;
    .ml_level_0{
      .ma_level_0{
        padding: 0 4px;
        font-size: 16px;
        &:before{

        }
      }
      &.current,
      &:hover{

        .ma_level_0{

          &:before{

          }
        }
      }
    }
    .ma_level_0{

      &:after{

      }
    }
  }
  #header_right{


  }
  .st_mega_menu_container{
    margin-right: 0px;
  }
  .search_widget_simple{
    margin: 0 auto 18px auto;
    width: 37.5%;
    .dropdown_tri{

    }
    .dropdown_list{

    }
  }
}
@media(max-width: $presta-tablet){
  body#index .header-container{
    margin-bottom: 0;
  }
  #mobile_bar{
    padding: 12px 0 0 0;
    .blockcart{
      margin-bottom: 0;
    }
  }
  .mobile_logo{
    .logo{
      width: 100%;
    }
  }
 #page_wrapper #top_bar{
    padding: 6px 0;
    display: block;
  }
#easycontent_19{
  margin-bottom: 0;
  background-color: $black;
  padding: 5px;
  font-size: 13px;
  margin-top: 12px;
  p{
    color: #fff;
    margin-bottom: 0;
    text-align: center;
  }
  a{
    color: #fff;
  }
}
  .blockcart{
    .dropdown_list{
      display: none!important;
    }
  }
  .search_widget_simple{
    margin: 0;
    box-shadow: 0 0 5px 2px #0000002e;
    min-width: 32px;
    height: 32px;
    .dropdown_list{
      min-width: 289px;
      left: -31px;
    }
    i{
      width: 32px;
      height: 32px;
      line-height: 30px;
      display: inline-block;
      text-align: center;
      &:before{
        color: $black;
      }
    }
  }
  #rightbar_4{
    background-color: $black;
    margin-right: 15px;
    min-width: 32px;
    height: 32px;
    line-height: 27px;
    i{
      &:before{
        color: #fff;
        line-height: 34px;
      }
    }
  }
}
@media(max-width: $mobile){
  body#index .header-container{

  }
  #mobile_bar_center{
    padding: 0 12px 0 15px;
  }
  #mobile_bar{
    .container{
      padding-right: 5px;
    }
    .blockcart{

    }
  }
  .mobile_logo{
    .logo{

    }
  }
  #page_wrapper #top_bar{
    P{
      text-align: center;
      font-size: 10px;
    }
    #top_bar_row{
      padding-left: 0;
      padding-right: 0;
    }
  }
  #easycontent_19{

    p{
      font-size: 12px;
    }
    a{

    }
  }
  .search_widget_simple{

    .dropdown_list{

    }
    i{

      &:before{

      }
    }
  }
  #rightbar_4{

    i{
      &:before{

      }
    }
  }
  #st_header{
    .login,
    .userinfo_mod_top{
      margin-right: 5px;
    }
  }
}
