.same{
  font-size: 50px;
  text-shadow: -3px 3px 10px #000000a1;
}
#n-collection{
  margin-top: 95px;
  margin-bottom: 67px;
  #st_banner_block_6{
    overflow: visible;
    width: 100%;
    display: inline-block;
  }
  #banner_box_9{
    padding-left: 0;
    flex: 0 0 72%;
    max-width: 72%;
    .st_image_layered_description_inner{
      position: absolute;
      right: 0.5%;
      top: 24%;
    }
    img{
      float: left;
    }
  }
  #banner_box_10{
    padding-right: 0;
    flex: 0 0 28%;
    max-width: 28%;
    img{
      float: right;
      margin-top: 91px;
    }
  }
  h6{
    padding-left: 110px;
    margin-bottom: 60px;
  }
  h5{
    line-height: 57.93px;
    max-width: 330px;
    margin-bottom: 0;
    &:before{
      width: 70px;
      height: 5px;
      top: 22px;
    }
  }
  a{
    font-size: 16px;
    font-weight: 700;
    color: $black;
    border-bottom: 1px solid $black;
  }
}
@media(max-width: $desktop){
  .same{
    font-size: 37px;
  }
  #n-collection{

    #st_banner_block_6{

    }
    #banner_box_9{
      padding-right: 92px;
      .st_image_layered_description_inner{
        right: -117px;
      }
      img{

      }
    }
    #banner_box_10{

      img{

      }
    }
    h5{
      line-height: 45.93px;
      padding-left: 69px;
      &:before{
        width: 54px;
      }
    }
    a{
      margin-right: 34px;
    }
  }
}
@media(max-width: $tablet){
  .same{
    font-size: 32px;
  }
  #n-collection{

    #st_banner_block_6{

    }
    #banner_box_9{
      .st_image_layered_description_inner{
        right: -135px;
      }
      img{

      }
    }
    #banner_box_10{

      img{

      }
    }
    h5{

      &:before{

      }
    }
    a{

    }
  }
}
@media(max-width: $presta-tablet){
  .same{
    font-size: 26px;
  }
  #n-collection{
    margin-bottom: 10px;
    margin-top: 12px;
    #st_banner_block_6{

    }
    #banner_box_9{
      flex: 0 0 68%;
      max-width: 68%;
      .st_image_layered_description_inner{
        right: -135px;
      }
      img{

      }
    }
    #banner_box_10{
      flex: 0 0 32%;
      max-width: 32%;
      img{
        margin-top: 27px;
      }
    }
    h5{
      line-height: 35.93px;
      &:before{

      }
    }
    a{
      margin-right: 0;
    }
  }
}
@media(max-width: $mobile){
  .same{

  }
  #n-collection{
    .st_banner_8{
      margin: 0;
    }
    .wpb_column{
      padding: 0 5px;
    }
    #st_banner_block_6{
      margin-bottom: 5px;
    }
    #banner_box_9{
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 71px;
      .st_image_layered_description_inner{
        right: -72px;
      }
      img{

      }
    }
    #banner_box_10{
      display: none;
      img{

      }
    }
    h6{
      margin-bottom: 10px;
    }
    h5{

      &:before{

      }
    }
    a{

    }
  }
}
@media(max-width: 480px){
  .same{
    font-size: 18px;
  }
  #n-collection{
    .st_banner_8{

    }
    .wpb_column{

    }
    #st_banner_block_6{

    }
    #banner_box_9{

      .st_image_layered_description_inner{

      }
      img{

      }
    }
    #banner_box_10{

      img{

      }
    }
    h6{
      margin-bottom: 20px
    }
    h5{
      padding-left: 34px;
      line-height: 23.93px;
      &:before{
        width: 27px;
        top: 10px;
      }
    }
    a{
      font-size: 13px;
      margin-right: -11px;
    }
  }
}