#top_bar{
  padding: 11px 0;
  p{
    color: $black;
    font-size: 12px;
    letter-spacing: 0.6px;
    font-weight: 300;
    margin-bottom: 0;
    strong{
      font-weight: 700;
    }
  }
}
#nav_center{
  width: 100%;
  .left{
    float: left;
    @media(max-width: 580px){
      float: unset;
      display: block;
    }
    p{
      font-size: 13px;
      a{
        font-size: 13px;
        &:hover{
          color: $gray;
        }
      }
    }
  }
}
#nav_right{
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 999;
  transform: translateY(-50%);
}
#easycontent_14{
  text-align: center;
  width: calc(100% - 109px);
  margin-bottom: 0;
  padding-right: 109px;
  .easy_brother_block{
    text-align: center;
    position: relative;
    //left: 109px;
  }
}
#languages-block-top-mod{
  .dropdown_list{
    z-index: 999;
  }
}
@media(max-width: $presta-tablet){
  #easycontent_14{
    padding-right: 0px;
  }
}
@media(max-width: $mobile){
  #easycontent_14{
    padding-right: 26px;
    width: 100%;
    .easy_brother_block{
      left: auto;
    }
  }
  #nav_right{
    right: 0;
  }
  #languages-block-top-mod{
    .dropdown_tri{
      font-size: 0;
    }
  }
}