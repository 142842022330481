#cookiesplus-modal{
  background-color: #e5e5e5;
  .cookiesplus-info{
    text-align: center;
  }
  .cookiesplus-accept{

  }
  .cookiesplus-actions{
    float: none;
    max-width: 307px;
    margin: 0 auto;
    text-align: center;
  }
}
#cookiesplus-modal.cookiesplus-bottom.col-12 .float-xs-right{
  float: none !important;
}
#cookiesplus-modal.cookiesplus-bottom.col-12 .cookiesplus-actions div:nth-child(-n+2){
  float: right;
}



