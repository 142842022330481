[class^="module-stblog"]{
  #columns{
    position: relative;
    margin-top: 48px;
    padding-bottom: 40px;
    max-width: 1660px;
    .bottom_pagination{
      .product_count{
        display: none;
      }
    }
    .product_list.list .product_list_item{
      clear: unset;
      padding-top: 0;
      border-bottom: none;
      @media(min-width: $presta-tablet){
        width: calc(33.3% - 5px);
        margin-right: 10px;
        &:nth-child(3n){
          margin-right: 0;
          float: right;
          width: calc(33.4% - 10px);
        }
      }
    }
  }
  .pro_outer_box{
    flex-flow: column;
    min-height: 440px;
  }
  .product_list_item{
    float: left;
    .blog_image{
      text-align: center;
    }
    .pro_first_box{
      border: none;
    }
    flex: unset;
    max-width: unset;
    .block_blog{
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      .pro_second_box{
        padding: 15px;
      }
    }
    .blog_info{
      display: none;
    }
    .go{
      margin-top: 15px;
    }
    .s_title_block{
      margin-bottom: 10px;
      a{
        font-size: 18px;
        color: $black;
        @media(min-width: $mobile){
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 27px;
        }
      }
    }
    .blok_blog_short_content{
      font-size: 14px;
      line-height: 27px;
      color: #737373;
      min-height: 87px;
    }
  }
  .bottom_pagination{
    width: 100%;
    display: inline-block;
  }
}

@media(max-width: $presta-tablet){
  #sidebar_box{
    a{
      background: #272727;
      i{
        &:before{
          color: #fff;
        }
      }
      .icon_text{
        color: #fff;
      }
    }
  }
  [class^="module-stblog"]{
    #columns{

    }
    .product_list_item{

      @media(min-width: $mobile){
        width: calc(50% - 5px);
        &:nth-child(2n){
          margin-right: 0;
          float: right;
          width: calc(50% - 10px);
        }
      }
      .block_blog{

        .pro_second_box{
          padding: 15px;
        }
      }
      .blog_info{

      }

      .s_title_block{

        a{
          margin-top: 0;
          margin-bottom: 5px;
          font-size: 18px;
        }
      }
      .blok_blog_short_content{

      }
    }
    .bottom_pagination{
      width: 100%;
      display: inline-block;
    }
  }
}
@media(max-width: $mobile){
  [class^="module-stblog"]{
    #columns{
      margin-top: 0;
    }
  }
  #module-stblog-default{
    .product_list_item{
      max-width: 404px;
      margin: auto;
      float: unset;
      .s_title_block{
        height: auto;
        a{
          font-size: 15px;
        }
      }
    }
  }
}