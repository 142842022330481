#product{
  .st_product_comment_wrap{
    margin-top: 30px;
    padding: 25px;
    box-shadow: 0 0 25px 7px #00000026;
    #st_product_comment_submit{
      width: 100%;
      max-width: 220px;
      border-color: $black;
      background-color: $black;
    }
  }
}
@media(max-width: $mobile){
  #product{
    .st_product_comment_wrap{
      padding: 15px;
      h3{
        font-size: 22px;
        margin-bottom: 18px;

      }
      #st_product_comment_submit{

      }
    }
  }
}