.cms-id-12{
  #center_column{
    @media(min-width: $presta-tablet){
      //min-height: 100vh;
    }
  }
  .vc_col-sm-12.wpb_column{
    position: unset;
  }
}
#cms{
  #wrapper{
    .wpb_toggle{
      color: #fff;
      padding: 12px 20px;
      background: $black;
      margin-bottom: 0;
      border-bottom: 3px solid #f4f4f4;
      position: relative;
      font-weight: 400;
      font-size: 16px;
      padding-right: 43px;
      @media(min-width: $presta-tablet){
        //width: 100%;
        //max-width: 300px;
      }
      &:before{
        content:"\E848";
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-family: fontello;
        color: #fff;
        font-size: 12px;
        transition: 0.3s ease;
      }
      &.wpb_toggle_title_active{
        color: $black;
        background-color: #d2d2d2;
        &:before{
          content:"\E84A";
          color: $black;
        }
      }
    }
    .last_toggle_el_margin{
      display: none;
    }
    .wpb_toggle_content{
      background-color: #f4f4f4;
      margin: 0;
      padding: 20px 15px;
      //height: 100%;
      //max-height: 920px;
      overflow-y: auto;
      @media(min-width: $presta-tablet){
        //position: absolute;
        //width: calc(100% - 350px);
        //right: 0;
        //top: 0;
      }
      a{
        color: $black;
        &:hover{
          color: $black;
        }
      }
    }
  }
}