#authentication{
  #wrapper{
    #columns{
      max-width: 1406px;
      margin: auto;
    }
    #register_form_block{
      @media(min-width: 480px){
        .col-lg-12{
          flex: 0 0 50%;
          max-width: 50%;
          &:nth-child(5),
          &:nth-child(6){
            flex: 0 0 100%;
            max-width: 100%;
          }
          .form-control{
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
  @media(min-width: 769px){
    .col-lg-6{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
#my-account{
  @media(max-width: 580px){
    .list-group-item{
      width: 100%;
    }
  }
}
#create_account_block{
  .btn-primary{
    border: none;
    background-color: $gray;
    @extend .shine-hover;
  }
}
#login_form_block{
  .form-control,
  .input-group{
    max-width: 100%;
  }
  .btn-primary{
    border: none;
    background-color: $black;
    @extend .shine-hover;
  }
}
@media(max-width: $mobile){
  body#authentication #wrapper{
    padding-top: 0;
  }
  #create_account_block{
    .form_content{
      min-height: auto;
      margin-bottom: 20px;
    }
  }
}
.page-customer-account{
  button{
    background-color: $black;
    border: none;
    @extend .shine-hover;
  }
}