#submitWishlist{
  background-color: #000;
  border-color: #000;
}
.myacount_dashbord_list{
  a{
    color: #000;
    &:hover{
      color: $gray;
    }
  }
  .col-lg-4{
    padding: 12px 6px;
    display: inline-block;
    width: 260px;
    text-align: center;
    font-size: .875rem;
    margin: 0 8px 16px;
    background: transparent;
    border-color: #d9d9d9;
    flex: unset;
    max-width: unset;
    border: 1px solid rgba(0,0,0,.125);
    span{
      color: #000;
    }
    i{
      display: none;
    }
  }
  #lrp-account-link{

  }
}
.my_account_left_column{
  a{
    color: #000;
  }
  .col-lg-4{
    padding: 12px 6px;
    display: inline-block;
    background: transparent;
    border-color: #d9d9d9;
    flex: unset;
    max-width: unset;
    border: 1px solid rgba(0,0,0,.125);
    width: 100%;
    margin: 0;
    text-align: left;
    padding-left: 25px;
    font-size: 12px;
    span{
      color: #000;
      i{
        display: none;
      }
    }
  }
}
.fl,
.fr{
  padding: 13px;
  color: #fff;
  background-color: $black;
  &:hover{
    color: #fff;
  }
  @extend .shine-hover;
}
.fr{
  background-color: $gray;
  float: left;
  margin-left: 15px;
}
#lrp-customer-account{
  font-size: 14px;
  color: #000;
  th{
    color: #000;
  }
  .points-card{
    min-width: 141px;
    height: 75px;
    margin-bottom: 13px;
    text-align: center;
    span{
      line-height: 34px;
    }
    strong{
      color: $black;
      font-size: 32px;
    }
  }
}