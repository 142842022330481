.product_third_section{
  margin-top: 77px;
  padding-bottom: 80px;
  .title_block{
    max-width: 1512px;
    margin: auto;
  }
  .block_content{
    margin-top: 37px;
  }
  .title_block_inner{
    color: $black;
    font-weight: 700;
    position: relative;
    font-size: 30px;
    max-width: 180px;
    padding-left: 51px;
    line-height: 32px;
    &:before{
      content: "";
      position: absolute;
      display: inline-block;
      width: 37px;
      height: 4px;
      left: 0;
      top: 20px;
      background-color: #000000;
    }
  }
}
@media(max-width: $mobile){
  .product_third_section{
    margin-top: 22px;
    padding-bottom: 0px;
    .title_block{
      font-size: 23px;
    }
    .block_content{
      margin-top: 19px;
    }
    .title_block_inner{
      font-size: 23px;
      &:before{

      }
    }
  }
}