@charset "UTF-8";
.kk_open .header-container {
  display: none; }

#side_wishlist_block button {
  background-color: #000;
  border: none; }

#wishlist_go_login {
  background-color: #f1f1f1;
  padding: 38px 15px 18px 15px; }
  #wishlist_go_login p {
    color: #000;
    line-height: 20px; }
  #wishlist_go_login .mfp-close {
    background-color: #000;
    color: #fff;
    right: 0;
    z-index: 999; }
  #wishlist_go_login .go {
    text-decoration: none;
    padding: 9px 45px;
    margin-top: 6px;
    display: inline-block;
    color: #fff;
    background-color: #000; }

#easycontent_20 {
  margin-bottom: 0;
  max-width: 182px;
  position: fixed;
  bottom: 0;
  left: 3px;
  z-index: 999;
  box-shadow: 0 0 9px 4px #0000002e; }
  #easycontent_20 p {
    margin-bottom: 0; }

#index #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a {
  max-width: 28px;
  min-width: 28px; }

.mobile_device .act_box_cart.add_show_on_mobile {
  display: none; }

.act_box_cart.add_show_on_mobile {
  display: none; }

body {
  font-size: 16px;
  font-family: 'gotham';
  font-size: 16px; }
  body a {
    cursor: pointer; }
  body .st_sticker_block .st_sticker_type_1 span {
    padding: 0; }
  body .st_sticker_type_1 span {
    letter-spacing: 0.68px;
    border-bottom: 1px solid #000;
    font-weight: 700; }
  body #page_banner_container_1 {
    background-color: #f3f3f3;
    margin-top: 0;
    padding: 5px 0px; }
    body #page_banner_container_1 .navigation-pipe {
      color: #000;
      margin: 0; }
    body #page_banner_container_1 span {
      font-size: 14px;
      font-weight: 400;
      color: #000; }
  body:not(#index) #st_header.stuck {
    display: none; }
  body:not(#index) #header_right_bottom {
    display: none; }
  body .st_sticker_type_3 {
    transform: rotate(5deg); }
  body .ajax_block_product .hover_fly {
    bottom: -51px; }
    @media (max-width: 992px) {
      body .ajax_block_product .hover_fly {
        bottom: 14px; } }
  body .ajax_block_product:hover .hover_fly {
    bottom: 14px; }
  body #page_wrapper .js-product-miniature .pro_outer_box {
    max-width: 340px; }
    body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly {
      background: unset;
      display: flex; }
      body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a {
        background: unset;
        max-width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        font-size: 0;
        background-color: #f9f9f9;
        position: relative; }
        body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a i {
          height: 40px;
          line-height: 50px;
          margin-right: 0; }
        body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a.view_button {
          margin-left: 10px; }
        body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a.quick-view i, body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a.view_button i {
          line-height: 43px; }
          body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a.quick-view i:before, body #page_wrapper .js-product-miniature .pro_outer_box .hover_fly a.view_button i:before {
            color: #000;
            font-size: 18px; }
    body #page_wrapper .js-product-miniature .pro_outer_box .ajax_add_to_cart_button {
      left: 14px; }
      body #page_wrapper .js-product-miniature .pro_outer_box .ajax_add_to_cart_button i:before {
        content: "";
        background-image: url(../img/sprite.png);
        background-position: -126px 0px;
        width: 17px;
        height: 18px; }
    body #page_wrapper .js-product-miniature .pro_outer_box .add_to_wishlit {
      right: 14px; }
      body #page_wrapper .js-product-miniature .pro_outer_box .add_to_wishlit i:before {
        content: "";
        background-image: url(../img/sprite.png);
        background-position: -56px -126px;
        width: 17px;
        height: 15px; }
      body #page_wrapper .js-product-miniature .pro_outer_box .add_to_wishlit.st_added {
        background-color: #d83232; }
        body #page_wrapper .js-product-miniature .pro_outer_box .add_to_wishlit.st_added i:before {
          filter: invert(1); }
    body #page_wrapper .js-product-miniature .pro_outer_box:hover .pro_second_box {
      background: unset; }
  body #page_wrapper .js-product-miniature .pro_list_manufacturer {
    color: #999;
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
    margin: 9px 0 5px 0; }
  body #page_wrapper .js-product-miniature .s_title_block {
    text-align: left;
    margin-bottom: 10px; }
    body #page_wrapper .js-product-miniature .s_title_block a {
      font-size: 14px;
      color: #000;
      font-weight: 300; }
  body #page_wrapper .js-product-miniature .product-price-and-shipping {
    text-align: left; }
  body #page_wrapper .js-product-miniature .regular-price {
    font-size: 14px;
    color: #a3a2a2;
    font-weight: 200; }
  body #page_wrapper .js-product-miniature .price {
    color: #000;
    font-size: 14px;
    font-weight: 700; }

h3, h4 {
  color: #000; }

.ctitle {
  color: #000;
  font-weight: 700;
  position: relative; }
  .ctitle.line:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 37px;
    height: 4px;
    background-color: #000000; }
  .ctitle a {
    font-weight: 700;
    color: #000;
    font-size: 30px; }
  .ctitle.l-line {
    padding-left: 51px;
    max-width: 224px;
    text-align: left; }
    .ctitle.l-line:before {
      left: 0;
      top: 14px; }

.common {
  max-width: 1490px;
  padding: 0 10px;
  margin: auto; }

.shop {
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  display: block;
  max-width: 222px;
  padding: 16px 10px; }
  .shop:hover {
    color: #fff; }

.shine-hover, .shop, .product-add-to-cart .add button, .fl,
.fr, #blockcart-modal .cart-content-btn .col-md-6 button,
#blockcart-modal .cart-content-btn .col-md-6 a, #header_right .small_cart_btn, #manufacturer #columns .brand .go, #cart #center_column .cart-grid-right .cart-detailed-actions .btn-default, #contact .btn-primary, #create_account_block .btn-primary, #login_form_block .btn-primary, .page-customer-account button {
  position: relative;
  overflow: hidden;
  vertical-align: bottom; }
  .shine-hover:after, .shop:after, .product-add-to-cart .add button:after, .fl:after,
  .fr:after, #blockcart-modal .cart-content-btn .col-md-6 button:after,
  #blockcart-modal .cart-content-btn .col-md-6 a:after, #header_right .small_cart_btn:after, #manufacturer #columns .brand .go:after, #cart #center_column .cart-grid-right .cart-detailed-actions .btn-default:after, #contact .btn-primary:after, #create_account_block .btn-primary:after, #login_form_block .btn-primary:after, .page-customer-account button:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -200%;
    width: 200%;
    opacity: 0;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.23) 68%, rgba(255, 255, 255, 0.5) 85%, rgba(255, 255, 255, 0.13) 90%, rgba(255, 255, 255, 0) calc(90% + 1px));
    transition-property: left, opacity;
    transition-duration: 0s, 0.15s;
    transition-delay: 0.15s, 0s;
    transition-timing-function: ease-in-out; }
  .shine-hover:hover:after, .shop:hover:after, .product-add-to-cart .add button:hover:after, .fl:hover:after,
  .fr:hover:after, #blockcart-modal .cart-content-btn .col-md-6 button:hover:after,
  #blockcart-modal .cart-content-btn .col-md-6 a:hover:after, #header_right .small_cart_btn:hover:after, #manufacturer #columns .brand .go:hover:after, #cart #center_column .cart-grid-right .cart-detailed-actions .btn-default:hover:after, #contact .btn-primary:hover:after, #create_account_block .btn-primary:hover:after, #login_form_block .btn-primary:hover:after, .page-customer-account button:hover:after {
    left: 0;
    opacity: 1;
    transition-duration: 0.4s, 0.15s;
    transition-delay: 0s; }

#body_wrapper .swiper-button {
  width: 52px;
  height: 52px;
  border: 2px solid #000000;
  background: transparent;
  border-radius: 50%; }
  #body_wrapper .swiper-button i {
    margin: 0;
    height: 52px;
    width: 48px; }
    #body_wrapper .swiper-button i:before {
      color: #000;
      font-size: 17px; }
  #body_wrapper .swiper-button:hover {
    background: #000; }
    #body_wrapper .swiper-button:hover i:before {
      color: #fff; }

@media (max-width: 1440px) {
  .shop {
    max-width: 174px;
    padding: 12px 5px;
    font-size: 15px; } }

@media (max-width: 1200px) {
  .ctitle a {
    font-size: 26px; }
  .ctitle.l-line:before {
    left: 0; } }

@media (max-width: 992px) {
  .shop {
    max-width: 150px;
    padding: 10px 5px;
    font-size: 13px; } }

@media (max-width: 768px) {
  .shop {
    max-width: 130px;
    padding: 8px 5px;
    font-size: 12px; }
  .ctitle {
    font-size: 22px; }
    .ctitle.line:before {
      top: 11px; }
    .ctitle a {
      font-size: 23px; }
  body #page_wrapper .js-product-miniature .pro_list_manufacturer {
    margin: 0; }
  body #page_wrapper .js-product-miniature .s_title_block {
    margin-bottom: 4px; }
  body #page_wrapper .js-product-miniature .regular-price {
    margin-left: 0; } }

.stmenu_sub {
  min-width: 1300px; }
  @media (max-width: 1300px) {
    .stmenu_sub {
      min-width: 1100px; } }
  @media (max-width: 1100px) {
    .stmenu_sub {
      min-width: 992px; } }

#st_menu_8 .stmenu_sub {
  top: 82px;
  background-color: #1d1c1c; }
  #st_menu_8 .stmenu_sub #st_menu_column_6 {
    padding: 0;
    max-width: 1264px;
    margin: auto; }
  #st_menu_8 .stmenu_sub #st_menu_block_11 {
    width: 100%; }
    #st_menu_8 .stmenu_sub #st_menu_block_11 img {
      background-color: #fff; }
    #st_menu_8 .stmenu_sub #st_menu_block_11 .col-md-2 {
      max-width: calc(16.66667% - 4px);
      margin-bottom: 19px;
      padding: 0 8px;
      text-align: center;
      display: inline-block; }

#st_mega_menu_header_container #st_mega_menu_wrap .stmenu_sub {
  left: 50%;
  transform: translateX(-50%); }

.stmenu_sub {
  top: 80px;
  min-width: 1161px; }
  .stmenu_sub .ml_level_1 {
    margin-bottom: 25px; }
  .stmenu_sub .ma_level_1 {
    font-size: 14px;
    color: #000;
    border-bottom: 1px solid #000;
    padding-bottom: 6px; }
  .stmenu_sub .ma_level_2 {
    font-size: 13px;
    color: #000;
    margin-bottom: 5px; }
    .stmenu_sub .ma_level_2:hover {
      color: #808080; }
    .stmenu_sub .ma_level_2 i {
      display: none; }

@media (max-width: 1300px) {
  .stmenu_sub {
    min-width: 990px; }
    .stmenu_sub .ml_level_1 {
      margin-bottom: 15px; }
    .stmenu_sub .ma_level_1 {
      font-size: 12px; }
    .stmenu_sub .ma_level_2 {
      font-size: 11px; } }

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Thin.otf);
  font-weight: 100; }

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Light.otf);
  font-weight: 200; }

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Book.otf);
  font-weight: 300; }

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Medium.otf);
  font-weight: 400; }

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-MediumItalic.otf);
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Bold.otf);
  font-weight: 700; }

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Ultra.otf);
  font-weight: 900; }

#footer #footer-top {
  margin-left: auto;
  margin-right: auto;
  padding-top: 42px;
  padding-bottom: 32px; }
  #footer #footer-top .title_block_inner {
    font-size: 16px;
    font-weight: 700;
    color: #000; }
    @media (min-width: 768px) {
      #footer #footer-top .title_block_inner {
        margin-bottom: 20px;
        margin-top: 46px; } }
  #footer #footer-top .footer_block_content li {
    margin-bottom: 6px; }
  #footer #footer-top .footer_block_content a {
    color: #7b7b7b;
    font-size: 14px;
    font-weight: 300; }
    #footer #footer-top .footer_block_content a:hover {
      color: #000; }

#footer .ctitle {
  font-size: 36px;
  font-weight: 700; }

#footer .st_news_letter p {
  font-size: 14px;
  color: #808080;
  font-weight: 300;
  max-width: 500px; }

#footer form {
  flex-flow: column-reverse;
  display: flex;
  margin-top: 50px; }
  #footer form .st_news_letter_form_inner {
    margin-bottom: 15px; }
  #footer form .input-group-btn {
    width: 49px;
    height: 49px;
    margin-top: 9px; }
  #footer form button {
    position: relative;
    font-size: 0;
    background-color: #000;
    border-radius: 50%;
    width: 49px;
    height: 49px; }
    #footer form button:before {
      content: "";
      position: absolute;
      background-image: url(../img/sprite.png);
      background-position: -63px -65px;
      width: 23px;
      height: 13px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    #footer form button i {
      display: none; }
  #footer form input {
    background-color: unset;
    border-bottom: 2px solid #000;
    margin-right: 7px;
    padding-bottom: 12px; }
  #footer form input::placeholder {
    color: #000;
    font-weight: 400;
    font-size: 14px; }
  #footer form .input-group {
    background: unset;
    border: none; }
  #footer form .psgdpr_consent_message {
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px;
    margin-left: 7px;
    text-align: left;
    color: #000; }
    #footer form .psgdpr_consent_message a {
      color: #000;
      border-bottom: 1px solid #808080; }
      #footer form .psgdpr_consent_message a:hover {
        color: #7b7b7b; }
  #footer form .custom-input-item {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-color: #000; }
    #footer form .custom-input-item i {
      line-height: 22px; }
      #footer form .custom-input-item i:before {
        color: #000; }

#footer #multilink_8 a {
  padding-left: 37px;
  position: relative; }
  #footer #multilink_8 a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-39%);
    transition: 0.3s ease; }
  #footer #multilink_8 a:hover:before {
    left: 5px; }
  #footer #multilink_8 a.fb:before {
    background-image: url(../img/sprite.png);
    background-position: -126px -58px;
    width: 16px;
    height: 16px; }
  #footer #multilink_8 a.insta:before {
    background-image: url(../img/sprite.png);
    background-position: 0px -126px;
    width: 16px;
    height: 16px; }
  #footer #multilink_8 a.twtr:before {
    background-image: url(../img/sprite.png);
    background-position: -113px -126px;
    width: 15px;
    height: 14px; }

#footer #st_news_letter_2 {
  max-width: 537px; }

@media (min-width: 768px) {
  #footer .col1 {
    width: 46%; }
  #footer .col2 {
    width: 13.5%; }
  #footer .col3 {
    width: 15.2%; }
  #footer .col4 {
    width: 16.3%; }
  #footer .col5 {
    width: 9%;
    padding-right: 0; } }

#footer #copywrite {
  margin-left: auto;
  margin-right: auto; }
  #footer #copywrite .right {
    padding-right: 0; }
  #footer #copywrite .left {
    padding-left: 0; }
    #footer #copywrite .left p {
      font-size: 14px;
      font-weight: 300;
      color: #000; }
      #footer #copywrite .left p a {
        color: #000;
        font-weight: 700; }
        #footer #copywrite .left p a:hover {
          color: #ef5611; }
      #footer #copywrite .left p strong {
        font-weight: 700; }

@media (max-width: 1440px) and (min-width: 768px) {
  #footer .col1 {
    width: 44%;
    padding-right: 55px; }
  #footer .col4 {
    width: 15.3%; }
  #footer .col5 {
    width: 12%;
    padding-right: 15px; } }

@media (max-width: 1200px) and (min-width: 768px) {
  #footer .col1 {
    width: 36%;
    padding-right: 26px; }
  #footer .col2 {
    width: 13.5%; }
  #footer .col3 {
    width: 18.2%; }
  #footer .col4 {
    width: 18.3%; }
  #footer .col5 {
    width: 14%;
    padding-right: 15px; } }

@media (max-width: 992px) {
  #footer #footer-top {
    padding: 10px 0; } }

@media (max-width: 992px) and (min-width: 768px) {
  #footer .col {
    min-height: 312px; }
  #footer .col1 {
    width: 50%; }
  #footer .col2 {
    width: 25%; }
  #footer .col3 {
    width: 25%; }
  #footer .col4 {
    width: 25%;
    min-height: unset; }
  #footer .col5 {
    width: 75%;
    min-height: unset; } }

@media (max-width: 992px) {
  #footer #copywrite .right {
    width: 45%; }
  #footer #copywrite .left {
    width: 55%; }
    #footer #copywrite .left p {
      font-size: 12px;
      margin-top: 22px; } }

@media (max-width: 768px) {
  #footer #footer-top .opener {
    z-index: 999;
    display: flex; }
    #footer #footer-top .opener i:before {
      color: #000; }
  #footer #footer-top .footer_block {
    margin: 10px 0; }
  #footer #footer-top .title_block {
    padding: 0 15px;
    margin-bottom: 0; }
  #footer #footer-top .title_block_inner {
    font-size: 14px; }
  #footer #footer-top .st_open .footer_block_content {
    display: block; }
  #footer #footer-top .footer_block_content {
    display: none;
    padding: 0 15px;
    margin-top: 15px; }
  #footer #footer-top .col {
    padding: 0;
    border-top: 1px solid #ccc; }
  #footer #footer-top .col1 {
    margin-bottom: 30px;
    padding: 0 15px;
    border: none; }
    #footer #footer-top .col1 .footer_block_content {
      display: block;
      padding: 0; }
  #footer #footer-top .col5 {
    border-bottom: 1px solid #ccc; }
  #footer .ctitle {
    font-size: 25px; }
  #footer .st_news_letter_content p {
    font-size: 13px; }
  #footer form {
    margin-top: 23px; }
    #footer form .st_news_letter_form_inner {
      margin-bottom: 0; }
    #footer form input::placeholder {
      font-size: 12px; }
  #footer #copywrite .right {
    width: 100%;
    padding-left: 0; }
  #footer #copywrite .left {
    width: 100%;
    padding-right: 0; }
    #footer #copywrite .left .wpb_content_element {
      margin-top: 0; }
    #footer #copywrite .left p {
      text-align: center;
      margin-top: 5px; } }

#header_left .shop_logo {
  width: 100%;
  max-width: 216px; }
  #header_left .shop_logo img {
    width: 100%; }

#header_primary {
  position: relative; }

#product #st_header {
  z-index: 999; }

#st_header #header_primary_container {
  height: auto;
  padding: 0;
  position: unset; }
  #st_header #header_primary_container #header_primary_row {
    max-width: 1470px;
    margin: auto; }
  #st_header #header_primary_container .search_widget_block {
    width: 100%;
    max-width: 396px;
    float: right; }

#st_header .search_widget_form_inner {
  border: none; }
  #st_header .search_widget_form_inner .icon_text {
    color: #000; }
  #st_header .search_widget_form_inner.input-group.input-group-with-border {
    border-bottom: 2px solid #000; }
  #st_header .search_widget_form_inner input {
    background: unset; }
  #st_header .search_widget_form_inner button {
    background: #fff;
    border: none; }
    #st_header .search_widget_form_inner button:hover {
      background: #fff; }
    #st_header .search_widget_form_inner button i:before {
      position: relative;
      top: 3px;
      color: #000; }

#st_header .blockcart {
  order: 3;
  margin-bottom: 5px; }
  #st_header .blockcart .ajax_cart_quantity {
    background: unset;
    font-size: 12px;
    color: #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%); }
  #st_header .blockcart .ajax_cart_bag {
    background: unset;
    border: none; }
    #st_header .blockcart .ajax_cart_bag i:before {
      content: "";
      background-image: url(../img/sprite.png);
      background-position: 0px 0px;
      width: 24px;
      height: 25px; }

#st_header .login,
#st_header .userinfo_mod_top {
  margin-right: 15px; }
  #st_header .login .fto-user:before,
  #st_header .userinfo_mod_top .fto-user:before {
    content: "";
    background-image: url(../img/sprite.png);
    background-position: -64px 0px;
    width: 22px;
    height: 22px; }

#st_header .userinfo_mod_top {
  margin-right: 0px; }
  #st_header .userinfo_mod_top .dropdown_list_ul li span, #st_header .userinfo_mod_top .dropdown_list_ul li a {
    color: #000; }

#st_header .wishlist_link .wishlist_customer_count {
  display: none; }

#st_header .wishlist_link i:before {
  content: "";
  background-image: url(../img/sprite.png);
  background-position: 0px -65px;
  width: 23px;
  height: 21px; }

#st_header.stuck #header_primary_row {
  display: none; }

@media (min-width: 992px) {
  #st_header.stuck #header_right_bottom {
    margin-top: 15px; } }

#header_center {
  margin-left: 65px; }
  #header_center .ml_level_0 {
    padding: 25px 7px;
    transition: 0.3s ease; }
    #header_center .ml_level_0 .ma_level_0:before {
      content: "";
      position: absolute;
      display: inline-block;
      width: 42px;
      height: 2px;
      background-color: #ffffff;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
      opacity: 0; }
    #header_center .ml_level_0.current, #header_center .ml_level_0:hover {
      background-color: #000; }
      #header_center .ml_level_0.current .ma_level_0, #header_center .ml_level_0:hover .ma_level_0 {
        color: #fff; }
        #header_center .ml_level_0.current .ma_level_0:before, #header_center .ml_level_0:hover .ma_level_0:before {
          opacity: 1; }
  #header_center .ma_level_0 {
    font-size: 18px;
    font-weight: 700;
    color: #000; }
    #header_center .ma_level_0:after {
      display: none; }

#header_right {
  margin-top: 17px; }
  @media (min-width: 1460px) {
    #header_right {
      position: relative;
      left: 30px; } }

.st_mega_menu_container {
  margin-right: auto; }

.search_widget_simple {
  margin-right: auto;
  margin-left: 25px;
  width: 45.5%;
  margin-bottom: 18px; }
  @media (min-width: 992px) {
    .search_widget_simple .dropdown_tri {
      display: none; }
    .search_widget_simple .dropdown_list {
      display: block;
      position: relative; }
      .search_widget_simple .dropdown_list:before {
        display: none; } }

@media (max-width: 1300px) {
  #header_center {
    margin-left: 15px; }
    #header_center .ml_level_0 .ma_level_0 {
      padding: 0 4px;
      font-size: 16px; }
  .st_mega_menu_container {
    margin-right: 0px; }
  .search_widget_simple {
    margin: 0 auto 18px auto;
    width: 37.5%; } }

@media (max-width: 992px) {
  body#index .header-container {
    margin-bottom: 0; }
  #mobile_bar {
    padding: 12px 0 0 0; }
    #mobile_bar .blockcart {
      margin-bottom: 0; }
  .mobile_logo .logo {
    width: 100%; }
  #page_wrapper #top_bar {
    padding: 6px 0;
    display: block; }
  #easycontent_19 {
    margin-bottom: 0;
    background-color: #000;
    padding: 5px;
    font-size: 13px;
    margin-top: 12px; }
    #easycontent_19 p {
      color: #fff;
      margin-bottom: 0;
      text-align: center; }
    #easycontent_19 a {
      color: #fff; }
  .blockcart .dropdown_list {
    display: none !important; }
  .search_widget_simple {
    margin: 0;
    box-shadow: 0 0 5px 2px #0000002e;
    min-width: 32px;
    height: 32px; }
    .search_widget_simple .dropdown_list {
      min-width: 289px;
      left: -31px; }
    .search_widget_simple i {
      width: 32px;
      height: 32px;
      line-height: 30px;
      display: inline-block;
      text-align: center; }
      .search_widget_simple i:before {
        color: #000; }
  #rightbar_4 {
    background-color: #000;
    margin-right: 15px;
    min-width: 32px;
    height: 32px;
    line-height: 27px; }
    #rightbar_4 i:before {
      color: #fff;
      line-height: 34px; } }

@media (max-width: 768px) {
  #mobile_bar_center {
    padding: 0 12px 0 15px; }
  #mobile_bar .container {
    padding-right: 5px; }
  #page_wrapper #top_bar P {
    text-align: center;
    font-size: 10px; }
  #page_wrapper #top_bar #top_bar_row {
    padding-left: 0;
    padding-right: 0; }
  #easycontent_19 p {
    font-size: 12px; }
  #st_header .login,
  #st_header .userinfo_mod_top {
    margin-right: 5px; } }

#side_stmobilemenu a {
  color: #000; }

#side_stmobilemenu i:before {
  color: #000; }

#side_stmobilemenu .st-menu-title {
  background: #000;
  color: #fff; }

#side_stmobilemenu .style_content {
  background-color: #000;
  color: #fff;
  padding: 10px 12px;
  font-size: 14px; }
  #side_stmobilemenu .style_content h4 {
    font-size: 14px;
    margin-bottom: 0; }

#side_stmobilemenu .mo_ml_level_0 {
  background-color: #f0f0f0;
  border-bottom: 1px solid #d5d5d5;
  padding-left: 0; }
  #side_stmobilemenu .mo_ml_level_0 .mo_ma_level_0 {
    padding-left: 15px; }
  #side_stmobilemenu .mo_ml_level_0 .mo_ml_level_1 {
    background-color: #fafafa;
    padding-left: 0; }
    #side_stmobilemenu .mo_ml_level_0 .mo_ml_level_1 .mo_ma_level_1 {
      padding: 6px 0;
      padding-left: 20px;
      font-size: 13px; }
    #side_stmobilemenu .mo_ml_level_0 .mo_ml_level_1 .mo_sub_li {
      padding-left: 30px;
      background-color: #fff; }

.st-menu .st-menu-title {
  background: #000; }

.st-menu .mo_ma_level_0 {
  font-weight: 700;
  font-size: 13px; }

#st_news_letter_3 {
  background-color: #f2f2f2; }
  #st_news_letter_3 .st_news_letter_do_not_show_outer {
    padding: 0; }
    #st_news_letter_3 .st_news_letter_do_not_show_outer .st_news_letter_do_not_show_inner {
      background-color: #000;
      padding: 5px 10px;
      font-size: 13px;
      color: #fff;
      margin-top: 15px; }
    #st_news_letter_3 .st_news_letter_do_not_show_outer input {
      position: relative;
      top: 1px; }

#st_news_letter_popup_3 .close {
  background-color: #000; }
  #st_news_letter_popup_3 .close span {
    color: #fff; }

#st_news_letter_popup_3 h3 {
  font-size: 26px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px; }
  #st_news_letter_popup_3 h3:before {
    content: "";
    position: absolute;
    display: inline-block;
    height: 4px;
    width: 25%;
    background-color: #000;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); }

#st_news_letter_popup_3 .modal-dialog {
  max-width: 650px;
  width: 100%;
  top: 50%; }
  @media (min-width: 660px) {
    #st_news_letter_popup_3 .modal-dialog {
      min-width: 650px; } }
  @media (min-width: 540px) {
    #st_news_letter_popup_3 .modal-dialog {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -95%); } }

#st_news_letter_popup_3 .st_news_letter_content {
  padding: 25px 30px 15px 30px;
  color: #000; }

#st_news_letter_popup_3 .wrap {
  background-color: #fff;
  padding: 20px;
  max-width: 408px;
  margin: auto;
  margin-top: 23px; }

#st_news_letter_popup_3 p {
  font-size: 14px; }

#st_news_letter_popup_3 ul {
  text-align: left;
  max-width: 408px;
  margin: auto; }
  #st_news_letter_popup_3 ul li {
    padding-left: 20px;
    position: relative;
    font-size: 14px; }
    #st_news_letter_popup_3 ul li:before {
      content: "\E80F";
      font-family: fontello;
      position: absolute;
      left: 0;
      top: 0;
      color: #000;
      font-size: 14px; }

@media (max-width: 540px) {
  #st_news_letter_3 .st_news_letter_do_not_show_outer .st_news_letter_do_not_show_inner {
    margin-top: 6px;
    padding: 2px 7px 6px 7px;
    font-size: 11px; }
  #st_news_letter_popup_3 .close {
    right: 0;
    z-index: 999; }
  #st_news_letter_popup_3 h3 {
    font-size: 19px;
    margin-bottom: 23px; }
    #st_news_letter_popup_3 h3:before {
      height: 3px; }
  #st_news_letter_popup_3 .modal-content {
    margin-left: 0;
    margin-right: 0; }
  #st_news_letter_popup_3 .modal-dialog {
    margin: 10px;
    width: calc(100% - 20px);
    transform: translateY(-50%); }
  #st_news_letter_popup_3 .st_news_letter_content {
    padding: 33px 15px 15px 15px; }
  #st_news_letter_popup_3 .wrap {
    padding: 14px 10px; }
  #st_news_letter_popup_3 p {
    font-size: 12px; }
  #st_news_letter_popup_3 ul li {
    padding-left: 16px;
    font-size: 12px; } }

#page_wrapper .autocomplete-suggestion .search_product_price {
  font-size: 13px; }

.autocomplete-suggestion .search_product_name {
  font-size: 13px;
  color: #000; }

.autocomplete-suggestion a:hover .search_product_name {
  color: #000; }

.autocomplete-suggestions .search_more_products {
  background-color: #000;
  color: #fff;
  text-decoration: none; }

#top_bar {
  padding: 11px 0; }
  #top_bar p {
    color: #000;
    font-size: 12px;
    letter-spacing: 0.6px;
    font-weight: 300;
    margin-bottom: 0; }
    #top_bar p strong {
      font-weight: 700; }

#nav_center {
  width: 100%; }
  #nav_center .left {
    float: left; }
    @media (max-width: 580px) {
      #nav_center .left {
        float: unset;
        display: block; } }
    #nav_center .left p {
      font-size: 13px; }
      #nav_center .left p a {
        font-size: 13px; }
        #nav_center .left p a:hover {
          color: #808080; }

#nav_right {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 999;
  transform: translateY(-50%); }

#easycontent_14 {
  text-align: center;
  width: calc(100% - 109px);
  margin-bottom: 0;
  padding-right: 109px; }
  #easycontent_14 .easy_brother_block {
    text-align: center;
    position: relative; }

#languages-block-top-mod .dropdown_list {
  z-index: 999; }

@media (max-width: 992px) {
  #easycontent_14 {
    padding-right: 0px; } }

@media (max-width: 768px) {
  #easycontent_14 {
    padding-right: 26px;
    width: 100%; }
    #easycontent_14 .easy_brother_block {
      left: auto; }
  #nav_right {
    right: 0; }
  #languages-block-top-mod .dropdown_tri {
    font-size: 0; } }

#header_right_bottom {
  border-top: 1px solid #ccc;
  padding-top: 8px;
  margin-bottom: 15px; }
  #header_right_bottom p {
    text-align: center;
    margin-bottom: 0; }
  #header_right_bottom .pipe {
    margin: 0 15px; }
  #header_right_bottom span {
    color: #000;
    font-weight: 400;
    font-size: 13px; }
    #header_right_bottom span a {
      color: #000;
      position: relative; }
      #header_right_bottom span a:before {
        content: "";
        position: absolute;
        display: inline-block;
        height: 2px;
        left: 0px;
        bottom: -5px;
        background-color: #000;
        transition: 0.3s ease;
        right: 100%; }
      #header_right_bottom span a:hover:before {
        right: 0px; }
    #header_right_bottom span strong {
      font-weight: 700; }

#lrp-product-widget,
#cart-points-summary {
  border: none;
  padding: 0;
  max-width: 500px;
  font-size: 14px;
  color: #000;
  background-color: #f2f2f2; }
  #lrp-product-widget strong:first-of-type,
  #cart-points-summary strong:first-of-type {
    color: #fff;
    background-color: #d83232;
    border-radius: 50%;
    padding: 4px;
    font-weight: 400;
    font-size: 13px; }
  #lrp-product-widget br,
  #cart-points-summary br {
    display: none; }
  #lrp-product-widget .material-icons,
  #cart-points-summary .material-icons {
    display: none; }

#cart-points-summary {
  background-color: unset; }
  #cart-points-summary .card {
    background-color: #f2f2f2;
    max-width: 300px; }

#product .kk_close {
  background-color: #fff;
  border-radius: 50%;
  color: #000; }

#product .swiper-slide {
  cursor: zoom-in; }
  #product .swiper-slide.swiper-slide-zoomed {
    cursor: zoom-out; }

#product .sizep h3 {
  text-align: center;
  font-size: 24px; }

#product .sizep p {
  text-align: center;
  font-size: 16px;
  color: #000; }
  #product .sizep p span {
    margin-bottom: 20px;
    display: inline-block; }

#product .sizep h4 {
  font-size: 20px;
  text-align: center;
  margin: 30px 0; }

#product .sizep table {
  width: 100%;
  color: #000; }
  #product .sizep table th {
    font-size: 14px;
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center; }
  #product .sizep table td {
    border: 1px solid #ccc;
    font-size: 13px;
    padding: 5px;
    text-align: center; }

#product .inline_popup_content {
  width: 100%;
  overflow-y: auto;
  max-height: 800px; }
  #product .inline_popup_content .mfp-close {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #000;
    color: #fff;
    z-index: 999; }

#product .product_first_section .swiper-button {
  display: block !important; }
  #product .product_first_section .swiper-button i {
    line-height: 44px;
    width: 47px; }

#product #page_banner_container_1 {
  padding: 23px 0px 31px 0; }
  #product #page_banner_container_1 .container-fluid {
    max-width: 1465px; }
    #product #page_banner_container_1 .container-fluid span {
      font-weight: 300; }

#product .breadcrumb_spacing {
  display: none; }

#product .steasy_divider {
  display: none; }

#product .product_first_section {
  background-color: #f2f2f2;
  padding-bottom: 75px; }
  #product .product_first_section .product_page_container {
    max-width: 1465px;
    margin: auto; }

#product .pro_gallery_thumbs {
  position: static; }

#product .pro_gallery_thumbs_container {
  float: left;
  width: 100%;
  max-width: 100%;
  padding-right: 328px;
  position: relative; }
  #product .pro_gallery_thumbs_container .general_border {
    border: none; }
  #product .pro_gallery_thumbs_container .swiper-button {
    width: 46px;
    height: 46px;
    top: 50%; }
    #product .pro_gallery_thumbs_container .swiper-button i {
      width: 42px; }
  #product .pro_gallery_thumbs_container .swiper-button-next {
    right: 194px; }
  #product .pro_gallery_thumbs_container .swiper-button-prev {
    left: auto;
    right: 248px; }
  #product .pro_gallery_thumbs_container .swiper-button-disabled {
    border-color: #b9b9b9; }
    #product .pro_gallery_thumbs_container .swiper-button-disabled i:before {
      color: #b9b9b9; }
    #product .pro_gallery_thumbs_container .swiper-button-disabled:hover {
      background-color: unset; }
  #product .pro_gallery_thumbs_container .swiper-slide {
    width: 89px;
    margin-bottom: 8px;
    box-shadow: 0px 4px 15px -8px #0000002b; }

#product .pro_gallery_top_container {
  box-shadow: 14px 16px 76px rgba(0, 0, 0, 0.17);
  z-index: 999; }

#product .images-container {
  max-width: 720px; }

#product .pro_popup_trigger_box {
  right: 36px;
  bottom: 36px; }

#product .st_popup_image {
  width: 50px;
  height: 50px;
  box-shadow: 0 0 13px rgba(18, 16, 11, 0.15);
  background-color: #ffffff;
  border-radius: 50%; }
  #product .st_popup_image i {
    color: #000;
    font-size: 23px;
    line-height: 49px; }

#product .product_left_column {
  flex: 0 0 51.5%;
  max-width: 51.5%;
  z-index: 99;
  padding-right: 0; }

#product .product_middle_column_inner {
  padding: 24px 15px 0px 50px; }

#product .product_middle_column {
  flex: 0 0 100%;
  max-width: calc(48.5% + 156px);
  background-color: #fff;
  margin-left: -156px;
  padding-left: 148px;
  padding-right: 0;
  padding-bottom: 25px; }

#product .product-manufacturer {
  padding-left: 28px;
  background-color: #f2f2f2; }

#product .social_share_block {
  float: right;
  margin: 5px 25px 0 0; }
  #product .social_share_block span {
    font-size: 14px;
    font-weight: 300;
    color: #000;
    margin-right: 20px; }
  #product .social_share_block a {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 24px;
    margin-bottom: 0;
    margin-right: 15px; }
  #product .social_share_block .social_share_twitter i:before {
    font-size: 20px; }

#page_wrapper .product_name {
  font-size: 26px;
  font-weight: 300;
  color: #000;
  margin-bottom: 13px; }

#page_wrapper .price {
  font-size: 30px;
  font-weight: 700;
  color: #000; }

#page_wrapper .regular-price {
  margin-left: 10px;
  font-size: 20px;
  color: #a3a2a2;
  font-weight: 300; }

#page_wrapper .product-variants-item {
  margin-bottom: 32px; }

#page_wrapper .product-variants .li_fl {
  float: left; }

#page_wrapper .product-variants .radio-label {
  border: 2px solid #fff;
  padding: 3px;
  line-height: 13px;
  align-items: center;
  justify-content: center;
  display: flex; }

#page_wrapper .product-variants .input-radio:checked + span {
  border-color: #000; }

#page_wrapper .product-variants input:hover + span {
  border-color: #000;
  line-height: 13px; }

#page_wrapper .product-variants .color {
  border: 2px solid #000; }

#page_wrapper .product-variants .input-color:checked + span::before {
  border-color: #000; }

.product-reference {
  font-size: 16px;
  font-weight: 300;
  color: #000;
  margin-bottom: 19px; }
  .product-reference .pro_extra_info_label {
    color: #b8b7b7; }

.product-description-short {
  color: #808080;
  font-size: 16px;
  font-weight: 200;
  margin-top: 15px;
  margin-bottom: 9px !important; }

.tax-shipping-delivery-label br {
  display: none; }

.product-unavailable {
  color: #dc3545; }

.availability {
  color: #939393;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  padding: 0 0 23px 27px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 23px; }
  .availability:before {
    content: "\E80F";
    font-family: fontello;
    position: absolute;
    left: 0;
    top: 0;
    color: #1cd91c;
    font-size: 16px; }

.pro_right_item a {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  border-bottom: 2px solid #000;
  padding-bottom: 11px; }

.product-variants-item {
  margin-bottom: 32px; }

.product-variants {
  margin: 41px 0 45px 0; }
  .product-variants .control-label {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-right: 35px;
    min-width: 81px; }
  .product-variants .li_fl {
    float: left; }
  .product-variants .radio-label {
    border: 2px solid #fff;
    color: #000;
    padding: 3px;
    width: 48px;
    line-height: 13px;
    height: 36px;
    text-align: center;
    font-size: 14px; }
    .product-variants .radio-label:hover {
      border-color: #000; }
  .product-variants .input-radio {
    width: 48px;
    height: 36px;
    line-height: 13px; }
  .product-variants .input-radio:checked + span {
    border-color: #000; }
  .product-variants input:hover + span {
    border-color: #000;
    line-height: 13px; }
  .product-variants .color {
    border: none;
    width: 32px;
    height: 32px; }
  .product-variants .input-color:checked + span::before {
    content: "\E80F";
    position: absolute;
    font-family: fontello;
    font-size: 18px;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -58%); }
  .product-variants .input-color[title="Λευκό"]:checked + span::before {
    color: #000; }

.product-add-to-cart .product-quantity {
  width: 77.5%;
  display: inline-block; }

.product-add-to-cart .pro_cart_right {
  width: calc(22.5% - 23px);
  display: inline-block;
  margin-left: 23px; }

.product-add-to-cart .input-group {
  border: 2px solid #000; }

.product-add-to-cart .qty {
  width: 130px;
  margin-right: 19px;
  display: inline-block !important; }
  .product-add-to-cart .qty button,
  .product-add-to-cart .qty input {
    height: 65px !important;
    border: none;
    color: #000; }
  .product-add-to-cart .qty input {
    font-size: 23px;
    text-align: center;
    font-weight: 700; }
  .product-add-to-cart .qty button {
    position: relative;
    font-size: 0;
    width: 42px; }
    .product-add-to-cart .qty button:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: #000;
      font-family: fontello; }
    .product-add-to-cart .qty button:hover {
      background-color: unset; }
  .product-add-to-cart .qty .bootstrap-touchspin-down:before {
    content: "\E84A"; }
  .product-add-to-cart .qty .bootstrap-touchspin-up:before {
    content: "\E848"; }

.product-add-to-cart .add {
  width: calc(100% - 155px);
  display: inline-block !important; }
  .product-add-to-cart .add button {
    height: 68px;
    background-color: #000;
    border-color: #000; }
    .product-add-to-cart .add button.active {
      cursor: progress; }
    .product-add-to-cart .add button span {
      letter-spacing: 1.3px;
      font-size: 15px;
      font-weight: 400;
      color: #fff; }
    .product-add-to-cart .add button i {
      display: none; }

.product-add-to-cart .add_to_wishlit {
  margin: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 50px;
  transition: 0.3s ease;
  position: relative;
  height: 50px; }
  .product-add-to-cart .add_to_wishlit .hover_fly_btn_inner {
    font-size: 0; }
  .product-add-to-cart .add_to_wishlit:hover {
    border-color: #000; }
  .product-add-to-cart .add_to_wishlit i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    margin: 0; }
    .product-add-to-cart .add_to_wishlit i:before {
      content: "";
      background-image: url(../img/sprite.png);
      background-position: 0px -65px;
      width: 23px;
      height: 21px; }

@media (max-width: 1440px) {
  #product .pro_gallery_thumbs {
    max-width: 260px;
    float: left; }
  #product .pro_gallery_thumbs_container .swiper-slide {
    width: 78px; }
  #product .product_middle_column {
    padding-bottom: 35px; } }

@media (max-width: 1200px) {
  #product .pro_gallery_thumbs {
    max-width: 176px; }
  #product .product_left_column {
    padding-left: 0; }
  #page_wrapper .product-add-to-cart .pro_cart_right {
    width: calc(14.5% - 10px);
    margin-left: 10px; }
  #page_wrapper .product-add-to-cart .qty {
    width: 110px; }
  #page_wrapper .product-add-to-cart .add {
    width: calc(100% - 134px); }
    #page_wrapper .product-add-to-cart .add button {
      padding: 10px; }
      #page_wrapper .product-add-to-cart .add button span {
        font-size: 14px; }
      #page_wrapper .product-add-to-cart .add button i {
        display: none; } }

@media (max-width: 992px) {
  #product .pro_gallery_thumbs {
    max-width: 263px;
    margin: auto;
    float: unset; } }
  @media (max-width: 992px) and (max-width: 875px) {
    #product .pro_gallery_thumbs {
      max-width: 174px; } }

@media (max-width: 992px) {
  #product .pro_gallery_thumbs_container {
    max-width: calc(100% - 104px);
    padding-right: 0; }
    #product .pro_gallery_thumbs_container .swiper-button {
      top: auto;
      bottom: -51px; }
    #product .pro_gallery_thumbs_container .swiper-button-next {
      right: 31px; }
    #product .pro_gallery_thumbs_container .swiper-button-prev {
      right: 90px; }
  #product .product_left_column {
    flex: 0 0 44.5%;
    max-width: 44.5%; }
  #product .product_middle_column {
    max-width: calc(55.5% + 100px);
    margin-left: -100px;
    padding-left: 69px; }
  #page_wrapper .product_name {
    font-size: 18px;
    margin-bottom: 3px; }
  #page_wrapper .product-reference {
    margin-bottom: 7px; }
  #page_wrapper .price {
    font-size: 26px; }
  #page_wrapper .regular-price {
    font-size: 17px; }
  #page_wrapper .product-description-short {
    font-size: 14px; }
  #page_wrapper .availability {
    font-size: 14px; }
  #page_wrapper .pro_right_item a {
    font-size: 14px;
    padding-bottom: 5px; }
  #page_wrapper .product-variants {
    margin: 18px 0 0px 0; }
  #page_wrapper .product-variants-item {
    margin-bottom: 15px; }
    #page_wrapper .product-variants-item .control-label {
      font-size: 16px;
      margin-right: 22px; }
  #page_wrapper .product-add-to-cart .pro_cart_right {
    width: calc(14.5% - 10px);
    margin-left: 10px; }
  #page_wrapper .product-add-to-cart .qty {
    width: 107px;
    margin-right: 10px; }
    #page_wrapper .product-add-to-cart .qty button,
    #page_wrapper .product-add-to-cart .qty input {
      height: 50px !important; }
    #page_wrapper .product-add-to-cart .qty input {
      font-size: 16px; }
    #page_wrapper .product-add-to-cart .qty button:before {
      font-size: 11px; }
  #page_wrapper .product-add-to-cart .add {
    width: calc(100% - 134px); }
    #page_wrapper .product-add-to-cart .add button {
      height: 50px; }
      #page_wrapper .product-add-to-cart .add button span {
        font-size: 12px; }
      #page_wrapper .product-add-to-cart .add button i {
        display: none; } }

@media (max-width: 768px) {
  #product .mfp-image-holder {
    padding-left: 5px;
    padding-right: 5px; }
    #product .mfp-image-holder .mfp-close {
      right: 0;
      top: 0;
      background-color: #000;
      color: #fff;
      z-index: 999; }
    #product .mfp-image-holder .mfp-img {
      padding: 0;
      min-height: 460px;
      object-fit: cover; }
  #product .sizep h3 {
    font-size: 16px; }
  #product .sizep p {
    font-size: 13px; }
  #product .sizep h4 {
    font-size: 16px; }
  #product .sizep table th {
    font-size: 10px; }
  #product .sizep table td {
    font-size: 11px; }
  #product .mfp-inline-holder {
    padding: 5px; }
  #product .product_first_section {
    padding-bottom: 10px; }
    #product .product_first_section .swiper-button {
      display: none !important; }
  #product .product-manufacturer {
    padding-left: 0px; }
  #product .pro_gallery_thumbs {
    max-width: inherit; }
  #product .pro_gallery_thumbs_container {
    max-width: inherit; }
    #product .pro_gallery_thumbs_container .swiper-slide {
      width: 72px; }
  #product .product_left_column {
    flex: 0 0 100%;
    max-width: 100%; }
  #product .product_middle_column_inner {
    padding: 10px; }
  #product .product_middle_column {
    max-width: 100%;
    margin-left: 0;
    padding-left: 0px;
    padding-bottom: 25px; }
  #page_wrapper .product-reference {
    margin-bottom: 15px; }
  #page_wrapper .product-variants-item .control-label {
    font-size: 14px;
    margin-right: 5px; }
  #page_wrapper .product-add-to-cart {
    margin-top: 30px; }
    #page_wrapper .product-add-to-cart .pro_cart_block {
      flex-flow: row; }
    #page_wrapper .product-add-to-cart .pro_cart_right {
      width: calc(12.5% - 6px);
      margin-left: 6px; }
    #page_wrapper .product-add-to-cart .qty {
      display: inline-block;
      width: 87px;
      margin-right: 2px; }
      #page_wrapper .product-add-to-cart .qty input {
        font-size: 14px; }
      #page_wrapper .product-add-to-cart .qty button {
        width: 30px; }
    #page_wrapper .product-add-to-cart .add {
      width: calc(100% - 94px);
      display: inline-block; }
      #page_wrapper .product-add-to-cart .add button {
        height: 54px;
        padding: 10px 6px; }
        #page_wrapper .product-add-to-cart .add button span {
          font-size: 11px; }
    #page_wrapper .product-add-to-cart .add_to_wishlit {
      left: 3px; }
  .product-description-short {
    font-size: 12px;
    margin-bottom: 2px !important; }
  .availability {
    font-size: 12px;
    padding: 0 0 13px 19px;
    margin-bottom: 12px; } }

.product_third_section {
  margin-top: 77px;
  padding-bottom: 80px; }
  .product_third_section .title_block {
    max-width: 1512px;
    margin: auto; }
  .product_third_section .block_content {
    margin-top: 37px; }
  .product_third_section .title_block_inner {
    color: #000;
    font-weight: 700;
    position: relative;
    font-size: 30px;
    max-width: 180px;
    padding-left: 51px;
    line-height: 32px; }
    .product_third_section .title_block_inner:before {
      content: "";
      position: absolute;
      display: inline-block;
      width: 37px;
      height: 4px;
      left: 0;
      top: 20px;
      background-color: #000000; }

@media (max-width: 768px) {
  .product_third_section {
    margin-top: 22px;
    padding-bottom: 0px; }
    .product_third_section .title_block {
      font-size: 23px; }
    .product_third_section .block_content {
      margin-top: 19px; }
    .product_third_section .title_block_inner {
      font-size: 23px; } }

#product .st_product_comment_wrap {
  margin-top: 30px;
  padding: 25px;
  box-shadow: 0 0 25px 7px #00000026; }
  #product .st_product_comment_wrap #st_product_comment_submit {
    width: 100%;
    max-width: 220px;
    border-color: #000;
    background-color: #000; }

@media (max-width: 768px) {
  #product .st_product_comment_wrap {
    padding: 15px; }
    #product .st_product_comment_wrap h3 {
      font-size: 22px;
      margin-bottom: 18px; } }

#product .product_second_section {
  margin-top: 22px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 55px; }
  #product .product_second_section .tab-pane-body {
    max-width: 755px;
    margin: auto;
    font-size: 16px;
    font-weight: 300;
    color: #000;
    padding-top: 60px; }
  #product .product_second_section .nav-tabs {
    border: none; }
  #product .product_second_section .nav-item {
    margin-right: 20px; }
    #product .product_second_section .nav-item .nav-link {
      border-bottom: 3px solid #fff;
      font-size: 18px;
      font-weight: 300;
      color: #000;
      text-transform: capitalize; }
      #product .product_second_section .nav-item .nav-link.active {
        font-weight: 700; }
      #product .product_second_section .nav-item .nav-link.active, #product .product_second_section .nav-item .nav-link:hover {
        border-bottom: 3px solid #000; }
  #product .product_second_section .product-features {
    border: 1px solid #000; }
    #product .product_second_section .product-features .page_heading {
      display: none; }
    #product .product_second_section .product-features .data-sheet {
      padding: 6px 20px;
      background-color: #fff;
      color: #000;
      border-bottom: none; }
      #product .product_second_section .product-features .data-sheet:nth-child(2n) {
        background-color: #000;
        color: #fff; }

@media (max-width: 992px) {
  #product .product_second_section {
    margin-top: 0; }
    #product .product_second_section .mobile_tab_title {
      border: 1px solid #000;
      background-color: #000;
      color: #fff;
      text-transform: uppercase;
      font-weight: 400;
      padding: 10px 20px; }
      #product .product_second_section .mobile_tab_title .mobile_tab_name {
        font-size: 12px; }
      #product .product_second_section .mobile_tab_title i:before {
        color: #fff; }
    #product .product_second_section .tab-pane-body {
      padding-top: 35px;
      padding-bottom: 35px; } }

@media (max-width: 768px) {
  #product .product_second_section {
    padding-bottom: 25px; }
    #product .product_second_section .tab-pane-body {
      font-size: 13px; }
    #product .product_second_section #product-details .tab-pane-body {
      padding: 20px 0 25px 0; } }

.show-left-column #page_banner_container_1 .breadcrumb_nav {
  max-width: 1820px;
  margin: auto; }

.show-left-column #columns {
  max-width: 1853px;
  margin-top: 16px; }

.show-left-column #center_column {
  flex: 0 0 80.6%;
  max-width: 80.6%;
  padding-left: 57px; }

.show-left-column #left_column {
  flex: 0 0 19.4%;
  max-width: 19.4%; }

@media (max-width: 1200px) {
  .show-left-column #center_column {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 30px; }
  .show-left-column #left_column {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (max-width: 992px) {
  .show-left-column #center_column {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px; }
  .show-left-column #left_column {
    flex: 0 0 100%;
    max-width: 300px;
    height: 100%; }
    .show-left-column #left_column .main_column_box {
      padding-top: 15px; } }

@media (max-width: 768px) {
  .show-left-column #columns {
    margin-top: 5px; } }

#js-product-list-top {
  border-bottom: none;
  border-top: 1px solid #e6e6e6;
  margin: 23px 0 5px 0;
  width: 100%;
  padding-top: 28px;
  display: inline-block; }
  #js-product-list-top .product-count {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    float: left;
    margin-top: 18px; }
    #js-product-list-top .product-count strong {
      font-weight: 700; }
  #js-product-list-top .products-sort-order {
    float: right; }
    #js-product-list-top .products-sort-order .dropdown_tri {
      border: 1px solid #e1e1e1;
      color: #000;
      font-size: 16px;
      width: 100%;
      min-width: 226px;
      padding: 10px 15px;
      transition: 0.3s ease; }
      #js-product-list-top .products-sort-order .dropdown_tri:hover {
        border-color: #000; }
      #js-product-list-top .products-sort-order .dropdown_tri i {
        float: right;
        opacity: 1; }
        #js-product-list-top .products-sort-order .dropdown_tri i:before {
          color: #000; }
      #js-product-list-top .products-sort-order .dropdown_tri .arrow_down:before {
        content: "\E817"; }
      #js-product-list-top .products-sort-order .dropdown_tri .arrow_up:before {
        content: "\E816"; }
  #js-product-list-top .dropdown_list {
    width: 100%; }
  #js-product-list-top .dropdown_list_ul a {
    color: #000;
    font-size: 14px; }
    #js-product-list-top .dropdown_list_ul a:hover {
      background-color: #000;
      color: #fff; }

#js-product-list .product_list.grid .product_list_item {
  border-top: none;
  padding-bottom: 10px; }

#js-product-list .bottom_pagination {
  margin-top: 81px;
  padding-bottom: 137px; }
  #js-product-list .bottom_pagination nav {
    margin: auto; }
  #js-product-list .bottom_pagination li {
    margin: 0 6px; }
    #js-product-list .bottom_pagination li a {
      color: #000;
      width: 42px;
      height: 42px;
      font-size: 18px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px; }
      #js-product-list .bottom_pagination li a:hover {
        background-color: #e6e6e6; }
    #js-product-list .bottom_pagination li.active a {
      background-color: #e6e6e6;
      font-weight: 700; }
    #js-product-list .bottom_pagination li .previous {
      border: 2px solid #000;
      width: 52px;
      height: 52px;
      line-height: 36px; }
      #js-product-list .bottom_pagination li .previous:hover {
        background-color: #000; }
        #js-product-list .bottom_pagination li .previous:hover i:before {
          color: #fff; }
    #js-product-list .bottom_pagination li .next {
      border: 2px solid #000;
      width: 52px;
      height: 52px;
      line-height: 36px;
      background-color: #000; }
      #js-product-list .bottom_pagination li .next i:before {
        color: #fff; }
      #js-product-list .bottom_pagination li .next:hover {
        background-color: #fff; }
        #js-product-list .bottom_pagination li .next:hover i:before {
          color: #000; }

#js-active-search-filters {
  margin-bottom: -22px; }
  #js-active-search-filters .active_filter_title {
    color: #000;
    font-size: 14px;
    margin-right: 10px; }
  #js-active-search-filters a {
    background-color: #fff;
    color: #000;
    border-color: #e1e1e1;
    padding: 7px;
    padding-right: 25px;
    position: relative;
    font-size: 12px; }
    #js-active-search-filters a i {
      position: absolute;
      right: 5px; }
      #js-active-search-filters a i:before {
        color: #000; }
    #js-active-search-filters a:hover {
      background-color: #000;
      color: #fff; }
      #js-active-search-filters a:hover i:before {
        color: #fff; }

@media (max-width: 992px) {
  #js-product-list-top {
    padding: 20px 0 10px 0;
    margin: 0 0 5px 0; }
    #js-product-list-top .filter-button {
      float: left;
      margin-right: 21px;
      width: 170px; }
    #js-product-list-top #search_filter_toggler {
      border-color: #000;
      background-color: #000;
      width: 100%;
      padding: 14px 5px;
      font-size: 14px; }
    #js-product-list-top .product-count {
      margin-top: 14px; }
  #js-product-list .bottom_pagination {
    margin-top: 45px;
    padding-bottom: 30px; } }

@media (max-width: 768px) {
  #js-product-list-top {
    padding: 13px 0 10px 0; }
    #js-product-list-top .product-count {
      display: none; }
    #js-product-list-top .products-sort-order .dropdown_tri {
      text-align: center; }
  #js-product-list .bottom_pagination {
    margin-top: 20px;
    padding-bottom: 14px; } }

@media (max-width: 580px) {
  #js-product-list-top .filter-button {
    max-width: 340px;
    margin: auto;
    float: unset;
    width: 100%;
    margin-bottom: 10px; }
  #js-product-list-top .products-sort-order {
    width: 100%;
    max-width: 340px;
    margin: auto; } }

#left_column .block-categories {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 0; }
  #left_column .block-categories .block_content {
    padding-top: 0; }
  #left_column .block-categories .category-sub-menu li[data-depth="0"] a {
    color: #000;
    font-weight: 400;
    font-size: 16px;
    padding: 3px 0;
    padding-right: 8px; }
    #left_column .block-categories .category-sub-menu li[data-depth="0"] a:hover {
      color: #999; }
  #left_column .block-categories .category-sub-menu li[data-depth="0"] > .acc_header a {
    font-size: 18px;
    font-weight: 700; }
  #left_column .block-categories .category-sub-menu .category-sub-menu {
    margin-top: 9px; }
    #left_column .block-categories .category-sub-menu .category-sub-menu .category-sub-menu li a {
      font-size: 14px; }
    #left_column .block-categories .category-sub-menu .category-sub-menu .category-sub-menu .category-sub-menu li a {
      font-size: 13px; }
  #left_column .block-categories .category-sub-menu li {
    border: none; }
    #left_column .block-categories .category-sub-menu li i:before {
      color: #000;
      font-size: 14px; }
    #left_column .block-categories .category-sub-menu li .current_cate a {
      padding-left: 13px;
      position: relative; }
      #left_column .block-categories .category-sub-menu li .current_cate a:before {
        content: "";
        position: absolute;
        display: inline-block;
        left: 0;
        background-color: #000;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%); }

#search_filters_wrapper .facet-title-mobile {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 18px;
  color: #000; }
  @media (min-width: 992px) {
    #search_filters_wrapper .facet-title-mobile {
      pointer-events: none; } }
  @media (min-width: 992px) {
    #search_filters_wrapper .facet-title-mobile i {
      display: none; } }

#search_filters_wrapper .facet_filter_box {
  display: block;
  max-height: 271px; }

#search_filters_wrapper .facet_filter_box::-webkit-scrollbar {
  width: 5px; }

#search_filters_wrapper .facet_filter_box::-webkit-scrollbar-track {
  background-color: #ccc;
  border: 2px solid #fff; }

#search_filters_wrapper .facet_filter_box::-webkit-scrollbar-thumb {
  background-color: #000; }

#search_filters_wrapper .faceted-slider {
  margin-top: 18px;
  padding-bottom: 0px;
  margin-bottom: 24px;
  margin-left: 14px; }
  #search_filters_wrapper .faceted-slider .ui-slider-handle {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    top: -8px;
    background: #ffffff; }
  #search_filters_wrapper .faceted-slider .ui-slider {
    height: 8px;
    border-radius: 4px;
    background: #dddddd;
    border-color: #ddd; }
  #search_filters_wrapper .faceted-slider .ui-slider-range {
    background: #000;
    height: 8px; }
  #search_filters_wrapper .faceted-slider p {
    font-size: 14px;
    color: #595b5b;
    font-weight: 400;
    margin-bottom: 25px; }

#search_filters_wrapper .facet {
  padding: 25px 0 28px 0;
  border-bottom: 1px solid #dddddd; }
  #search_filters_wrapper .facet:last-child {
    border: none; }
  #search_filters_wrapper .facet .facet-label {
    margin-bottom: 0; }

#search_filters_wrapper .facet-label .search-link {
  color: #000;
  width: calc(100% - 40px);
  font-size: 16px; }
  #search_filters_wrapper .facet-label .search-link:hover {
    color: #999; }

#search_filters_wrapper .custom-input-box {
  margin-right: 15px; }

#search_filters_wrapper .custom-input-item {
  width: 18px;
  height: 18px;
  border: 1px solid #a4a4a4;
  background: unset; }

#search_filters_wrapper label.active .custom-input-item {
  background-color: #000;
  border-color: #000; }
  #search_filters_wrapper label.active .custom-input-item i:before {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px; }

#search_filters_wrapper .magnitude {
  display: none; }

@media (max-width: 992px) {
  #left_column .block-categories {
    padding-bottom: 8px; }
    #left_column .block-categories .category-sub-menu li[data-depth="0"] a {
      font-size: 14px; }
    #left_column .block-categories .category-sub-menu li[data-depth="0"] > .acc_header a {
      font-size: 16px; }
  #search_filters_wrapper .facet-title-mobile-inner {
    font-size: 16px; }
  #search_filters_wrapper .collapsed + .facet_filter_box {
    display: none; }
  #search_filters_wrapper .facet-title-mobile {
    margin-bottom: 0; }
    #search_filters_wrapper .facet-title-mobile i {
      opacity: 1; }
      #search_filters_wrapper .facet-title-mobile i:before {
        color: #000; }
    #search_filters_wrapper .facet-title-mobile .arrow_up:before {
      content: "\F107"; }
    #search_filters_wrapper .facet-title-mobile .arrow_down:before {
      content: "\F106"; }
  #search_filters_wrapper .block_content {
    padding-top: 0; }
  #search_filters_wrapper .facet {
    padding: 9px 0 9px 0; }
    #search_filters_wrapper .facet ul {
      margin-top: 10px; }
  #search_filters_wrapper .facet-label .search-link {
    font-size: 14px; } }

.category_page_heading {
  color: #000;
  font-size: 24px;
  font-weight: 700; }

#category-description {
  color: #000;
  letter-spacing: 0.8px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300; }

@media (max-width: 992px) {
  .category_page_heading {
    font-size: 21px; }
  #category-description {
    font-size: 14px; } }

@media (max-width: 768px) {
  .category_page_heading {
    font-size: 18px; }
  #category-description {
    font-size: 12px;
    line-height: 23px; } }

#home-brands {
  margin-top: 52px; }
  #home-brands .common {
    float: unset;
    margin-bottom: 35px; }
  #home-brands .brands_slider_item {
    border: none; }
  #home-brands .brands_slider {
    background-color: #f0f0f0;
    max-height: 166px; }
    #home-brands .brands_slider .flex_stretch {
      max-width: 1470px;
      margin: auto; }

@media (max-width: 1440px) {
  #home-brands .brands_slider .flex_stretch {
    max-width: 1270px; } }

@media (max-width: 992px) {
  #home-brands {
    margin-top: 0; }
    #home-brands .brands_slider .flex_stretch {
      max-width: 1270px; } }

#insta {
  margin-top: 68px;
  margin-bottom: 86px; }
  #insta .ins_image {
    height: 355px;
    object-fit: cover;
    width: 355px;
    object-position: top; }
  #insta h5 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 11px; }
  #insta h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 300; }

@media (max-width: 992px) {
  #insta {
    margin-top: 0px; } }

@media (max-width: 768px) {
  #insta {
    margin-top: 0px;
    margin-bottom: 0; }
    #insta h5 {
      font-size: 26px;
      margin-bottom: 4px; }
    #insta h3 {
      font-size: 15px; } }

#men-jacket #st_banner_block_9 {
  overflow: visible;
  width: 100%;
  display: inline-block; }

#men-jacket .st_image_layered_description_inner {
  position: absolute;
  left: -6%;
  top: 22%; }

#men-jacket h6 {
  margin-bottom: 70px;
  padding-left: 37px; }

#men-jacket h5 {
  text-align: left;
  line-height: 48px;
  margin-bottom: 0; }
  #men-jacket h5:before {
    right: 0;
    width: 70px;
    top: 32px;
    height: 5px; }

#men-jacket #banner_box_12 img {
  float: left;
  margin-top: 145px; }

#men-jacket #banner_box_13 img {
  float: right; }

@media (max-width: 1440px) {
  #men-jacket .st_image_layered_description_inner {
    left: -116px; }
  #men-jacket h5 {
    line-height: 45.93px;
    padding-right: 68px; }
    #men-jacket h5:before {
      width: 50px;
      top: 22px; }
  #men-jacket #banner_box_13 {
    padding-left: 80px; } }

@media (max-width: 1200px) {
  #men-jacket .st_image_layered_description_inner {
    left: -95px; }
  #men-jacket #banner_box_12 {
    flex: 0 0 27.33333%;
    max-width: 27.33333%; }
    #men-jacket #banner_box_12 img {
      margin-top: 84px; }
  #men-jacket #banner_box_13 {
    padding-left: 108px;
    flex: 0 0 72.66667%;
    max-width: 72.66667%; } }

@media (max-width: 992px) {
  #men-jacket h6 {
    margin-bottom: 22px; }
  #men-jacket h5 {
    line-height: 35.93px; }
  #men-jacket #banner_box_12 {
    flex: 0 0 31.33333%;
    max-width: 31.33333%; }
    #men-jacket #banner_box_12 img {
      margin-top: 13px; }
  #men-jacket #banner_box_13 {
    flex: 0 0 68.66667%;
    max-width: 68.66667%; } }

@media (max-width: 768px) {
  #men-jacket .wpb_column {
    padding: 0 5px; }
  #men-jacket .st_banner_11 {
    margin-bottom: 12px; }
  #men-jacket .st_image_layered_description_inner {
    left: -73px;
    top: 15%; }
  #men-jacket #banner_box_12 {
    display: none; }
  #men-jacket #banner_box_13 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 76px; } }

@media (max-width: 480px) {
  #men-jacket h5 {
    line-height: 23.93px;
    padding-right: 35px; }
    #men-jacket h5:before {
      width: 27px;
      top: 10px; } }

#two-banners {
  max-width: 1575px; }
  #two-banners .st_banner_5 {
    overflow: visible; }
  #two-banners .st_banner_block:hover img {
    filter: grayscale(1); }
  #two-banners .st_banner_block {
    padding-bottom: 40px; }
  #two-banners .st_image_layered_description_inner {
    position: absolute;
    bottom: 0;
    padding: 0; }
  #two-banners h5 {
    font-size: 65px; }
  #two-banners .men {
    color: #fff; }

@media (min-width: 992px) {
  #banner_box_6 {
    flex: 0 0 51%;
    max-width: 51%;
    padding-right: 7px; } }

#banner_box_6 img {
  box-shadow: -40px 55px 47px -24px #0000006e; }

#banner_box_6 .st_banner_block {
  padding-left: 53px;
  overflow: visible; }

@media (min-width: 992px) {
  #banner_box_6 .st_image_layered_description_inner {
    left: 30px; } }

@media (min-width: 992px) {
  #banner_box_7 {
    flex: 0 0 49%;
    max-width: 49%;
    padding-left: 7px; } }

#banner_box_7 .st_banner_block {
  padding-right: 27px; }

@media (min-width: 992px) {
  #banner_box_7 .st_image_layered_description_inner {
    right: 0; } }

@media (max-width: 1200px) {
  #two-banners .st_banner_5 {
    margin-bottom: 24px; }
  #two-banners .st_image_layered_description_inner {
    bottom: 7px; }
  #two-banners h5 {
    font-size: 55px; } }

@media (max-width: 992px) {
  #two-banners .st_image_layered_description_inner {
    bottom: 13px; }
  #two-banners h5 {
    font-size: 42px; }
  #banner_box_6 .st_banner_block {
    padding-left: 0; }
  #banner_box_7 .st_banner_block {
    padding-right: 0; } }

@media (max-width: 768px) {
  #two-banners {
    max-width: 480px; }
    #two-banners .banner_col {
      flex: 0 0 100%;
      max-width: 100%; }
    #two-banners h5 {
      font-size: 36px; }
  #banner_box_6 img {
    box-shadow: -4px 25px 35px -11px #0000006e; }
  #banner_box_6 .st_banner_block {
    margin-bottom: 0; }
  #banner_box_7 .st_banner_block {
    margin-bottom: 0; } }

.same {
  font-size: 50px;
  text-shadow: -3px 3px 10px #000000a1; }

#n-collection {
  margin-top: 95px;
  margin-bottom: 67px; }
  #n-collection #st_banner_block_6 {
    overflow: visible;
    width: 100%;
    display: inline-block; }
  #n-collection #banner_box_9 {
    padding-left: 0;
    flex: 0 0 72%;
    max-width: 72%; }
    #n-collection #banner_box_9 .st_image_layered_description_inner {
      position: absolute;
      right: 0.5%;
      top: 24%; }
    #n-collection #banner_box_9 img {
      float: left; }
  #n-collection #banner_box_10 {
    padding-right: 0;
    flex: 0 0 28%;
    max-width: 28%; }
    #n-collection #banner_box_10 img {
      float: right;
      margin-top: 91px; }
  #n-collection h6 {
    padding-left: 110px;
    margin-bottom: 60px; }
  #n-collection h5 {
    line-height: 57.93px;
    max-width: 330px;
    margin-bottom: 0; }
    #n-collection h5:before {
      width: 70px;
      height: 5px;
      top: 22px; }
  #n-collection a {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    border-bottom: 1px solid #000; }

@media (max-width: 1440px) {
  .same {
    font-size: 37px; }
  #n-collection #banner_box_9 {
    padding-right: 92px; }
    #n-collection #banner_box_9 .st_image_layered_description_inner {
      right: -117px; }
  #n-collection h5 {
    line-height: 45.93px;
    padding-left: 69px; }
    #n-collection h5:before {
      width: 54px; }
  #n-collection a {
    margin-right: 34px; } }

@media (max-width: 1200px) {
  .same {
    font-size: 32px; }
  #n-collection #banner_box_9 .st_image_layered_description_inner {
    right: -135px; } }

@media (max-width: 992px) {
  .same {
    font-size: 26px; }
  #n-collection {
    margin-bottom: 10px;
    margin-top: 12px; }
    #n-collection #banner_box_9 {
      flex: 0 0 68%;
      max-width: 68%; }
      #n-collection #banner_box_9 .st_image_layered_description_inner {
        right: -135px; }
    #n-collection #banner_box_10 {
      flex: 0 0 32%;
      max-width: 32%; }
      #n-collection #banner_box_10 img {
        margin-top: 27px; }
    #n-collection h5 {
      line-height: 35.93px; }
    #n-collection a {
      margin-right: 0; } }

@media (max-width: 768px) {
  #n-collection .st_banner_8 {
    margin: 0; }
  #n-collection .wpb_column {
    padding: 0 5px; }
  #n-collection #st_banner_block_6 {
    margin-bottom: 5px; }
  #n-collection #banner_box_9 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 71px; }
    #n-collection #banner_box_9 .st_image_layered_description_inner {
      right: -72px; }
  #n-collection #banner_box_10 {
    display: none; }
  #n-collection h6 {
    margin-bottom: 10px; } }

@media (max-width: 480px) {
  .same {
    font-size: 18px; }
  #n-collection h6 {
    margin-bottom: 20px; }
  #n-collection h5 {
    padding-left: 34px;
    line-height: 23.93px; }
    #n-collection h5:before {
      width: 27px;
      top: 10px; }
  #n-collection a {
    font-size: 13px;
    margin-right: -11px; } }

#new-prod {
  margin-top: 88px; }
  #new-prod .swiper-button-next {
    right: 7%; }
  #new-prod .swiper-button-prev {
    left: 11%; }
  #new-prod .ctitle {
    margin-bottom: 42px; }

@media (max-width: 992px) {
  #new-products {
    margin-top: 15px; }
    #new-products .sthomenew_container {
      margin-bottom: 15px; } }

@media (max-width: 768px) {
  #new-prod {
    margin-top: 0px; }
    #new-prod .sthomenew_container {
      padding: 0 20px; }
    #new-prod .ctitle {
      margin-bottom: 35px; } }

#swiper_container_out_1 {
  margin-bottom: 21px; }

@media (min-width: 768px) {
  .swiper_images_slider .st_image_layered_description_inner {
    position: absolute;
    left: 12%;
    top: 26%;
    transform: translateY(-50%); } }

.swiper_images_slider h3 {
  text-align: left;
  font-size: 29px;
  font-weight: 300;
  margin-bottom: 21px; }

.swiper_images_slider h4 {
  font-size: 62px;
  font-weight: 700; }

.swiper_images_slider .shop {
  margin-top: 78px; }

.num-count {
  color: #000;
  font-weight: 700;
  position: absolute;
  bottom: 2%;
  left: 50px; }
  .num-count .inline {
    display: inline-block; }
  .num-count .start {
    font-size: 88px; }
    .num-count .start span {
      overflow: hidden;
      max-width: 33px;
      width: 100%;
      display: inline-flex;
      transform: rotate(180deg); }
  .num-count .pipe {
    font-size: 26px; }
  .num-count .end {
    font-size: 26px; }

#body_wrapper .swiper_images_slider .swiper-button {
  top: auto;
  bottom: 9%; }

#body_wrapper .swiper_images_slider .swiper-button-next {
  right: 77px; }

#body_wrapper .swiper_images_slider .swiper-button-prev {
  left: auto;
  right: 142px; }

@media (max-width: 1360px) and (min-width: 768px) {
  .swiper_images_slider .st_image_layered_description_inner {
    left: 5%;
    top: 18%; } }

@media (max-width: 1360px) {
  .swiper_images_slider h3 {
    font-size: 26px;
    margin-bottom: 15px; }
  .swiper_images_slider h4 {
    font-size: 50px; }
  .swiper_images_slider .shop {
    margin-top: 40px; }
  .num-count {
    bottom: 10px; }
    .num-count .inline {
      display: inline-block; }
    .num-count .start {
      font-size: 40px; }
      .num-count .start span {
        max-width: 15px; }
    .num-count .pipe {
      font-size: 23px; }
    .num-count .end {
      font-size: 23px; }
  #body_wrapper .swiper_images_slider .swiper-button {
    bottom: 23px; }
  #body_wrapper .swiper_images_slider .swiper-button-next {
    right: 77px; }
  #body_wrapper .swiper_images_slider .swiper-button-prev {
    right: 142px; } }

@media (max-width: 992px) and (min-width: 768px) {
  .swiper_images_slider .st_image_layered_description_inner {
    left: 3%; } }

@media (max-width: 992px) {
  .swiper_images_slider h3 {
    font-size: 23px;
    margin-bottom: 4px; }
  .swiper_images_slider h4 {
    font-size: 38px; }
  .swiper_images_slider .shop {
    margin-top: 18px; }
  .num-count {
    bottom: 0px; }
  #body_wrapper .swiper_images_slider .swiper-button {
    display: none !important; } }

@media (max-width: 768px) {
  .swiper_images_slider img {
    min-height: 250px;
    object-fit: cover; }
  .swiper_images_slider .st_image_layered_description_inner {
    background-color: #ffffff9c;
    padding: 19px 24px; }
  .swiper_images_slider h3 {
    font-size: 19px; }
  .swiper_images_slider h4 {
    font-size: 30px; }
  .num-count {
    left: 15px; }
    .num-count .start {
      font-size: 26px; }
      .num-count .start span {
        max-width: 10px; }
  #body_wrapper .swiper_images_slider .swiper-button {
    display: none; } }

#usp {
  background-color: #000;
  padding: 45px 0 38px 0; }
  #usp .common {
    max-width: 1705px;
    padding: 0 15px; }
  #usp .wpb_single_image {
    margin-bottom: 21px; }
  #usp .col3 .wpb_single_image {
    margin-bottom: 17px; }
  #usp .col4 .wpb_single_image {
    margin-bottom: 12px; }
  #usp .bottom {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center; }
    #usp .bottom strong,
    #usp .bottom a {
      color: #fff;
      font-size: 16px; }
    #usp .bottom strong {
      display: block;
      margin-bottom: 3px; }
    #usp .bottom p {
      line-height: 17px;
      max-width: 253px;
      color: #bdbdbd;
      font-weight: 200;
      margin: auto; }

@media (max-width: 1200px) {
  #usp .bottom p {
    font-size: 12px; } }

@media (max-width: 992px) {
  #usp .common {
    padding: 0; }
  #usp .col {
    padding: 0 10px; }
  #usp .bottom strong,
  #usp .bottom a {
    font-size: 14px; }
  #usp .bottom p {
    font-size: 11px; } }

@keyframes scroll {
  0% {
    transform: translateX(300px); }
  100% {
    transform: translateX(calc(-240px * 4)); } }

@media (max-width: 768px) {
  #usp {
    padding: 25px 0; }
    #usp .common {
      max-width: 420px;
      display: flex;
      margin: auto;
      overflow: hidden;
      position: relative;
      flex-flow: row; }
    #usp .col {
      max-width: 220px;
      flex: 0 0 100%;
      animation: scroll 12s linear infinite;
      display: flex; } }

#submitWishlist {
  background-color: #000;
  border-color: #000; }

.myacount_dashbord_list a {
  color: #000; }
  .myacount_dashbord_list a:hover {
    color: #808080; }

.myacount_dashbord_list .col-lg-4 {
  padding: 12px 6px;
  display: inline-block;
  width: 260px;
  text-align: center;
  font-size: .875rem;
  margin: 0 8px 16px;
  background: transparent;
  border-color: #d9d9d9;
  flex: unset;
  max-width: unset;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .myacount_dashbord_list .col-lg-4 span {
    color: #000; }
  .myacount_dashbord_list .col-lg-4 i {
    display: none; }

.my_account_left_column a {
  color: #000; }

.my_account_left_column .col-lg-4 {
  padding: 12px 6px;
  display: inline-block;
  background: transparent;
  border-color: #d9d9d9;
  flex: unset;
  max-width: unset;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
  margin: 0;
  text-align: left;
  padding-left: 25px;
  font-size: 12px; }
  .my_account_left_column .col-lg-4 span {
    color: #000; }
    .my_account_left_column .col-lg-4 span i {
      display: none; }

.fl,
.fr {
  padding: 13px;
  color: #fff;
  background-color: #000; }
  .fl:hover,
  .fr:hover {
    color: #fff; }

.fr {
  background-color: #808080;
  float: left;
  margin-left: 15px; }

#lrp-customer-account {
  font-size: 14px;
  color: #000; }
  #lrp-customer-account th {
    color: #000; }
  #lrp-customer-account .points-card {
    min-width: 141px;
    height: 75px;
    margin-bottom: 13px;
    text-align: center; }
    #lrp-customer-account .points-card span {
      line-height: 34px; }
    #lrp-customer-account .points-card strong {
      color: #000;
      font-size: 32px; }

#blockcart-modal .title_block_inner {
  color: #000; }

#blockcart-modal .swiper-button-tr i {
  background-color: #000; }

#blockcart-modal .alert-success {
  background-color: unset;
  color: #2eb841;
  font-weight: 700;
  border: 1px solid #2eb841;
  text-align: center;
  padding: 7px;
  font-size: 15px; }

#blockcart-modal .st_modal_close {
  background-color: #000; }
  #blockcart-modal .st_modal_close span {
    color: #fff;
    background-color: #000;
    font-weight: 700; }

#blockcart-modal .product_name {
  color: #000;
  margin-bottom: 10px;
  font-size: 15px; }

#blockcart-modal .list_detail_item {
  color: #000; }
  #blockcart-modal .list_detail_item li {
    font-weight: 300; }
  #blockcart-modal .list_detail_item span {
    font-weight: 300;
    margin-right: 8px;
    font-size: 15px;
    min-width: 164px;
    display: inline-block; }
  #blockcart-modal .list_detail_item.m-b-1 span {
    min-width: unset; }

#blockcart-modal .cart-products-count {
  color: #000;
  margin-bottom: 3px; }

#blockcart-modal .cart-content-btn {
  padding-left: 5px;
  padding-right: 5px; }
  #blockcart-modal .cart-content-btn .col-md-6 {
    padding-right: 5px;
    padding-left: 5px; }
    #blockcart-modal .cart-content-btn .col-md-6 button,
    #blockcart-modal .cart-content-btn .col-md-6 a {
      background-color: #dedede;
      color: #000;
      border-color: #000;
      height: auto;
      line-height: 27px;
      font-size: 14px;
      border: none; }
    #blockcart-modal .cart-content-btn .col-md-6 a {
      background-color: #000;
      color: #fff; }

#blockcart-modal .modal_cart_details {
  color: #000; }

#header_right .small_cart_info a {
  color: #000;
  font-weight: 700;
  font-size: 12px; }

#header_right .small_cart_info .price {
  font-size: 15px;
  color: #000; }

#header_right .small_cart_info .small_cart_attr_attr {
  font-size: 12px;
  margin-right: 10px;
  color: #000; }
  #header_right .small_cart_info .small_cart_attr_attr span {
    display: inline-block;
    float: left; }

#header_right .small_cart_sumary {
  color: #000;
  font-weight: 700; }

#header_right .cart-summary-v {
  color: #000;
  font-size: 13px;
  font-weight: 700; }

#header_right .cart-summary-k {
  font-size: 13px; }

#header_right .small_cart_btn {
  border: none;
  line-height: 34px;
  font-size: 16px;
  background: #000; }

@media (max-width: 768px) {
  #blockcart-modal .modal-content {
    width: 100%;
    margin: 0; }
  #blockcart-modal .modal-dialog {
    width: 100%;
    padding: 10px; }
  #blockcart-modal .st_modal_close {
    top: 0px;
    right: 0px;
    z-index: 9;
    width: 32px;
    height: 32px; }
    #blockcart-modal .st_modal_close span {
      top: 1px;
      position: relative; } }

[class^="module-stblog"] #columns {
  position: relative;
  margin-top: 48px;
  padding-bottom: 40px;
  max-width: 1660px; }
  [class^="module-stblog"] #columns .bottom_pagination .product_count {
    display: none; }
  [class^="module-stblog"] #columns .product_list.list .product_list_item {
    clear: unset;
    padding-top: 0;
    border-bottom: none; }
    @media (min-width: 992px) {
      [class^="module-stblog"] #columns .product_list.list .product_list_item {
        width: calc(33.3% - 5px);
        margin-right: 10px; }
        [class^="module-stblog"] #columns .product_list.list .product_list_item:nth-child(3n) {
          margin-right: 0;
          float: right;
          width: calc(33.4% - 10px); } }

[class^="module-stblog"] .pro_outer_box {
  flex-flow: column;
  min-height: 440px; }

[class^="module-stblog"] .product_list_item {
  float: left;
  flex: unset;
  max-width: unset; }
  [class^="module-stblog"] .product_list_item .blog_image {
    text-align: center; }
  [class^="module-stblog"] .product_list_item .pro_first_box {
    border: none; }
  [class^="module-stblog"] .product_list_item .block_blog {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }
    [class^="module-stblog"] .product_list_item .block_blog .pro_second_box {
      padding: 15px; }
  [class^="module-stblog"] .product_list_item .blog_info {
    display: none; }
  [class^="module-stblog"] .product_list_item .go {
    margin-top: 15px; }
  [class^="module-stblog"] .product_list_item .s_title_block {
    margin-bottom: 10px; }
    [class^="module-stblog"] .product_list_item .s_title_block a {
      font-size: 18px;
      color: #000; }
      @media (min-width: 768px) {
        [class^="module-stblog"] .product_list_item .s_title_block a {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 27px; } }
  [class^="module-stblog"] .product_list_item .blok_blog_short_content {
    font-size: 14px;
    line-height: 27px;
    color: #737373;
    min-height: 87px; }

[class^="module-stblog"] .bottom_pagination {
  width: 100%;
  display: inline-block; }

@media (max-width: 992px) {
  #sidebar_box a {
    background: #272727; }
    #sidebar_box a i:before {
      color: #fff; }
    #sidebar_box a .icon_text {
      color: #fff; } }

@media (max-width: 992px) and (min-width: 768px) {
  [class^="module-stblog"] .product_list_item {
    width: calc(50% - 5px); }
    [class^="module-stblog"] .product_list_item:nth-child(2n) {
      margin-right: 0;
      float: right;
      width: calc(50% - 10px); } }

@media (max-width: 992px) {
  [class^="module-stblog"] .product_list_item .block_blog .pro_second_box {
    padding: 15px; }
  [class^="module-stblog"] .product_list_item .s_title_block a {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px; }
  [class^="module-stblog"] .bottom_pagination {
    width: 100%;
    display: inline-block; } }

@media (max-width: 768px) {
  [class^="module-stblog"] #columns {
    margin-top: 0; }
  #module-stblog-default .product_list_item {
    max-width: 404px;
    margin: auto;
    float: unset; }
    #module-stblog-default .product_list_item .s_title_block {
      height: auto; }
      #module-stblog-default .product_list_item .s_title_block a {
        font-size: 15px; } }

[class^="module-stblog"] #page_banner_container_1 {
  margin-top: 6px; }

[class^="module-stblog"].show-left-column #columns {
  position: relative;
  max-width: 1540px;
  padding-bottom: 0; }
  [class^="module-stblog"].show-left-column #columns .blog_content {
    color: #000; }
    [class^="module-stblog"].show-left-column #columns .blog_content p img {
      max-width: 500px;
      object-fit: cover;
      margin: 0 10px; }
    [class^="module-stblog"].show-left-column #columns .blog_content h3 {
      margin-top: 25px;
      font-size: 22px; }
  [class^="module-stblog"].show-left-column #columns h1 {
    color: #000;
    font-weight: 700; }
  @media (min-width: 992px) {
    [class^="module-stblog"].show-left-column #columns #center_column {
      padding-left: 100px;
      flex: 0 0 75%;
      max-width: 75%; } }
  @media (min-width: 992px) {
    [class^="module-stblog"].show-left-column #columns #left_column {
      flex: 0 0 25%;
      max-width: 25%; } }

[class^="module-stblog"].show-left-column .blog_image {
  text-align: center;
  margin-bottom: 20px; }

[class^="module-stblog"].show-left-column #blog_link_nav .fl {
  background: #000;
  color: #fff;
  padding: 6px 10px;
  transition: 0.3s ease; }
  [class^="module-stblog"].show-left-column #blog_link_nav .fl:hover {
    background: #808080; }

[class^="module-stblog"].show-left-column #blog_link_nav .fr {
  background: #808080;
  color: #fff;
  padding: 6px 10px;
  transition: 0.3s ease; }
  [class^="module-stblog"].show-left-column #blog_link_nav .fr:hover {
    background: #000; }

[class^="module-stblog"].show-left-column #st_blog_comment_reply_block #stblog_leave_a_comment {
  color: #000; }

[class^="module-stblog"].show-left-column #st_blog_comment_reply_block .st_blog_comment_reply {
  color: #000; }
  [class^="module-stblog"].show-left-column #st_blog_comment_reply_block .st_blog_comment_reply .go {
    color: #000;
    background-color: unset;
    font-weight: 700;
    text-decoration: none; }

[class^="module-stblog"].show-left-column #left_column {
  padding: 0; }
  [class^="module-stblog"].show-left-column #left_column .wrapper-sticky {
    background-color: #f7f7f7;
    padding: 20px 15px;
    box-shadow: 0 0px 11px 2px #00000036; }
  [class^="module-stblog"].show-left-column #left_column .column_block {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 7px;
    margin-top: 10px; }
  [class^="module-stblog"].show-left-column #left_column .input-group {
    border: none;
    box-shadow: 0 0px 21px 1px #00000021; }
    [class^="module-stblog"].show-left-column #left_column .input-group input {
      height: 42px;
      padding-left: 25px; }
    [class^="module-stblog"].show-left-column #left_column .input-group input::placeholder {
      color: #000; }
    [class^="module-stblog"].show-left-column #left_column .input-group .input-group-btn {
      height: 34px;
      margin-top: 4px;
      padding-right: 6px; }
      [class^="module-stblog"].show-left-column #left_column .input-group .input-group-btn button {
        background-color: #000;
        width: 60px;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        border: none; }
  [class^="module-stblog"].show-left-column #left_column .title_block_inner {
    color: #272727;
    font-size: 16px;
    padding: 3px 0;
    line-height: 20px;
    margin-bottom: 4px; }
  [class^="module-stblog"].show-left-column #left_column .block_content {
    padding: 3px 0; }
  [class^="module-stblog"].show-left-column #left_column .category-sub-menu a {
    font-size: 16px;
    padding: 4px 0; }

@media (max-width: 1340px) {
  [class^="module-stblog"].show-left-column #left_column .title_block_inner {
    font-size: 20px; }
  [class^="module-stblog"].show-left-column #left_column .category-sub-menu a {
    font-size: 15px; } }

@media (max-width: 992px) {
  [class^="module-stblog"] #center_column {
    padding-left: 15px; }
  #sidebar_box {
    display: none; } }

#manufacturer #left_column {
  display: none; }

#manufacturer #center_column {
  flex: 0 0 100%;
  max-width: 100%; }

#manufacturer #sidebar_box {
  display: none; }

#manufacturer #columns {
  max-width: 1440px;
  margin: auto;
  padding-bottom: 15px; }
  #manufacturer #columns h1 {
    color: #000;
    font-weight: 900;
    font-size: 18px; }
  #manufacturer #columns .brand {
    background-color: #f9f9f9;
    border: 1px dashed #000;
    padding: 10px; }
    #manufacturer #columns .brand .brand-img img {
      max-height: 160px;
      object-fit: cover; }
    @media (min-width: 1050px) {
      #manufacturer #columns .brand {
        width: calc(33.3% - 10px);
        float: left;
        margin: 5px;
        min-height: 130px; } }
    @media (max-width: 1049px) and (min-width: 768px) {
      #manufacturer #columns .brand {
        width: calc(50% - 10px);
        float: left;
        margin: 5px;
        min-height: 130px; } }
    #manufacturer #columns .brand a {
      color: #000;
      font-weight: 900; }
    #manufacturer #columns .brand .go {
      display: block;
      margin-top: 10px;
      color: #fff;
      background-color: #000;
      padding: 10px;
      text-decoration: none;
      max-width: 178px;
      text-align: center;
      font-size: 14px; }

@media (max-width: 480px) {
  #manufacturer #columns .brand {
    flex-flow: column;
    max-width: 294px;
    margin: auto;
    margin-bottom: 15px; }
    #manufacturer #columns .brand .flex_child {
      margin: auto; }
    #manufacturer #columns .brand .brand-img {
      margin: auto !important; } }

#lrp-summary {
  color: #000;
  position: relative;
  border: 2px dashed #000;
  padding: 8px 13px 8px 37px;
  display: inline-block;
  margin-bottom: 10px; }
  #lrp-summary a {
    color: #2eb841; }
  #lrp-summary i {
    display: none; }

#lrp-redeem-form {
  margin-bottom: 10px; }
  #lrp-redeem-form .points-label {
    margin-left: 10px; }
  #lrp-redeem-form #btn-lrp-redeem {
    color: #fff;
    background-color: #000;
    height: 40px;
    line-height: 26px; }

#cart #center_column {
  padding-bottom: 20px;
  max-width: 1700px;
  margin: auto; }
  #cart #center_column .cart-grid-body .card {
    order: 1;
    border: none; }
    #cart #center_column .cart-grid-body .card .card-header {
      font-size: 24px;
      color: #000;
      font-weight: 700;
      border-color: #dfdfdf; }
    #cart #center_column .cart-grid-body .card .cart-items {
      margin-top: 0; }
      #cart #center_column .cart-grid-body .card .cart-items .cart-item {
        padding-top: 10px;
        padding-bottom: 10px;
        border-color: #dfdfdf; }
        #cart #center_column .cart-grid-body .card .cart-items .cart-item:last-child {
          border-bottom: 1px solid #dfdfdf; }
        #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body {
          padding-top: 27px;
          padding-bottom: 24px; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .label {
            font-weight: 700;
            color: #000; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info .product-discount {
            display: inline-block; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info .product-discount .discount-percentage {
              display: none; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info .current-price {
            display: inline-block; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info .current-price .price {
              font-size: 15px; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info .current-price .regular-price {
              font-size: 15px; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info.product-price {
            display: block;
            margin-bottom: 18px; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info:not(.product-price) {
            display: inline-block; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info:not(.product-price) .value {
              font-weight: 700;
              font-size: 15px; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info:first-child .label {
            margin-bottom: 20px;
            display: block;
            font-size: 15px; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info:nth-child(n + 3) .label {
            display: none; }
        @media (min-width: 768px) {
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right > .row {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 6%;
            width: 100%; } }
        #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap {
          width: 100%;
          max-width: 110px; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group {
            width: 100%;
            border: 1px solid #dfdfdf; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group .input-group-btn:first-child:before {
              content: "";
              position: absolute;
              display: inline-block;
              width: 12px;
              height: 2px;
              z-index: 99;
              background-color: #ccc;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%); }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group .input-group-btn:first-child button {
              color: transparent; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group input,
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group button {
              height: 43px;
              border: none; }
              #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group input:hover,
              #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group button:hover {
                background: unset; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group button {
              color: #cccccc;
              width: 37px;
              font-size: 20px; }
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group input {
              font-weight: 700;
              color: #000;
              font-size: 15px; }
        #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .col-md-6 {
          text-align: center; }
        #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .product-price {
          font-weight: 700;
          font-size: 15px;
          line-height: 45px; }
        #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .remove-from-cart i:before {
          content: '';
          position: absolute;
          border: none;
          left: 6px;
          width: 21px;
          height: 1px;
          top: 19px;
          border-bottom: 3px solid #000;
          border-radius: 0;
          transform: rotate(-45deg); }
        #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .remove-from-cart i:after {
          content: '';
          position: absolute;
          left: 6px;
          width: 21px;
          height: 1px;
          top: 19px;
          border-bottom: 3px solid #000;
          transform: rotate(45deg); }
  #cart #center_column .cart-grid-body .btn-default {
    order: 3;
    background-color: #fff;
    color: #000;
    border: 1px solid #e1e1e1;
    font-weight: 700;
    padding: 15px 58px;
    height: auto; }
    #cart #center_column .cart-grid-body .btn-default:hover {
      border-color: #000; }
    #cart #center_column .cart-grid-body .btn-default i {
      display: none; }
  #cart #center_column .cart-grid-right {
    margin-top: 11px; }
    #cart #center_column .cart-grid-right .card-header-right {
      font-size: 24px;
      color: #000;
      font-weight: 700;
      margin-bottom: 12px;
      display: block; }
    #cart #center_column .cart-grid-right .card {
      border: none; }
      #cart #center_column .cart-grid-right .card .cart-detailed-totals {
        background-color: #f0f0f0;
        padding: 21px 4px;
        padding-bottom: 4px; }
        #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block {
          padding-bottom: 0;
          padding-top: 0; }
          #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block hr {
            display: none; }
          #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line {
            margin-bottom: 15px; }
            #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line .label,
            #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line .value {
              font-weight: 400;
              font-size: 16px; }
            #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line.cart-total .label,
            #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line.cart-total .value {
              font-weight: 700; }
    #cart #center_column .cart-grid-right .cart-detailed-actions {
      padding: 0;
      margin-top: 15px; }
      #cart #center_column .cart-grid-right .cart-detailed-actions .btn-default {
        height: 57px;
        line-height: 41px;
        font-size: 16px;
        border-color: #000;
        background-color: #000; }

@media (max-width: 1200px) {
  #cart #center_column {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px; }
    #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line .label,
    #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line .value {
      font-size: 15px; } }

@media (max-width: 768px) {
  #cart #center_column {
    padding-top: 0;
    padding-bottom: 0px; }
    #cart #center_column .cart-grid-body {
      margin-bottom: 0 !important; }
      #cart #center_column .cart-grid-body picture {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        left: 0; }
      #cart #center_column .cart-grid-body .card {
        margin-bottom: 0 !important; }
        #cart #center_column .cart-grid-body .card .card-header {
          padding-top: 0; }
        #cart #center_column .cart-grid-body .card .cart-items {
          padding-bottom: 0; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-left {
            position: relative;
            flex: 0 0 29%;
            max-width: 29%; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body {
            flex: 0 0 71%;
            max-width: 71%; } }
          @media (max-width: 768px) and (max-width: 440px) {
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body {
              padding-top: 0; } }

@media (max-width: 768px) {
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .label {
              font-size: 14px; } }
          @media (max-width: 768px) and (max-width: 440px) {
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info.product-price {
              margin-bottom: 2px; } }

@media (max-width: 768px) {
            #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info:first-child .label {
              font-size: 14px; } }
            @media (max-width: 768px) and (max-width: 440px) {
              #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-body .product-line-info:first-child .label {
                margin-bottom: 8px; } }
        @media (max-width: 768px) and (max-width: 440px) {
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right {
            margin-top: 20px;
            margin-bottom: 10px; } }
        @media (max-width: 768px) and (max-width: 440px) {
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .hidden-md-up {
            display: none; } }
        @media (max-width: 768px) and (max-width: 440px) {
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .col-md-10.col-7 {
            flex: 0 0 80.33333%;
            max-width: 80.33333%; } }

@media (max-width: 768px) {
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .qty .qty_wrap .input-group button {
            width: 28px; }
          #cart #center_column .cart-grid-body .card .cart-items .cart-item .product-line-grid-right .col-md-6 {
            min-width: 120px; }
      #cart #center_column .cart-grid-body .btn-default {
        display: none; }
    #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line .label,
    #cart #center_column .cart-grid-right .card .cart-detailed-totals .card-block .cart-summary-line .value {
      font-size: 15px; } }

#checkout #header_center,
#checkout #header_right,
#checkout #mobile_bar_left,
#checkout #mobile_bar_right {
  display: none; }

#checkout #cms_modal {
  color: #000; }
  #checkout #cms_modal .close {
    background-color: #000;
    color: #fff;
    padding: 7px;
    width: 32px;
    height: 32px;
    font-size: 21px; }
    #checkout #cms_modal .close i {
      margin-right: 0;
      position: relative;
      bottom: 4px;
      right: -1px; }
  #checkout #cms_modal h1 {
    color: #000; }
  #checkout #cms_modal .style_content.cms_content {
    font-size: 13px !important; }
  @media (max-width: 480px) {
    #checkout #cms_modal .modal-dialog {
      margin: 0; }
      #checkout #cms_modal .modal-dialog .modal-content {
        margin-right: 10px; } }

#checkout #header_primary_container {
  padding-top: 25px;
  padding-bottom: 25px; }

#checkout div#onepagecheckoutps div.clear {
  padding-left: 21px;
  margin-top: 6px; }

#checkout #easycontent_17 {
  display: none; }

#checkout div#onepagecheckoutps .onepagecheckoutps_p_step i.fa-pts {
  display: none; }

#checkout #opc_modal .modal-content {
  color: #000;
  margin: 0; }
  #checkout #opc_modal .modal-content .modal-dialog {
    max-width: 340px; }
  #checkout #opc_modal .modal-content .forget_password {
    text-align: left !important;
    line-height: 15px; }
    #checkout #opc_modal .modal-content .forget_password a {
      font-size: 12px;
      line-height: 17px; }
  #checkout #opc_modal .modal-content .panel-title {
    font-size: 15px; }
    #checkout #opc_modal .modal-content .panel-title .fa-pts-times-circle {
      display: none; }
  #checkout #opc_modal .modal-content #btn_login {
    background-color: #000;
    border-color: #000; }

#checkout #opc_modal.show {
  background-color: #0000003b; }

#checkout #wrapper {
  max-width: 1400px;
  margin: auto; }
  #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 19px;
    margin-top: 30px;
    margin-bottom: 33px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header .row {
      flex-flow: row; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header div#div_onepagecheckoutps_info {
      float: left;
      flex: unset;
      max-width: unset; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header div#div_onepagecheckoutps_info h1 {
        font-size: 20px;
        font-weight: 700;
        color: #000; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header div#div_onepagecheckoutps_info p {
        font-size: 14px;
        font-weight: 400;
        color: #000;
        max-width: 480px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login {
      flex: 100%;
      max-width: 500px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #opc_show_login,
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout {
      background-color: unset;
      border: 1px solid #000000;
      color: #000;
      font-weight: 700;
      font-size: 16px;
      height: auto;
      min-width: 180px;
      line-height: 42px;
      transition: 0.3s ease; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #opc_show_login:hover,
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout:hover {
        background-color: #000;
        color: #fff; }
        #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #opc_show_login:hover i:before,
        #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout:hover i:before {
          color: #fff; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login br {
      display: none; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login p {
      color: #000;
      font-weight: 400;
      display: inline-block;
      width: 100%;
      float: left;
      text-align: left;
      margin-top: 27px; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login p a {
        color: #000;
        display: block; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout {
      float: right;
      padding: 0;
      position: relative;
      padding-right: 0px;
      margin-top: -48px; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout i {
        display: none; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .left_content {
    flex: 0 0 43%;
    max-width: 43%; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row .left_content .form-group {
      padding-left: 5px;
      padding-right: 5px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .right_content {
    flex: 0 0 57%;
    max-width: 57%;
    padding-left: 52px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row input[type=radio],
  #checkout #wrapper #onepagecheckoutps_contenedor .row input[type=checkbox] {
    appearance: none;
    background: none;
    margin-left: 5px;
    margin-right: 5px;
    background: #fff;
    width: 16px;
    height: 16px;
    padding: 6px 6px;
    border-radius: 50% !important;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #00000066;
    float: left;
    margin-top: 0 !important; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row input[type=radio]:focus,
    #checkout #wrapper #onepagecheckoutps_contenedor .row input[type=checkbox]:focus {
      outline: none;
      border: 4px solid #fff;
      box-shadow: 0px 0px 0px 1px #000;
      padding: 4px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row input[type=radio]:checked,
    #checkout #wrapper #onepagecheckoutps_contenedor .row input[type=checkbox]:checked {
      background: #000;
      box-shadow: 0px 0px 0px 1px  #000;
      border: 4px solid #ffffff;
      padding: 4px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one .small-text {
    color: #000; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one label {
    font-size: 14px;
    color: #000; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one label[for="checkbox_create_invoice_address"] {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 21px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_checkbox_create_account_guest {
    margin-top: 20px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_checkbox_create_account_guest label {
      color: #000;
      font-size: 14px;
      font-weight: 700; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_checkbox_create_account_guest label input {
        margin-right: 17px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one #field_customer_newsletter input {
    margin-right: 17px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice {
    margin-top: 34px;
    margin-bottom: 15px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice > label {
      color: #000;
      font-size: 16px;
      margin-bottom: 29px;
      font-weight: 900; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice .row label {
      font-size: 14px;
      color: #000; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice .row .col-6 {
      max-width: 150px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice .row .col-6 input {
        margin-right: 19px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option {
    border: none;
    background-color: unset; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content {
      padding-bottom: 4px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content .delivery_option_logo {
        width: 100%; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content .delivery_option_logo .delivery_option_title {
          color: #000000;
          font-size: 15px;
          font-weight: 400; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content .delivery_option_price {
        display: none; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container label {
    color: #000;
    font-size: 15px;
    margin-top: 13px;
    margin-left: 30px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container label[for="gift_message"] {
    margin-left: 70px;
    font-size: 13px; }
    @media (max-width: 1200px) {
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container label[for="gift_message"] {
        margin-left: 55px; } }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container textarea {
    max-width: calc(100% - 97px);
    min-height: 80px;
    float: right;
    margin-right: 30px; }
    @media (max-width: 1200px) {
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container textarea {
        max-width: calc(100% - 83px); } }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container #gift {
    margin-right: 19px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .selected {
    background-color: unset;
    border: none; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three #modirum_confirmation_form {
    margin-top: -20px;
    color: #000;
    font-size: 15px;
    margin-left: 21px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three #modirum_confirmation_form select {
      padding: 8px 20px 8px 12px;
      max-width: 140px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .payment-option {
    padding-top: 7px;
    padding-bottom: 0px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .payment-option span {
      font-size: 15px;
      font-weight: 400;
      color: #000; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container {
    margin-top: 41px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #remaining_amount_free_shipping {
      border: 1px solid #CCC;
      padding: 10px;
      color: #000; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review {
      margin-top: 28px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content {
        border-bottom: 1px solid #0000001a;
        margin: 0;
        padding-bottom: 10px; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content div {
          text-decoration: none;
          font-size: 14px;
          padding-left: 0;
          font-weight: 700;
          color: #000; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content .offset-1 {
          margin-left: 0;
          flex: 0 0 66%;
          max-width: 66%; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row {
        margin: 0;
        padding-top: 6px;
        padding-bottom: 6px;
        border-bottom: 1px solid #0000001a; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .image_product {
          flex: 0 0 11%;
          padding-left: 0;
          max-width: 11%; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .image_product img {
            border: none; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .cart_description .s_title_block {
          overflow: visible;
          max-width: 300px;
          height: auto; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .cart_description span {
          color: #000;
          font-size: 14px; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content {
          flex: 0 0 21.66667%;
          max-width: 21.66667%; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content input {
            width: 53px;
            height: 46px !important;
            padding: 5px; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content .input-group-btn-vertical button {
            height: 23px;
            border-radius: 0; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content .input-group-btn-vertical i:before {
            font-family: fontello;
            font-style: normal;
            font-weight: 400;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            -webkit-font-smoothing: antialiased; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content .input-group-btn-vertical .fa-pts-chevron-down:before {
            content: "\F107"; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content .input-group-btn-vertical .fa-pts-chevron-up:before {
            content: "\F106"; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .text-sm-right {
          padding-right: 0; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .text-sm-right .product-price {
            color: #000;
            font-weight: 400;
            font-size: 14px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items {
        margin-top: 23px; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items .row {
          height: auto; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items .row span {
            color: #000;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
            display: inline-block; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items .total_price {
          margin-top: 16px; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items .total_price span {
            font-weight: 900; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed {
          border: none; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div {
            padding: 0;
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0; }
            #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div a {
              text-decoration: none;
              border: 1px solid #000;
              font-size: 14px;
              font-weight: 700;
              color: #000;
              padding: 13px 15px;
              display: inline-block;
              float: right;
              transition: 0.3s ease; }
              #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div a:hover {
                background-color: #000;
                color: #fff; }
            #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code {
              width: 100%;
              display: none;
              margin-top: 20px; }
              #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code.show, #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code.collapsing {
                display: inline-block; }
              #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code input {
                width: calc(100% - 115px); }
              #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code button {
                max-width: 110px;
                text-align: center;
                height: 50px;
                border: 1px solid #000;
                line-height: 32px;
                background-color: unset; }
                #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code button span {
                  font-weight: 700; }
                #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code button:hover {
                  background-color: #000; }
                  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code button:hover span {
                    color: #fff; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #div_leave_message {
        border: none;
        margin-top: 6px;
        padding-top: 0; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #div_leave_message #message {
          min-height: 90px;
          border-color: #00000033;
          border-radius: 0 !important;
          color: #000;
          font-size: 14px; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #div_leave_message #message:focus {
            box-shadow: unset; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #div_leave_message textarea::placeholder {
          color: #000;
          font-size: 14px;
          padding-top: 10px;
          padding-left: 12px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #conditions-to-approve {
        margin-top: 12px; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #conditions-to-approve label {
          color: #000;
          font-size: 14px;
          max-width: 500px;
          line-height: 20px;
          position: relative;
          padding-left: 35px; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #conditions-to-approve label a {
            text-decoration: none;
            font-weight: 900; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #conditions-to-approve input {
          border-radius: 0 !important;
          position: absolute;
          left: 0;
          top: 4px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #buttons_footer_review {
        margin-top: 39px; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #buttons_footer_review #btn_continue_shopping {
          display: none; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #buttons_footer_review #btn_place_order {
          font-size: 16px;
          font-weight: 700;
          color: #fff;
          text-transform: uppercase;
          height: auto;
          line-height: 42px;
          width: 100%;
          max-width: 350px;
          border-color: #5cb355;
          background: #5cb355; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #buttons_footer_review #btn_place_order i {
            display: none; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #cart-points-summary {
        margin-top: 0; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer {
    margin-top: 38px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer button {
      min-height: 55px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer button i {
        display: none; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer .card {
      border: none;
      background-color: #f2f2f2;
      margin-bottom: 29px;
      padding: 15px 12px;
      opacity: 1; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer .card-header {
      background-color: unset;
      border: none; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer .card-header a {
        font-weight: 900;
        font-size: 16px;
        color: #000; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #address_card_new .container_card {
    max-width: 260px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .edit_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .selected_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .delete_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .choose_address {
    background-color: unset;
    border: 1px solid #000000ed;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    height: auto;
    line-height: 35px;
    margin-top: 6px;
    opacity: 1;
    display: inline-block;
    width: calc(50% - 5px);
    transition: 0.3s ease; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .edit_address {
    float: left;
    margin-right: 5px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row .edit_address:hover {
      background-color: #000;
      color: #fff; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .selected_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .choose_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .delete_address {
    background-color: #000;
    color: #fff;
    float: right;
    line-height: 44px;
    margin-left: 5px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row .selected_address:hover,
    #checkout #wrapper #onepagecheckoutps_contenedor .row .choose_address:hover,
    #checkout #wrapper #onepagecheckoutps_contenedor .row .delete_address:hover {
      background-color: unset;
      color: #000; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .choose_address {
    width: 100%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .address_card {
    flex: 0 0 100%;
    max-width: 100%; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row .address_card .container_card {
      background-color: #dadada;
      border: none;
      border-radius: 0;
      font-size: 16px;
      padding: 13px;
      color: #000;
      width: 100%;
      display: inline-block; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row .address_card .container_card span {
        font-weight: 400; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row .address_card .container_card span i:before {
          font-size: 16px;
          position: relative;
          top: -2px;
          margin-right: 7px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .selected {
    background-color: unset;
    border: none; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row .selected .header_card {
      border-top: 1px solid #000;
      border-bottom: none;
      font-weight: 700;
      padding-top: 27px;
      padding-bottom: 20px;
      color: #000; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row .selected .header_card span {
        font-size: 16px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row .selected .content_card {
      height: auto !important; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row .selected .content_card ul {
        margin-bottom: 34px; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row .selected .content_card ul li {
          color: #000;
          font-weight: 400 !important;
          font-size: 16px; }
          #checkout #wrapper #onepagecheckoutps_contenedor .row .selected .content_card ul li i {
            display: none; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #btn_save_customer {
    background-color: unset;
    border: 1px solid #000000ed;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    height: auto;
    line-height: 35px;
    margin-top: 27px;
    transition: 0.3s ease; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #btn_save_customer:hover {
      background-color: #000;
      color: #fff; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #btn_save_customer i {
      display: none; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery,
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_update_address_delivery {
    background-color: unset;
    border: 1px solid #000000ed;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    height: auto;
    line-height: 35px;
    width: calc(100% - 10px);
    text-decoration: none;
    transition: 0.3s ease; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery {
    margin-right: 5px;
    height: 100%; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery:hover {
      background-color: #000;
      color: #fff; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery:hover i:before {
        color: #fff; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_update_address_delivery {
    background-color: #000;
    color: #fff;
    margin-left: 5px;
    float: right; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_update_address_delivery:hover {
      background-color: unset;
      color: #000; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery button {
    min-height: 55px; }
  #checkout #wrapper #onepagecheckoutps_contenedor h5.onepagecheckoutps_p_step {
    color: #000;
    font-size: 16px;
    font-weight: 900;
    border-bottom: none;
    margin-bottom: 13px !important; }
  #checkout #wrapper #onepagecheckoutps_contenedor input::placeholder {
    color: #000;
    font-size: 14px; }
  #checkout #wrapper #onepagecheckoutps_contenedor input, #checkout #wrapper #onepagecheckoutps_contenedor select {
    border-radius: 0 !important;
    padding: 17px 19px;
    color: #000; }

@media (max-width: 1200px) {
  #checkout #wrapper #onepagecheckoutps_contenedor .row .left_content {
    flex: 0 0 37%;
    max-width: 37%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .right_content {
    flex: 0 0 63%;
    max-width: 63%;
    padding-left: 30px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .delivery_radio_content {
    margin-right: 10px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content {
    padding: 8px 0px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content .delivery_option_logo .delivery_option_title {
      font-size: 14px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container label {
    margin-left: 16px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container #gift {
    margin-right: 15px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three_container {
    padding-left: 5px;
    padding-right: 5px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .payment-option {
    padding: 8px 0px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .payment-option .payment_input {
      margin-right: 10px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .payment-option span {
      font-size: 14px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .cart_description span {
    font-size: 13px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items .row span {
    font-size: 15px;
    margin-bottom: 6px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #div_leave_message textarea::placeholder {
    font-size: 13px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery,
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_update_address_delivery {
    width: calc(100% - 2px); }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery i,
    #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_update_address_delivery i {
      display: none; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery {
    margin-right: 2px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_update_address_delivery {
    margin-left: 2px; }
  #checkout #wrapper #onepagecheckoutps_contenedor input::placeholder {
    font-size: 13px; } }

@media (max-width: 992px) {
  #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login br {
    display: block; }
  #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login p {
    float: right;
    text-align: right;
    margin-top: 0;
    margin-bottom: 8px; }
  #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout {
    margin-top: 0; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .left_content {
    flex: 0 0 100%;
    max-width: 100%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .right_content {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px; } }

@media (max-width: 992px) and (min-width: 481px) {
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content {
    display: flex !important; } }

@media (max-width: 992px) {
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content .offset-1 {
    max-width: 58%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content .text-md-center {
    position: relative;
    left: 5%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #buttons_footer_review {
    margin-top: 17px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #btn_save_customer {
    max-width: 300px;
    margin: 27px auto; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery {
    flex-flow: row; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery div {
      flex: 0 0 50%;
      max-width: 50%; } }

@media (max-width: 768px) {
  #checkout #columns {
    padding-left: 5px;
    padding-right: 5px; }
  #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header {
    margin-top: 0;
    margin-bottom: 12px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header .row {
      flex-flow: column-reverse; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header div#div_onepagecheckoutps_info {
      flex: 0 0 100%;
      max-width: 100%; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header div#div_onepagecheckoutps_info h1 {
        margin-top: 15px;
        font-size: 18px; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header div#div_onepagecheckoutps_info p {
        font-size: 12px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #opc_show_login {
      float: left;
      max-width: 180px;
      margin-bottom: 8px;
      margin-left: 14px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #opc_show_login,
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout {
      font-size: 14px;
      height: auto;
      line-height: 27px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login {
      max-width: 100%; }
      #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login p {
        float: left;
        text-align: left;
        margin-bottom: 15px;
        font-size: 12px; }
        #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #div_onepagecheckoutps_login p a {
          font-size: 12px; }
    #checkout #wrapper div#onepagecheckoutps div#onepagecheckoutps_header #btn-logout {
      float: left;
      margin-top: 0px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one #form_customer {
    width: 95%;
    display: block;
    margin: auto; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice {
    margin-bottom: 10px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice > label {
      font-size: 14px;
      margin-bottom: 20px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content .delivery_option_logo {
    margin-right: 0; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container,
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three_container {
    flex: 0 0 50%;
    max-width: 50%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content .offset-1 {
    max-width: 60%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content .text-md-center {
    left: 4%;
    max-width: 23%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #header-order-detail-content .text-md-right {
    max-width: 17%;
    padding-right: 0;
    text-align: right; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .cart_description {
    flex: 0 0 50%;
    max-width: 50%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content {
    flex: 0 0 21.66667%;
    max-width: 21.66667%; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .text-sm-right {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .text-sm-right .total-price-text {
      display: none !important; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #div_leave_message textarea::placeholder {
    font-size: 11px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer {
    margin-top: 23px; }
  #checkout #wrapper #onepagecheckoutps_contenedor input::placeholder {
    font-size: 11px; } }

@media (max-width: 555px) {
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container {
    margin-bottom: 20px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container,
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three_container {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px; } }

@media (max-width: 480px) {
  #checkout #columns {
    padding-left: 0;
    padding-right: 0; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .right_content {
    padding-top: 0; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one label[for="checkbox_create_invoice_address"] {
    font-size: 13px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one label[for="checkbox_create_invoice_address"] input {
      margin-top: 3px !important; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_one div#field_customer_invoice {
    margin-top: 18px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content {
    padding: 5px 0px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_two_container .delivery-option .carrier-item-content .delivery_option_logo .delivery_option_title {
      font-size: 13px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .module_payment_container {
    padding: 0; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .payment-option {
    padding: 5px 0px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_three .payment-option span {
      font-size: 13px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #remaining_amount_free_shipping {
    margin-left: 0px;
    margin-right: 2px;
    padding: 4px;
    font-size: 12px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #remaining_amount_free_shipping .col-sm-2 {
      flex: 0 0 22%;
      max-width: 22%; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #remaining_amount_free_shipping .col-sm-8 {
      flex: 0 0 56%;
      max-width: 56%; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #remaining_amount_free_shipping #remaining_amount_free_shipping-text {
      font-size: 11px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #remaining_amount_free_shipping #remaining_amount_free_shipping-text span {
        font-size: 11px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review {
    border-top: 1px solid #0000001a; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .image_product {
      max-width: 20%;
      flex: 0 0 20%; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .cart_description {
      max-width: 80%;
      flex: 0 0 80%; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .cart_description span {
        font-size: 11px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .row.clear {
      display: none; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content {
      padding-left: 0;
      max-width: 93px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .quantity-content .remove-from-cart {
        position: relative;
        bottom: 12px;
        margin-left: 10px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .text-sm-right {
      flex: 0 0 100%;
      max-width: calc(100% - 100px); }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content > .row .text-sm-right .product-price {
        text-align: left !important;
        font-weight: 700; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items {
      margin-top: 12px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items .row span {
        font-size: 14px;
        margin-bottom: 3px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items .total_price {
        margin-top: 6px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div a {
        font-size: 13px;
        padding: 8px 9px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code input {
        padding: 10px; }
      #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code button {
        height: 39px;
        line-height: 21px; }
        #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #order-detail-content .order_total_items #list-voucher-allowed div .promo-code button span {
          font-size: 12px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #conditions-to-approve label {
      font-size: 11px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #HOOK_SHOPPING_CART {
      display: none; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #cart-points-summary .card {
      display: inline-block;
      font-size: 12px; }
    #checkout #wrapper #onepagecheckoutps_contenedor .row #onepagecheckoutps_step_review_container #onepagecheckoutps_step_review #cart-points-summary br {
      display: none; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer .card {
    padding: 10px 5px;
    margin-bottom: 14px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #panel_addresses_customer .card-header a {
    font-size: 14px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .edit_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .selected_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .delete_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .choose_address {
    font-size: 14px;
    line-height: 30px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row .selected_address,
  #checkout #wrapper #onepagecheckoutps_contenedor .row .choose_address {
    line-height: 37px; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #btn_save_customer {
    font-size: 14px;
    line-height: 30px;
    margin: 13px auto; }
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_cancel_address_delivery,
  #checkout #wrapper #onepagecheckoutps_contenedor .row #action_address_delivery #btn_update_address_delivery {
    font-size: 14px; }
  #checkout #wrapper #onepagecheckoutps_contenedor h5.onepagecheckoutps_p_step {
    font-size: 15px;
    margin-bottom: 5px !important; } }

#cms #page_banner_container_1 .container-fluid {
  max-width: 1300px; }

#cms #columns {
  max-width: 1300px; }

#cms h1 {
  color: #000;
  margin-bottom: 25px; }

#cms #content {
  color: #000;
  font-size: 14px; }

#contact #center_column {
  max-width: 1450px;
  margin: auto;
  padding-bottom: 30px; }
  #contact #center_column .title_block_inner {
    color: #000;
    border-bottom: 2px solid #000; }

#contact label {
  color: #000; }

#contact .btn-primary {
  background-color: #000;
  border-color: #000;
  height: auto;
  line-height: 27px;
  font-size: 14px; }

#contact #easycontent_17 {
  padding-left: 30px; }

#contact .contact-list {
  list-style: none; }
  #contact .contact-list a, #contact .contact-list li {
    font-size: 14px; }
  #contact .contact-list li {
    color: #000;
    font-weight: 300;
    margin-bottom: 2px; }
    @media (max-width: 480px) {
      #contact .contact-list li:nth-child(3) {
        float: left; }
        #contact .contact-list li:nth-child(3) strong {
          line-height: 26px;
          float: left; } }
    #contact .contact-list li strong {
      font-weight: 700;
      min-width: 185px;
      display: inline-block;
      padding: 5px 10px;
      background-color: #f3f3f3; }
    #contact .contact-list li a {
      color: #000;
      position: relative; }
      #contact .contact-list li a:hover {
        color: #808080; }

@media (max-width: 768px) {
  #contact #center_column {
    padding-bottom: 10px; }
  #contact #easycontent_17 {
    padding-left: 0;
    margin-top: 10px; }
  #contact .contact-list li {
    font-size: 12px; }
    #contact .contact-list li strong {
      min-width: 154px;
      padding: 5px;
      background-color: #f3f3f3; } }

#cookiesplus-modal {
  background-color: #e5e5e5; }
  #cookiesplus-modal .cookiesplus-info {
    text-align: center; }
  #cookiesplus-modal .cookiesplus-actions {
    float: none;
    max-width: 307px;
    margin: 0 auto;
    text-align: center; }

#cookiesplus-modal.cookiesplus-bottom.col-12 .float-xs-right {
  float: none !important; }

#cookiesplus-modal.cookiesplus-bottom.col-12 .cookiesplus-actions div:nth-child(-n+2) {
  float: right; }

.cms-id-12 .vc_col-sm-12.wpb_column {
  position: unset; }

#cms #wrapper .wpb_toggle {
  color: #fff;
  padding: 12px 20px;
  background: #000;
  margin-bottom: 0;
  border-bottom: 3px solid #f4f4f4;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  padding-right: 43px; }
  #cms #wrapper .wpb_toggle:before {
    content: "\E848";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-family: fontello;
    color: #fff;
    font-size: 12px;
    transition: 0.3s ease; }
  #cms #wrapper .wpb_toggle.wpb_toggle_title_active {
    color: #000;
    background-color: #d2d2d2; }
    #cms #wrapper .wpb_toggle.wpb_toggle_title_active:before {
      content: "\E84A";
      color: #000; }

#cms #wrapper .last_toggle_el_margin {
  display: none; }

#cms #wrapper .wpb_toggle_content {
  background-color: #f4f4f4;
  margin: 0;
  padding: 20px 15px;
  overflow-y: auto; }
  #cms #wrapper .wpb_toggle_content a {
    color: #000; }
    #cms #wrapper .wpb_toggle_content a:hover {
      color: #000; }

#authentication #wrapper #columns {
  max-width: 1406px;
  margin: auto; }

@media (min-width: 480px) {
  #authentication #wrapper #register_form_block .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
    #authentication #wrapper #register_form_block .col-lg-12:nth-child(5), #authentication #wrapper #register_form_block .col-lg-12:nth-child(6) {
      flex: 0 0 100%;
      max-width: 100%; }
    #authentication #wrapper #register_form_block .col-lg-12 .form-control {
      width: 100%;
      max-width: 100%; } }

@media (min-width: 769px) {
  #authentication .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (max-width: 580px) {
  #my-account .list-group-item {
    width: 100%; } }

#create_account_block .btn-primary {
  border: none;
  background-color: #808080; }

#login_form_block .form-control,
#login_form_block .input-group {
  max-width: 100%; }

#login_form_block .btn-primary {
  border: none;
  background-color: #000; }

@media (max-width: 768px) {
  body#authentication #wrapper {
    padding-top: 0; }
  #create_account_block .form_content {
    min-height: auto;
    margin-bottom: 20px; } }

.page-customer-account button {
  background-color: #000;
  border: none; }

.quickview #product-description-short {
  display: none; }

.quickview .pro_cart_right {
  display: none; }

.quickview .product_name {
  font-size: 26px;
  font-weight: 300;
  color: #000; }

.quickview .price {
  font-size: 30px;
  font-weight: 700;
  color: #000; }

.quickview .regular-price {
  margin-left: 10px;
  font-size: 20px;
  color: #a3a2a2;
  font-weight: 300; }

.quickview .product-variants .li_fl {
  float: left; }

.quickview .product-variants .radio-label {
  border: 2px solid #fff;
  padding: 3px;
  line-height: 29px; }

.quickview .product-variants .input-radio:checked + span {
  border-color: #000; }

.quickview .product-variants input:hover + span {
  border-color: #000;
  line-height: 29px; }

.quickview .product-variants .color {
  border: none; }

@media (max-width: 768px) {
  .quickview .st_modal_close {
    right: 0;
    z-index: 999;
    background-color: #000; }
    .quickview .st_modal_close span {
      color: #fff; }
  .quickview .modal-dialog {
    margin-left: 10px;
    margin-right: 10px; }
  .quickview .modal-content {
    width: 100%;
    margin-left: 0;
    padding-top: 30px; }
  .quickview .product-add-to-cart {
    margin-top: 30px; }
    .quickview .product-add-to-cart .pro_cart_block {
      flex-flow: row; }
    .quickview .product-add-to-cart .pro_cart_right {
      width: calc(12.5% - 6px);
      margin-left: 6px; }
    .quickview .product-add-to-cart .qty {
      display: inline-block;
      width: 87px;
      margin-right: 2px; }
      .quickview .product-add-to-cart .qty button,
      .quickview .product-add-to-cart .qty input {
        height: 50px !important; }
      .quickview .product-add-to-cart .qty input {
        font-size: 14px; }
      .quickview .product-add-to-cart .qty button {
        width: 30px;
        font-size: 0; }
    .quickview .product-add-to-cart .add {
      width: calc(100% - 94px);
      display: inline-block; }
      .quickview .product-add-to-cart .add button {
        height: 54px;
        padding: 10px 6px; }
        .quickview .product-add-to-cart .add button span {
          font-size: 11px; }
    .quickview .product-add-to-cart .add_to_wishlit {
      left: 3px; } }

#stores #columns {
  color: #000; }

#stores #center_column {
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;
  max-width: 1440px; }

#stores .page_heading {
  margin-bottom: 40px;
  color: #000;
  border-bottom: 4px solid #000;
  display: inline-block; }

@media (min-width: 768px) {
  #stores .store-description {
    max-width: 30.66667%; } }

@media (max-width: 768px) {
  #stores .store-description {
    margin-bottom: 15px; } }

#stores .store-description .page_heading {
  margin-bottom: 10px; }

#stores .store-description a {
  background-color: #000;
  color: #fff;
  padding: 10px;
  transition: 0.3s ease;
  font-size: 14px; }
  #stores .store-description a:hover {
    background-color: #808080;
    color: #000; }

#stores .store-item-footer {
  background-color: #f5f7f6;
  padding: 10px;
  box-shadow: 0 0 10px 3px #00000036; }

#stores .heading_color {
  color: #000;
  font-weight: 700;
  margin-right: 15px;
  min-width: 93px; }

#stores .flex_container:last-child .flex_child {
  color: red;
  font-weight: 700; }

#order-confirmation #wrapper * {
  color: #000; }

#order-confirmation #wrapper .price {
  font-size: 16px; }

#order-confirmation #wrapper .btn-default {
  background-color: #000;
  border-color: #000;
  color: #fff !important; }

@media (max-width: 768px) {
  #order-confirmation #wrapper * {
    font-size: 14px; } }

#guest-tracking #columns {
  max-width: 1440px;
  margin: auto;
  color: #000;
  padding-bottom: 30px; }

#guest-tracking h3 {
  color: #000; }

#guest-tracking button {
  min-width: 150px;
  background-color: #000; }
