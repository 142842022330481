// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-name: 'arrow';
$arrow-x: 63px;
$arrow-y: 65px;
$arrow-offset-x: -63px;
$arrow-offset-y: -65px;
$arrow-width: 23px;
$arrow-height: 13px;
$arrow-total-width: 143px;
$arrow-total-height: 142px;
$arrow-image: '../img/sprite.png';
$arrow: (63px, 65px, -63px, -65px, 23px, 13px, 143px, 142px, '../img/sprite.png', 'arrow', );
$cart-btn-name: 'cart-btn';
$cart-btn-x: 126px;
$cart-btn-y: 0px;
$cart-btn-offset-x: -126px;
$cart-btn-offset-y: 0px;
$cart-btn-width: 17px;
$cart-btn-height: 18px;
$cart-btn-total-width: 143px;
$cart-btn-total-height: 142px;
$cart-btn-image: '../img/sprite.png';
$cart-btn: (126px, 0px, -126px, 0px, 17px, 18px, 143px, 142px, '../img/sprite.png', 'cart-btn', );
$cart-name: 'cart';
$cart-x: 0px;
$cart-y: 0px;
$cart-offset-x: 0px;
$cart-offset-y: 0px;
$cart-width: 24px;
$cart-height: 25px;
$cart-total-width: 143px;
$cart-total-height: 142px;
$cart-image: '../img/sprite.png';
$cart: (0px, 0px, 0px, 0px, 24px, 25px, 143px, 142px, '../img/sprite.png', 'cart', );
$fb-name: 'fb';
$fb-x: 126px;
$fb-y: 58px;
$fb-offset-x: -126px;
$fb-offset-y: -58px;
$fb-width: 16px;
$fb-height: 16px;
$fb-total-width: 143px;
$fb-total-height: 142px;
$fb-image: '../img/sprite.png';
$fb: (126px, 58px, -126px, -58px, 16px, 16px, 143px, 142px, '../img/sprite.png', 'fb', );
$heart-name: 'heart';
$heart-x: 0px;
$heart-y: 65px;
$heart-offset-x: 0px;
$heart-offset-y: -65px;
$heart-width: 23px;
$heart-height: 21px;
$heart-total-width: 143px;
$heart-total-height: 142px;
$heart-image: '../img/sprite.png';
$heart: (0px, 65px, 0px, -65px, 23px, 21px, 143px, 142px, '../img/sprite.png', 'heart', );
$insta-name: 'insta';
$insta-x: 0px;
$insta-y: 126px;
$insta-offset-x: 0px;
$insta-offset-y: -126px;
$insta-width: 16px;
$insta-height: 16px;
$insta-total-width: 143px;
$insta-total-height: 142px;
$insta-image: '../img/sprite.png';
$insta: (0px, 126px, 0px, -126px, 16px, 16px, 143px, 142px, '../img/sprite.png', 'insta', );
$love-name: 'love';
$love-x: 56px;
$love-y: 126px;
$love-offset-x: -56px;
$love-offset-y: -126px;
$love-width: 17px;
$love-height: 15px;
$love-total-width: 143px;
$love-total-height: 142px;
$love-image: '../img/sprite.png';
$love: (56px, 126px, -56px, -126px, 17px, 15px, 143px, 142px, '../img/sprite.png', 'love', );
$twt-name: 'twt';
$twt-x: 113px;
$twt-y: 126px;
$twt-offset-x: -113px;
$twt-offset-y: -126px;
$twt-width: 15px;
$twt-height: 14px;
$twt-total-width: 143px;
$twt-total-height: 142px;
$twt-image: '../img/sprite.png';
$twt: (113px, 126px, -113px, -126px, 15px, 14px, 143px, 142px, '../img/sprite.png', 'twt', );
$user-name: 'user';
$user-x: 64px;
$user-y: 0px;
$user-offset-x: -64px;
$user-offset-y: 0px;
$user-width: 22px;
$user-height: 22px;
$user-total-width: 143px;
$user-total-height: 142px;
$user-image: '../img/sprite.png';
$user: (64px, 0px, -64px, 0px, 22px, 22px, 143px, 142px, '../img/sprite.png', 'user', );
$spritesheet-width: 143px;
$spritesheet-height: 142px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($arrow, $cart-btn, $cart, $fb, $heart, $insta, $love, $twt, $user, );
$spritesheet: (143px, 142px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
