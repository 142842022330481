@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Thin.otf);
  font-weight: 100;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Light.otf);
  font-weight: 200;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Book.otf);
  font-weight: 300;
}

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Medium.otf);
  font-weight: 400;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-MediumItalic.otf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/GothamGreek-Ultra.otf);
  font-weight: 900;
}


