#lrp-summary{
  color: #000;
  position: relative;
  border: 2px dashed $black;
  padding: 8px 13px 8px 37px;
  display: inline-block;
  margin-bottom: 10px;
  a{
    color: #2eb841;
  }
  i{
    display: none;
  }
}
#lrp-redeem-form{
  margin-bottom: 10px;
  .points-label{
    margin-left: 10px;
  }
  #btn-lrp-redeem{
    color: #fff;
    background-color: $black;
    height: 40px;
    line-height: 26px;
  }
}
#cart{
  #center_column{
    padding-bottom: 20px;
    max-width: 1700px;
    margin: auto;
    .cart-grid-body{
      .card{
        order: 1;
        border: none;
        .card-header{
          font-size: 24px;
          color: #000;
          font-weight: 700;
          border-color: #dfdfdf;
        }
        .cart-items{
          margin-top: 0;
          .cart-item{
            padding-top: 10px;
            padding-bottom: 10px;
            border-color: #dfdfdf;
            &:last-child{
              border-bottom: 1px solid #dfdfdf;
            }
            .product-line-grid-body{
              padding-top: 27px;
              padding-bottom: 24px;
              .label{
                font-weight: 700;
                color: #000;
              }
              .product-line-info{
                .product-discount{
                  display: inline-block;
                  .discount-percentage{
                    display: none;
                  }
                }
                .current-price{
                  display: inline-block;
                  .price{
                    font-size: 15px;
                  }
                  .regular-price{
                    font-size: 15px;
                  }
                }
                &.product-price{
                  display: block;
                  margin-bottom: 18px;
                }
                &:not(.product-price){
                  display: inline-block;
                  .value{
                    font-weight: 700;
                    font-size: 15px;
                  }
                }
                &:first-child{
                  .label{
                    margin-bottom: 20px;
                    display: block;
                    font-size: 15px;
                  }
                }
                &:nth-child(n + 3){
                  .label{
                    display: none;
                  }
                }
              }
            }
            .product-line-grid-right{
              > .row{
                @media(min-width: 768px){
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 6%;
                  width: 100%;
                }
              }
              .qty{

                .qty_wrap{
                  width: 100%;
                  max-width: 110px;
                  .input-group{
                    width: 100%;
                    border: 1px solid #dfdfdf;
                    .input-group-btn{
                      &:first-child{
                        &:before{
                          content:"";
                          position: absolute;
                          display: inline-block;
                          width: 12px;
                          height: 2px;
                          z-index: 99;
                          background-color: #ccc;
                          left: 50%;
                          top:50%;
                          transform: translate(-50%,-50%);
                        }
                        button{
                          color: transparent;
                        }
                      }
                    }
                    input,
                    button{
                      height: 43px;
                      border: none;
                      &:hover{
                        background: unset;
                      }
                    }
                    button{
                      color: #cccccc;
                      width: 37px;
                      font-size: 20px;
                    }
                    input{
                      font-weight: 700;
                      color: #000;
                      font-size: 15px;
                    }
                  }
                }
              }
              .col-md-6{
                text-align: center;
              }
              .product-price{
                font-weight: 700;
                font-size: 15px;
                line-height: 45px;
              }
              .remove-from-cart{
                i{
                  &:before{
                    content: '';
                    position: absolute;
                    border: none;
                    left: 6px;
                    width: 21px;
                    height: 1px;
                    top: 19px;
                    border-bottom: 3px solid #000;
                    border-radius: 0;
                    transform: rotate(-45deg);
                  }
                  &:after{
                    content: '';
                    position: absolute;
                    left: 6px;
                    width: 21px;
                    height: 1px;
                    top: 19px;
                    border-bottom: 3px solid #000;
                    transform: rotate(45deg);
                  }
                }
              }
            }
          }
        }
      }
      .btn-default{
        order: 3;
        background-color: #fff;
        color: #000;
        border: 1px solid #e1e1e1;
        font-weight: 700;
        padding: 15px 58px;
        height: auto;
        &:hover{
          border-color: #000;
        }
        i{
          display: none;
        }
      }
    }
    .cart-grid-right{
      margin-top: 11px;
      .card-header-right{
        font-size: 24px;
        color: #000;
        font-weight: 700;
        margin-bottom: 12px;
        display: block;
      }
      .card{
        border: none;
        .cart-detailed-totals{
          background-color: #f0f0f0;
          padding: 21px 4px;
          padding-bottom: 4px;
          .card-block{
            padding-bottom: 0;
            padding-top: 0;
            hr{
              display: none;
            }
            .cart-summary-line{
              margin-bottom: 15px;
              .label,
              .value {
                font-weight: 400;
                font-size: 16px;
              }
              &.cart-total{
                .label,
                .value {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
      .cart-detailed-actions{
        padding: 0;
        margin-top: 15px;
        .btn-default{
          height: 57px;
          line-height: 41px;
          font-size: 16px;
          border-color: $black;
          background-color: $black;
          @extend .shine-hover;
        }
      }
    }
  }
}
@media(max-width: 1200px){
  #cart{
    #center_column{
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      .cart-grid-body{
        .card{

          .card-header{

          }
          .cart-items{

            .cart-item{

              &:last-child{

              }
              .product-line-grid-body{

                .label{

                }
                .product-line-info{
                  .product-discount{

                    .discount-percentage{

                    }
                  }
                  .current-price{

                    .price{

                    }
                    .regular-price{

                    }
                  }
                  &.product-price{

                  }
                  &:not(.product-price){

                    .value{

                    }
                  }
                  &:first-child{
                    .label{


                    }
                  }
                  &:nth-child(n + 3){
                    .label{

                    }
                  }
                }
              }
              .product-line-grid-right{
                > .row{


                }
                .qty{

                  .qty_wrap{

                    .input-group{

                      .input-group-btn{
                        &:first-child{
                          &:before{

                          }
                          button{

                          }
                        }
                      }
                      input,
                      button{

                        &:hover{

                        }
                      }
                      button{

                      }
                      input{

                      }
                    }
                  }
                }
                .col-md-6{

                }
                .product-price{

                }
                .remove-from-cart{
                  i{
                    &:before{

                    }
                    &:after{

                    }
                  }
                }
              }
            }
          }
        }
        .btn-default{

          &:hover{

          }
          i{

          }
        }
      }
      .cart-grid-right{

        .card-header-right{

        }
        .card{

          .cart-detailed-totals{

            .card-block{

              hr{

              }
              .cart-summary-line{

                .label,
                .value {
                  font-size: 15px;
                }
                &.cart-total{
                  .label,
                  .value {

                  }
                }
              }
            }
          }
        }
        .cart-detailed-actions{

          .btn-default{

          }
        }
      }
    }
  }
}
@media(max-width: 768px){
  #cart{
    #center_column{
      padding-top: 0;
      padding-bottom: 0px;
      .cart-grid-body{
        margin-bottom: 0!important;
        picture{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          left: 0;
        }
        .card{
          margin-bottom: 0!important;
          .card-header{
            padding-top: 0;
          }
          .cart-items{
            padding-bottom: 0;
            .cart-item{

              &:last-child{

              }
              .product-line-grid-left{
                position: relative;
                flex: 0 0 29%;
                max-width: 29%
              }
              .product-line-grid-body{
                flex: 0 0 71%;
                max-width: 71%;
                @media(max-width: 440px){
                  padding-top: 0;
                }

                .label{
                  font-size: 14px;
                }
                .product-line-info{
                  .product-discount{

                    .discount-percentage{

                    }
                  }
                  .current-price{

                    .price{

                    }
                    .regular-price{

                    }
                  }
                  &.product-price{
                    @media(max-width: 440px){
                      margin-bottom: 2px;
                    }

                  }
                  &:not(.product-price){

                    .value{

                    }
                  }
                  &:first-child{
                    .label{
                      font-size: 14px;
                      @media(max-width: 440px){
                        margin-bottom: 8px;
                      }
                    }
                  }
                  &:nth-child(n + 3){
                    .label{

                    }
                  }
                }
              }
              .product-line-grid-right{
                @media(max-width: 440px){
                  margin-top: 20px;
                  margin-bottom: 10px;
                }
                .hidden-md-up{
                  @media(max-width: 440px){
                    display: none;
                  }
                }
                .col-md-10.col-7{
                  @media(max-width: 440px){
                    flex: 0 0 80.33333%;
                    max-width: 80.33333%;
                  }
                }
                > .row{


                }
                .qty{

                  .qty_wrap{

                    .input-group{

                      .input-group-btn{
                        &:first-child{
                          &:before{

                          }
                          button{

                          }
                        }
                      }
                      input,
                      button{

                        &:hover{

                        }
                      }
                      button{
                        width: 28px;
                      }
                      input{

                      }
                    }
                  }
                }
                .col-md-6{
                  min-width: 120px;
                }
                .product-price{

                }
                .remove-from-cart{
                  i{
                    &:before{

                    }
                    &:after{

                    }
                  }
                }
              }
            }
          }
        }
        .btn-default{
          display: none;
          &:hover{

          }
          i{

          }
        }
      }
      .cart-grid-right{

        .card-header-right{

        }
        .card{

          .cart-detailed-totals{

            .card-block{

              hr{

              }
              .cart-summary-line{

                .label,
                .value {
                  font-size: 15px;
                }
                &.cart-total{
                  .label,
                  .value {

                  }
                }
              }
            }
          }
        }
        .cart-detailed-actions{

          .btn-default{

          }
        }
      }
    }
  }
}