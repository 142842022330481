//#st_menu_7{
//  .stmenu_sub{
//    display: block!important;
//  }
//}
.stmenu_sub{
  min-width: 1300px;
  @media(max-width: 1300px){
    min-width: 1100px;
  }
  @media(max-width: 1100px){
    min-width: 992px;
  }
}
#st_menu_8{
  .stmenu_sub{
    top: 82px;
    background-color: #1d1c1c;
    #st_menu_column_6{
      padding: 0;
      max-width: 1264px;
      margin: auto;
    }
    #st_menu_block_11{
      width: 100%;
      img{
        background-color: #fff;
      }
      .col-md-2{
        max-width: calc(16.66667% - 4px);
        margin-bottom: 19px;
        padding: 0 8px;
        text-align: center;
        display: inline-block;
      }
    }
  }
}
#st_mega_menu_header_container{
  #st_mega_menu_wrap{
    .stmenu_sub{
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.stmenu_sub{
  top: 80px;
  min-width: 1161px;
  .ml_level_1{
    margin-bottom: 25px;
  }
  .ma_level_1{
    font-size: 14px;
    color: $black;
    border-bottom: 1px solid $black;
    padding-bottom: 6px;
  }
  .ma_level_2{
    font-size: 13px;
    color: $black;
    margin-bottom: 5px;
    &:hover{
      color: $gray;
    }
    i{
      display: none;
    }
  }
}
@media(max-width: 1300px){
  .stmenu_sub{
    min-width: 990px;
    .ml_level_1{
      margin-bottom: 15px;
    }
    .ma_level_1{
      font-size: 12px;
    }
    .ma_level_2{
      font-size: 11px;
    }
  }
}
