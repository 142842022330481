[class^="module-stblog"]{
  #page_banner_container_1{
    margin-top: 6px;
  }
  &.show-left-column{
    #columns{
      position: relative;
      max-width: 1540px;
      padding-bottom: 0;
      .blog_content{
        color: $black;
        p{
          img{
            max-width: 500px;
            object-fit: cover;
            margin: 0 10px;
          }
        }
        h3{
          margin-top: 25px;
          font-size: 22px;
        }
      }
      h1{
        color: $black;
        font-weight: 700;
      }
      #center_column{
        @media(min-width: $presta-tablet){
          padding-left: 100px;
          flex: 0 0 75%;
          max-width: 75%;
        }
      }
      #left_column{
        @media(min-width:$presta-tablet){
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
    .blog_image{
      text-align: center;
      margin-bottom: 20px;
    }
    #blog_link_nav{
      .fl{
        background: $black;
        color: #fff;
        padding: 6px 10px;
        transition: 0.3s ease;
        &:hover{
          background: $gray;
        }
      }
      .fr{
        background: $gray;
        color: #fff;
        padding: 6px 10px;
        transition: 0.3s ease;
        &:hover{
          background: $black;
        }
      }
    }
    #st_blog_comment_reply_block{
      #stblog_leave_a_comment{
        color: $black;
      }
      .st_blog_comment_reply{
        color: $black;
        .go{
          color: $black;
          background-color: unset;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
    #left_column{
      padding: 0;
      .wrapper-sticky{
        background-color: #f7f7f7;
        padding: 20px 15px;
        box-shadow: 0 0px 11px 2px #00000036;
      }
      .column_block{
        border-bottom: 1px solid #dddddd;
        padding-bottom: 7px;
        margin-top: 10px;
      }
      .input-group{
        border: none;
        box-shadow: 0 0px 21px 1px #00000021;
        input{
          height: 42px;
          padding-left: 25px;
        }
        input::placeholder{
          color: $black;
        }
        .input-group-btn{
          height: 34px;
          margin-top: 4px;
          padding-right: 6px;
          button{
            background-color: $black;
            width: 60px;
            padding-left: 15px;
            padding-right: 15px;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            border:none;
          }
        }
      }
      .title_block_inner{
        color: #272727;
        font-size: 16px;
        padding: 3px 0;
        line-height: 20px;
        margin-bottom: 4px;
      }
      .block_content{
        padding: 3px 0;
      }
      .category-sub-menu{
        a{
          font-size: 16px;
          padding: 4px 0;
        }
      }
    }
  }
}
@media(max-width: 1340px){
  [class^="module-stblog"]{
    &.show-left-column{
      #columns{

      }
      #left_column{
        .column_block{

        }
        .input-group{

          button{

          }
        }
        .title_block_inner{

          font-size: 20px;
        }
        .category-sub-menu{
          a{
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media(max-width: $presta-tablet){
  [class^="module-stblog"]{
    #center_column{
      padding-left: 15px;
    }
  }
  #sidebar_box{
    display: none;
  }
}