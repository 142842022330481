#st_news_letter_3{
  background-color: #f2f2f2;
  .st_news_letter_do_not_show_outer{
    padding: 0;
    .st_news_letter_do_not_show_inner{
      background-color: $black;
      padding: 5px 10px;
      font-size: 13px;
      color: #fff;
      margin-top: 15px;
    }
    input{
      position: relative;
      top: 1px;
    }
  }
}
#st_news_letter_popup_3{
  .close{
    background-color: $black;
    span{
      color: #fff;
    }
  }
  h3{
    font-size: 26px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
    &:before{
      content: "";
      position: absolute;
      display: inline-block;
      height: 4px;
      width: 25%;
      background-color: $black;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
  .modal-dialog{
    max-width: 650px;
    width: 100%;
    @media(min-width: 660px){
      min-width: 650px;
    }
    top: 50%;
    @media(min-width: 540px){
      position: absolute;
      left: 50%;
      transform: translate(-50%,-95%);
    }
  }
  .st_news_letter_content{
    padding: 25px 30px 15px 30px;
    color: $black;
  }
  .wrap{
    background-color: #fff;
    padding: 20px;
    max-width: 408px;
    margin: auto;
    margin-top: 23px;
  }
  p{
    font-size: 14px;
  }
  ul{
    text-align: left;
    max-width: 408px;
    margin: auto;
    li{
      padding-left: 20px;
      position: relative;
      font-size: 14px;
      &:before{
        content: "\E80F";
        font-family: fontello;
        position: absolute;
        left: 0;
        top: 0;
        color: $black;
        font-size: 14px;
      }
    }
  }
}
@media(max-width: 540px){
  #st_news_letter_3{

    .st_news_letter_do_not_show_outer{

      .st_news_letter_do_not_show_inner{
        margin-top: 6px;
        padding: 2px 7px 6px 7px;
        font-size: 11px;
      }
      input{

      }
    }
  }
  #st_news_letter_popup_3{
    .close{
      right: 0;
      z-index: 999;
      span{

      }
    }
    h3{
      font-size: 19px;
      margin-bottom: 23px;
      &:before{
        height: 3px;
      }
    }
    .modal-content{
      margin-left: 0;
      margin-right: 0;
    }
    .modal-dialog{
      margin: 10px;
      width: calc(100% - 20px);
      transform: translateY(-50%);
    }
    .st_news_letter_content{
      padding: 33px 15px 15px 15px;
    }
    .wrap{
      padding: 14px 10px;
    }
    p{
      font-size: 12px;
    }
    ul{

      li{
        padding-left: 16px;
        font-size: 12px;
        &:before{

        }
      }
    }
  }
}