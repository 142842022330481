#cms{
  #page_banner_container_1{
    .container-fluid{
      max-width: 1300px;
    }
  }
  #columns{
    max-width: 1300px;
  }
  h1{
    color: $black;
    margin-bottom: 25px;
  }
  #content{
    color: $black;
    font-size: 14px;
  }
}