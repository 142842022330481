#manufacturer{
  #left_column{
    display: none;
  }
  #center_column{
    flex: 0 0 100%;
    max-width: 100%;
  }
  #sidebar_box{
    display: none;
  }
  #columns{
    max-width: 1440px;
    margin: auto;
    padding-bottom: 15px;
    h1{
      color: $black;
      font-weight: 900;
      font-size: 18px;
    }
    .brand{
      background-color: #f9f9f9;
      border: 1px dashed $black;
      padding: 10px;
      .brand-img{
        img{
          max-height: 160px;
          object-fit: cover;
        }
      }
      @media(min-width: 1050px){
        width: calc(33.3% - 10px);
        float: left;
        margin: 5px;
        min-height: 130px;
      }
      @media(max-width: 1049px) and (min-width: $mobile){
      width: calc(50% - 10px);
      float: left;
      margin: 5px;
      min-height: 130px;

      }
      a{
        color: $black;
        font-weight: 900;
      }
      .go{
        display: block;
        margin-top: 10px;
        color: #fff;
        background-color: $black;
        padding: 10px;
        text-decoration: none;
        max-width: 178px;
        text-align: center;
        font-size: 14px;
        @extend .shine-hover;
      }
    }
  }
}
@media(max-width: 480px){
  #manufacturer{
    #left_column{

    }
    #center_column{

    }
    #sidebar_box{

    }
    #columns{

      h1{

      }
      .brand{
        flex-flow: column;
        max-width: 294px;
        margin: auto;
        margin-bottom: 15px;
        .flex_child{
          margin: auto;
        }
        .brand-img{
          margin: auto!important;
          img{

          }
        }


        a{

        }
        .go{

        }
      }
    }
  }
}