#blockcart-modal{
  .title_block_inner{
    color: $black;
  }
  .swiper-button-tr{
    i{
      background-color: $black;
    }
  }
  .alert-success{
    background-color: unset;
    color: #2eb841;
    font-weight: 700;
    border: 1px solid #2eb841;
    text-align: center;
    padding: 7px;
    font-size: 15px;
  }
  .st_modal_close{
    background-color: $black;
    span{
      color: #fff;
      background-color: $black;
      font-weight: 700;
    }
  }
  .product_name{
    color: $black;
    margin-bottom: 10px;
    font-size: 15px;
  }
  .list_detail_item{
    color: $black;
    li{
      font-weight: 300;
    }
    span{
      font-weight: 300;
      margin-right: 8px;
      font-size: 15px;
      min-width: 164px;
      display: inline-block;
    }
    &.m-b-1{
      span{
        min-width: unset;
      }
    }
  }
  .cart-products-count{
    color: $black;
    margin-bottom: 3px;
  }
  .cart-content-btn{
    padding-left: 5px;
    padding-right: 5px;
    .col-md-6{
      padding-right: 5px;
      padding-left: 5px;
      button,
      a{
        background-color: #dedede;
        color: #000;
        border-color: $black;
        height: auto;
        line-height: 27px;
        font-size: 14px;
        border: none;
        @extend .shine-hover;
      }
      a{
        background-color: $black;
        color: #fff;

      }
    }
  }
  .modal_cart_details{
    color:$black;
  }
}
#header_right{
  .small_cart_info{
    a{
      color: $black;
      font-weight: 700;
      font-size: 12px;
    }
    .price{
      font-size: 15px;
      color: $black;
    }
    .small_cart_attr_attr{
      font-size: 12px;
      margin-right: 10px;
      color: $black;
      span{
        display: inline-block;
        float: left;
      }
    }
  }
  .small_cart_sumary{
    color: $black;
    font-weight: 700;
  }
  .cart-summary-v{
    color: $black;
    font-size: 13px;
    font-weight: 700;
  }
  .cart-summary-k{
    font-size: 13px;
  }
  .small_cart_btn{
    border: none;
    line-height: 34px;
    font-size: 16px;
    background: $black;
    @extend .shine-hover;
  }
}
@media(max-width: $mobile){
  #blockcart-modal{
    .modal-content{
      width: 100%;
      margin: 0;
    }
    .modal-dialog{
      width: 100%;
      padding: 10px;
    }
    .alert-success{

    }
    .st_modal_close{
      top: 0px;
      right: 0px;
      z-index: 9;
      width: 32px;
      height: 32px;
      span{
        top: 1px;
        position: relative;
      }
    }
    .product_name{

    }
    .list_detail_item{

      span{

      }
      &.m-b-1{
        span{

        }
      }
    }
    .cart-products-count{

    }
    .cart-content-btn{

      .col-md-6{

        button,
        a{

        }
        a{


        }
      }
    }
    .modal_cart_details{

    }
  }
}