#page_wrapper{
  .autocomplete-suggestion{
    .search_product_price{
      font-size: 13px;
    }
  }
}
.autocomplete-suggestion{
  .search_product_name{
    font-size: 13px;
    color: $black;
  }
  a{
    &:hover{
      .search_product_name{
        color: $black;
      }
    }
  }
}
.autocomplete-suggestions{
  .search_more_products{
    background-color: $black;
    color: #fff;
    text-decoration: none;
  }
}
