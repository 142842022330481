#men-jacket{
  #st_banner_block_9{
    overflow: visible;
    width: 100%;
    display: inline-block;
  }
  .st_image_layered_description_inner{
    position: absolute;
    left: -6%;
    top: 22%;
  }
  h6{
    margin-bottom: 70px;
    padding-left: 37px;
  }
  h5{
    text-align: left;
    line-height: 48px;
    margin-bottom: 0;
    &:before{
      right: 0;
      width: 70px;
      top: 32px;
      height: 5px;
    }
  }
  #banner_box_12{
    img{
      float: left;
      margin-top: 145px;
    }
  }
  #banner_box_13{
    img{
      float: right;
    }
  }
}
@media(max-width: $desktop){
  #men-jacket{
    #st_banner_block_9{

    }
    .st_image_layered_description_inner{
      left: -116px;
    }
    h5{
      line-height: 45.93px;
      padding-right: 68px;
      &:before{
        width: 50px;
        top: 22px;
      }
    }
    #banner_box_12{
      img{

      }
    }
    #banner_box_13{
      padding-left: 80px;
      img{

      }
    }
  }
}
@media(max-width: $tablet){
  #men-jacket{
    #st_banner_block_9{

    }
    .st_image_layered_description_inner{
      left: -95px;
    }
    h5{

      &:before{

      }
    }
    #banner_box_12{
      flex: 0 0 27.33333%;
      max-width: 27.33333%;
      img{
        margin-top: 84px;
      }
    }
    #banner_box_13{
      padding-left: 108px;
      flex: 0 0 72.66667%;
      max-width: 72.66667%;
      img{

      }
    }
  }
}
@media(max-width: $presta-tablet){
  #men-jacket{
    #st_banner_block_9{

    }
    .st_image_layered_description_inner{

    }
    h6{
      margin-bottom: 22px;
    }
    h5{
      line-height: 35.93px;
      &:before{

      }
    }
    #banner_box_12{
      flex: 0 0 31.33333%;
      max-width: 31.33333%;
      img{
        margin-top: 13px;
      }
    }
    #banner_box_13{
      flex: 0 0 68.66667%;
      max-width: 68.66667%;
      img{

      }
    }
  }
}
@media(max-width: $mobile){
  #men-jacket{
    .wpb_column{
      padding: 0 5px;
    }
    #st_banner_block_9{

    }
    .st_banner_11{
      margin-bottom: 12px;
    }
    .st_image_layered_description_inner{
      left: -73px;
      top: 15%;
    }
    h5{



      &:before{

      }
    }
    #banner_box_12{
      display: none;
      img{

      }
    }
    #banner_box_13{
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 76px;
      img{

      }
    }
  }
}
@media(max-width: 480px){
  #men-jacket{
    .wpb_column{

    }
    #st_banner_block_9{

    }
    .st_banner_11{

    }
    .st_image_layered_description_inner{

    }
    h5{
      line-height: 23.93px;

      padding-right: 35px;
      &:before{
        width: 27px;
        top: 10px;
      }
    }
    #banner_box_12{

      img{

      }
    }
    #banner_box_13{

      img{

      }
    }
  }
}