#new-prod{
  margin-top: 88px;
  .swiper-button-next{
    right: 7%;
  }
  .swiper-button-prev{
    left: 11%;
  }
  .ctitle{
    margin-bottom: 42px;
  }
}
@media(max-width: $presta-tablet){
  #new-products{
    margin-top:15px;
    .sthomenew_container{
      margin-bottom: 15px;
    }
    .swiper-button-next{

    }
    .swiper-button-prev{

    }
    .ctitle{

    }
  }
}
@media(max-width: $mobile){
  #new-prod{
    margin-top:0px;
    .sthomenew_container{
      padding: 0 20px;
    }
    .swiper-button-next{

    }
    .swiper-button-prev{

    }
    .ctitle{
      margin-bottom: 35px;
    }
  }
}