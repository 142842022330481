#contact{
  #center_column{
    max-width: 1450px;
    margin: auto;
    padding-bottom: 30px;
    .title_block_inner{
      color: $black;
      border-bottom: 2px solid $black;
    }
  }

  .contact-form{

  }
  label{
    color: $black;
  }
  .btn-primary{
    background-color: $black;
    border-color: $black;
    height: auto;
    line-height: 27px;
    font-size: 14px;
    @extend .shine-hover;
  }
  #easycontent_17{
    padding-left: 30px;
  }
  .contact-list{
    list-style: none;
    a,li{
      font-size: 14px;
    }
    li{
      color: $black;
      font-weight: 300;
      margin-bottom: 2px;
      @media(max-width: 480px){
        &:nth-child(3){
          float: left;
          strong{
            line-height: 26px;
            float: left;
          }
        }
      }
      strong{
        font-weight: 700;
        min-width: 185px;
        display: inline-block;
        padding: 5px 10px;
        background-color: #f3f3f3;
      }
      a{
        color: $black;
        position: relative;
        &:hover{
          color: $gray;
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #contact{
    #center_column{
      padding-bottom: 10px;
    }
    .title_block_inner{

    }
    .contact-form{

    }
    label{

    }
    .btn-primary{

      &:hover{

      }
    }

    #easycontent_17{
      padding-left: 0;
      margin-top: 10px;
    }
    .contact-list{

      a,li{

      }
      li{
        font-size: 12px;
        strong{
          min-width: 154px;
          padding: 5px;
          background-color: #f3f3f3;
        }
        a{

          &:hover{

          }
          &:before{

          }
        }
      }
    }
  }
}