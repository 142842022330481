#side_stmobilemenu{
  a{
    color: $black;
  }
  i{
    &:before{
      color: $black;
    }
  }
  .st-menu-title{
    background: $black;
    color: #fff;
  }
  .style_content{
    background-color: $black;
    color: #fff;
    padding: 10px 12px;
    font-size: 14px;
    h4{
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  .mo_ml_level_0{
    background-color: #f0f0f0;
    border-bottom: 1px solid #d5d5d5;
    padding-left: 0;
    .mo_ma_level_0{
      padding-left: 15px;
    }
    .mo_ml_level_1{
      background-color: #fafafa;
      padding-left: 0;
      .mo_ma_level_1{
        padding: 6px 0;
        padding-left: 20px;
        font-size: 13px;
      }
      .mo_sub_li{
        padding-left: 30px;
        background-color: #fff;
      }
    }
  }
}
.st-menu{
  .st-menu-title{
    background: $black;
  }
  .mo_ma_level_0{
    font-weight: 700;
    font-size: 13px;
  }
}
