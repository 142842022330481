#home-brands{
  margin-top: 52px;
  .common{
    float: unset;
    margin-bottom: 35px;
  }
  .brands_slider_item{
    border: none;
  }
  .brands_slider{
    background-color: #f0f0f0;
    max-height: 166px;
    .flex_stretch{
      max-width: 1470px;
      margin: auto;
    }
  }
}
@media(max-width: $desktop){
  #home-brands{
    .common{

    }
    .brands_slider_item{

    }
    .brands_slider{

      .flex_stretch{
        max-width: 1270px;
      }
    }
  }
}
@media(max-width: $presta-tablet){
  #home-brands{
    margin-top: 0;
    .common{

    }
    .brands_slider_item{

    }
    .brands_slider{

      .flex_stretch{
        max-width: 1270px;
      }
    }
  }
}
@media(max-width: $mobile){
  #home-brands{

    .common{

    }
    .brands_slider_item{

    }
    .brands_slider{

      .flex_stretch{
      }
    }
  }
}