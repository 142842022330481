.kk_open{
  .header-container{
    display: none;
  }
}
#side_wishlist_block{
  button{
    background-color: #000;
    border: none;
  }
}
#wishlist_go_login{
  background-color: #f1f1f1;
  padding: 38px 15px 18px 15px;
  p{
    color: $black;
    line-height: 20px;
  }
  .mfp-close{
    background-color: #000;
    color: #fff;
    right: 0;
    z-index: 999;
  }
  .go{
    text-decoration: none;
    padding: 9px 45px;
    margin-top: 6px;
    display: inline-block;
    color: #fff;
    background-color: #000;
  }
}
#easycontent_20{
  margin-bottom: 0;
  max-width: 182px;
  position: fixed;
  bottom: 0;
  left: 3px;
  z-index: 999;
  box-shadow: 0 0 9px 4px #0000002e;
  p{
    margin-bottom: 0;
  }
}
#index{
  #page_wrapper{
    .js-product-miniature{
      .pro_outer_box{
        .hover_fly{
          a{

            max-width: 28px;
            min-width: 28px;
            i{

            }
            &.quick-view{
              i{

                &:before{

                }
              }
            }
          }
        }
        .ajax_add_to_cart_button{

          i{
            &:before{

            }
          }
        }
        .add_to_wishlit{

          i{
            &:before{

            }
          }
        }
        &:hover{
          .pro_second_box{

          }
        }
      }
      .pro_list_manufacturer{

      }
      .s_title_block{

        a{

        }
      }
      .product-price-and-shipping{

      }
      .regular-price{

      }
      .price{

      }
    }
  }
}
.mobile_device .act_box_cart.add_show_on_mobile{
  display: none;
}
.act_box_cart.add_show_on_mobile{
  display: none;
}
body{
  font-size: 16px;
  a{
    cursor: pointer;
  }
  .st_sticker_block{
    .st_sticker_type_1{
      span{
        padding: 0;
      }
    }
  }
  .st_sticker_type_1{
    span{
      letter-spacing: 0.68px;
      border-bottom: 1px solid $black;
      font-weight: 700;
    }
  }
  #page_banner_container_1{
    background-color: #f3f3f3;
    margin-top: 0;
    padding: 5px 0px;
    .navigation-pipe{
      color: $black;
      margin: 0;
    }
    span{
      font-size: 14px;
      font-weight: 400;
      color: $black;
    }
  }
  &:not(#index){
    #st_header.stuck{
      display: none;
    }
    #header_right_bottom{
      display: none;
    }
    //#header_primary_container{
    //  padding-bottom: 50px;
    //}
    //#st_mega_menu_wrap{
    //  li{
    //    &:not(#st_menu_8){
    //      .stmenu_sub{
    //        bottom: 0;
    //      }
    //    }
    //  }
    //}
  }
  font-family: 'gotham';
  font-size: 16px;
  .st_sticker_type_3{
    transform: rotate(5deg);
  }
  .ajax_block_product .hover_fly{
    bottom: -51px;
    @media(max-width: $presta-tablet){
      bottom: 14px;
    }
  }
  .ajax_block_product:hover .hover_fly{
    bottom: 14px;
  }
  #page_wrapper{
    .js-product-miniature{
      .pro_outer_box{
        max-width: 340px;
        .hover_fly{
          background: unset;
          display: flex;
          a{
            background: unset;
            max-width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            font-size: 0;
            background-color: #f9f9f9;
            position: relative;
            i{
              height: 40px;
              line-height: 50px;
              margin-right: 0;
            }
            &.view_button{
              margin-left: 10px;
            }
            &.quick-view,
            &.view_button{
              i{
                line-height: 43px;
                &:before{
                  color: $black;
                  font-size: 18px;
                }
              }
            }
          }
        }
        .ajax_add_to_cart_button{
          left: 14px;
          i{
            &:before{
              content: "";
              @include sprite($cart-btn);
            }
          }
        }
        .add_to_wishlit{
          right: 14px;
          i{
            &:before{
              content: "";
              @include sprite($love);
            }
          }
          &.st_added{
            background-color: #d83232;
            i{
              &:before{
                filter: invert(1);
              }
            }
          }
        }
        &:hover{
          .pro_second_box{
            background: unset;
          }
        }
      }
      .pro_list_manufacturer{
        color: #999;
        font-size: 12px;
        text-align: left;
        text-transform: uppercase;
        margin: 9px 0 5px 0;
      }
      .s_title_block{
        text-align: left;
        margin-bottom: 10px;
        a{
          font-size: 14px;
          color: $black;
          font-weight: 300;
        }
      }
      .product-price-and-shipping{
        text-align: left;
      }
      .regular-price{
        font-size: 14px;
        color: #a3a2a2;
        font-weight: 200;
      }
      .price{
        color: $black;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
h3,h4{
  color: $black;
}
.ctitle{
  color: $black;
  font-weight: 700;
  position: relative;
  &.line{
    &:before{
      content: "";
      position: absolute;
      display: inline-block;
      width: 37px;
      height: 4px;
      background-color: #000000;
    }
  }
  a{
    font-weight: 700;
    color: $black;
    font-size: 30px;
  }
  &.l-line{
    padding-left: 51px;
    max-width: 224px;
    text-align: left;
    &:before{
      left: 0;
      top: 14px;
    }
  }
}
.common{
  max-width: 1490px;
  padding: 0 10px;
  margin: auto;
}
.shop{
  background-color: $black;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  display: block;
  max-width: 222px;
  padding: 16px 10px;
  &:hover{
    color: #fff;
  }
  @extend .shine-hover;
}
.shine-hover{
  position: relative;
  overflow: hidden;
  vertical-align: bottom;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -200%;
    width: 200%;
    opacity: 0;
    background-image: linear-gradient(
                    135deg,
                    rgba(255, 255, 255, 0.13) 0%,
                    rgba(255, 255, 255, 0.23) 68%,
                    rgba(255, 255, 255, 0.5) 85%,
                    rgba(255, 255, 255, 0.13) 90%,
                    rgba(255, 255, 255, 0) calc(90% + 1px)
    );
    transition-property: left, opacity;
    transition-duration: 0s, 0.15s;
    transition-delay: 0.15s, 0s;
    transition-timing-function: ease-in-out;
  }
  &:hover:after{
    left: 0;
    opacity: 1;
    transition-duration: 0.4s, 0.15s;
    transition-delay: 0s;
  }
}
#body_wrapper{
  .swiper-button{
    width: 52px;
    height: 52px;
    border: 2px solid #000000;
    background: transparent;
    border-radius: 50%;
    i{
      margin: 0;
      height: 52px;
      width: 48px;
      &:before{
        color: $black;
        font-size: 17px;
      }
    }
    &:hover{
      background: $black;
      i{
        &:before{
          color: #fff;
        }
      }
    }
  }
}
@media(max-width: $desktop){
  .shop{
    max-width: 174px;
    padding: 12px 5px;
    font-size: 15px;
    &:hover{

    }
  }
}
@media(max-width: $tablet){
  .ctitle{
    &.line{
      &:before{

      }
    }
    a{
      font-size: 26px;
    }
    &.l-line{

      &:before{
        left: 0;
      }
    }
  }
}
@media(max-width: $presta-tablet){
  .shop{
    max-width: 150px;
    padding: 10px 5px;
    font-size: 13px;
    &:hover{

    }
  }
}
@media(max-width: $mobile){
  .shop{
    max-width: 130px;
    padding: 8px 5px;
    font-size: 12px;
    &:hover{

    }
  }
  .ctitle{
    font-size: 22px;
    &.line{
      &:before{
        top: 11px;
      }
    }
    a{
      font-size: 23px;
    }
    &.l-line{

      &:before{

      }
    }
  }
  body{
    #page_wrapper{
      .js-product-miniature{
        .pro_outer_box{

          .hover_fly{

            a{

              i{

              }
              &.view_button{

              }
              &.quick-view,
              &.view_button{
                i{

                  &:before{

                  }
                }
              }
            }
          }
          .ajax_add_to_cart_button{

            i{
              &:before{

              }
            }
          }
          .add_to_wishlit{

            i{
              &:before{

              }
            }
          }
          &:hover{
            .pro_second_box{

            }
          }
        }
        .pro_list_manufacturer{
          margin: 0;
        }
        .s_title_block{
          margin-bottom: 4px;
          a{

          }
        }
        .product-price-and-shipping{

        }
        .regular-price{
          margin-left: 0;
        }
        .price{

        }
      }
    }
  }
}