#js-product-list-top{
  border-bottom: none;
  border-top: 1px solid #e6e6e6;
  margin: 23px 0 5px 0;
  width: 100%;
  padding-top: 28px;
  display: inline-block;
  .product-count{
    font-size: 16px;
    font-weight: 300;
    color: $black;
    float: left;
    margin-top: 18px;
    strong{
      font-weight: 700;
    }
  }
  .products-sort-order{
    float: right;
    .dropdown_tri{
      border: 1px solid #e1e1e1;
      color: $black;
      font-size: 16px;
      width: 100%;
      min-width: 226px;
      padding: 10px 15px;
      transition: 0.3s ease;
      &:hover{
        border-color: $black;
      }
      i{
        float: right;
        opacity: 1;
        &:before{
          color: #000;
        }
      }
      .arrow_down{
        &:before{
          content: "\E817";
        }
      }
      .arrow_up{
        &:before{
          content: "\E816";
        }
      }
    }
  }
  .dropdown_list{
    width: 100%;
  }
  .dropdown_list_ul{
    a{
      color: $black;
      font-size: 14px;
      &:hover{
        background-color: $black;
        color: #fff;
      }
    }
  }
}
#js-product-list .product_list.grid .product_list_item{
  border-top: none;
  padding-bottom: 10px;
}
#js-product-list{
  .bottom_pagination{
    margin-top: 81px;
    padding-bottom: 137px;
    nav{
      margin: auto;
    }
    li{
      margin: 0 6px;
      a{
        color: $black;
        width: 42px;
        height: 42px;
        font-size: 18px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        &:hover{
          background-color: #e6e6e6;
        }
      }
      &.active{
        a{
          background-color: #e6e6e6;
          font-weight: 700;
        }
      }
      .previous{
        border: 2px solid $black;
        width: 52px;
        height: 52px;
        line-height: 36px;
        &:hover{
          background-color: $black;
          i{
            &:before{
              color: #fff;
            }
          }
        }
      }
      .next{
        border: 2px solid $black;
        width: 52px;
        height: 52px;
        line-height: 36px;
        background-color: $black;
        i{
          &:before{
            color: #fff;
          }
        }
        &:hover{
          background-color: #fff;
          i{
            &:before{
              color: $black;
            }
          }
        }
      }
    }
  }
}
#js-active-search-filters{
  margin-bottom: -22px;
  .active_filter_title{
    color: $black;
    font-size: 14px;
    margin-right: 10px;
  }
  a{
    background-color: #fff;
    color: $black;
    border-color: #e1e1e1;
    padding: 7px;
    padding-right: 25px;
    position: relative;
    font-size: 12px;
    i{
      position: absolute;
      right: 5px;
      &:before{
        color: $black;
      }
    }
    &:hover{
      background-color: $black;
      color: #fff;
      i{
        &:before{
          color: #fff;
        }
      }
    }
  }
}
@media(max-width: $presta-tablet){
  #js-product-list-top{
    padding: 20px 0 10px 0;
    margin: 0 0 5px 0;
    .filter-button{
      float: left;
      margin-right: 21px;
      width: 170px;
    }
    #search_filter_toggler{
      border-color: $black;
      background-color: $black;
      width: 100%;
      padding: 14px 5px;
      font-size: 14px;
    }
    .product-count{
      margin-top: 14px;
      strong{

      }
    }
    .products-sort-order{

      .dropdown_tri{

        &:hover{

        }
        i{

          &:before{

          }
        }
        .arrow_down{
          &:before{

          }
        }
        .arrow_up{
          &:before{

          }
        }
      }
    }
    .dropdown_list{

    }
    .dropdown_list_ul{
      a{

        &:hover{

        }
      }
    }
  }
  #js-product-list{
    .bottom_pagination{
      margin-top: 45px;
      padding-bottom: 30px;
      nav{

      }
      li{

        a{

          &:hover{

          }
        }
        &.active{
          a{

          }
        }
        .previous{

          &:hover{

            i{
              &:before{

              }
            }
          }
        }
        .next{

          i{
            &:before{

            }
          }
          &:hover{

            i{
              &:before{

              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: $mobile){
  #js-product-list-top{
    padding: 13px 0 10px 0;
    .filter-button{

    }
    #search_filter_toggler{

    }
    .product-count{
      display: none;
      strong{

      }
    }
    .products-sort-order{

      .dropdown_tri{
        text-align: center;
        &:hover{

        }
        i{

          &:before{

          }
        }
        .arrow_down{
          &:before{

          }
        }
        .arrow_up{
          &:before{

          }
        }
      }
    }
    .dropdown_list{

    }
    .dropdown_list_ul{
      a{

        &:hover{

        }
      }
    }
  }
  #js-product-list{
    .bottom_pagination{
      margin-top: 20px;
      padding-bottom: 14px;
      nav{

      }
      li{

        a{

          &:hover{

          }
        }
        &.active{
          a{

          }
        }
        .previous{

          &:hover{

            i{
              &:before{

              }
            }
          }
        }
        .next{

          i{
            &:before{

            }
          }
          &:hover{

            i{
              &:before{

              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 580px){
  #js-product-list-top{

    .filter-button{
      max-width: 340px;
      margin: auto;
      float: unset;
      width: 100%;
      margin-bottom: 10px;
    }
    #search_filter_toggler{

    }
    .product-count{

      strong{

      }
    }
    .products-sort-order{
      width: 100%;
      max-width: 340px;
      margin: auto;
      .dropdown_tri{

        &:hover{

        }
        i{

          &:before{

          }
        }
        .arrow_down{
          &:before{

          }
        }
        .arrow_up{
          &:before{

          }
        }
      }
    }
    .dropdown_list{

    }
    .dropdown_list_ul{
      a{

        &:hover{

        }
      }
    }
  }
  #js-product-list{
    .bottom_pagination{

      nav{

      }
      li{

        a{

          &:hover{

          }
        }
        &.active{
          a{

          }
        }
        .previous{

          &:hover{

            i{
              &:before{

              }
            }
          }
        }
        .next{

          i{
            &:before{

            }
          }
          &:hover{

            i{
              &:before{

              }
            }
          }
        }
      }
    }
  }
}