#order-confirmation{
  #wrapper{
    *{
      color: #000;
    }
    .price{
      font-size: 16px;
    }
    .btn-default{
      background-color: #000;
      border-color: #000;
      color: #fff!important;
    }
  }
}
@media(max-width: $mobile){
  #order-confirmation{
    #wrapper{
      *{
        font-size: 14px;
      }
    }
  }
}